<template>
  <BaseModal
    name="ModalPipelineEditar"
    id="ModalPipelineEditar"
    idModal="ModalPipelineEditar"
    size="xl"
    title="Atualizar CRM"
    @shown="fetchDataPipeline()"
    @hidden="hideDataPipeline()"
  >
    <div v-if="tags_props">
      <div class="flex-titulo">
        <div :style="isIframe ? 'width: 50%' : 'width:40%'">
          <h4 class="nome">Nome do CRM</h4>
          <input
            style="width: 100%"
            type="text"
            placeholder="Nome do meu CRM"
            v-model="titulo"
          />

          <div class="show-error">
            <span class="titleError text-danger" v-show="titleRequired"
              >Por favor, insira o nome do CRM</span
            >
            <span class="titleError text-danger" v-if="titleSpace"
              >Espaços inválidos</span
            >
            <span
              class="titleError text-danger"
              v-show="titleLength"
              v-if="titulo.length < 6 || titulo.length > 40"
              >Mínimo de 6 caracteres e máximo de 40 caracteres</span
            >
          </div>
        </div>
        <div style="margin-top: -27px" :style="isIframe ? 'width: 50%' : 'width:40%'">
          <div class="add-link-body">
            <div
              style="
                display: flex;
                gap: 8px;
                align-items: center;
                margin-bottom: 8px;
              "
            >
              <label class="nome mb-0" for="tag_exit">Tag saída</label>
              <img
                width="17px"
                height="17px"
                src="@/assets/img/icons/help-circle.svg"
                alt="icon"
                v-b-tooltip.hover.top
                title="Lead irá receber uma tag ao ser removido do CRM"
              />
            </div>
            <router-link
              v-if="!tags_props.length"
              :to="{ name: 'Tags' }"
              class="add-link link mb-0"
              >Deseja criar uma tag?
            </router-link>
          </div>
          <multiselect
            
            id="tag_exit"
            label="name"
            v-model="tag_saida"
            :options="listTagsAvailable"
            track-by="id"
            placeholder="Selecione uma tag"
            selectLabel=""
            deselectLabel=""
            selectedLabel=""
            :allow-empty="false"
            :searchable="true"
            :hideSelected="true"
          >
            <span slot="noResult">Oops! Nenhuma tag encontrada.</span>
          </multiselect>
        </div>
        <div style="width: 20%;" v-if="!isIframe">
          <BaseButton
            variant="link-info"
            @click="openModal('ModalAddTagPipeline')"
          >
            Criar tag
          </BaseButton>
        </div>
      </div>
      <div  class="grid " :class="{'spacing-bottom':flagNewBords}">
        <div
          class="boards"
          v-for="(item, index) in selected_form"
          :key="index"
          :class="`${item.color ? item.color : 'default'}_color`"
        >
          <div style="display: flex; justify-content: space-between">
            <label
              class="nome"
              style="color: white; font-weight: 500 !important"
              :for="`board${index}`"
              >Board 0{{ index + 1 }}</label
            >
            <div>
              <div class="image-control">
                <img
                  :class="{ 'disabled-arrow': index == 0 }"
                  class="first-arrow"
                  src="@/assets/editor/chevron.svg"
                  @click="index == 0 ? '' : moveUp(index)"
                />
                <button
                  @click="
                    selected_form.length == 1 ? '' : diminuirContador(index)
                  "
                >
                  <img
                    class="icon"
                    src="@/assets/icons/calendar/trash.svg"
                    alt="trash"
                    :style="
                      selected_form.length == 1
                        ? 'filter: invert(50%);'
                        : 'filter: brightness(2);'
                    "
                  />
                </button>
                <img
                  :class="{
                    'disabled-arrow': index + 1 == selected_form.length,
                  }"
                  class="last-arrow"
                  src="@/assets/editor/chevron.svg"
                  @click="
                    index + 1 == selected_form.length ? '' : moveDown(index)
                  "
                />
              </div>
            </div>
          </div>
          <multiselect
            v-if="selected_form[index]"
            :id="`board${index}`"
            label="name"
            v-model="selected_form[index]"
            :options="listTagsBordAvailable"
            track-by="id"
            placeholder="Selecione"
            selectLabel=""
            deselectLabel=""
            selectedLabel=""
            :allow-empty="false"
            :searchable="true"
            :hideSelected="true"
          >
            <span slot="noResult">Nenhuma tag encontrada.</span>
          </multiselect>
        </div>
        <div
          class="boards"
          v-if="selected_form.length <= 7"
        >
          <label
            class="nome"
            :style="
              selected_form.every((item) => item.hasOwnProperty('id'))
                ? ''
                : 'color: #dadada;'
            "
            :for="`board`"
            >Adicionar board</label
          >
          <div
            class="add-board"
            :style="
              selected_form.every((item) => item.hasOwnProperty('id'))
                ? ''
                : 'border-color: #dadada; color: #dadada; background-color: #efefef;'
            "
            @click="
              selected_form.every((item) => item.hasOwnProperty('id'))
                ? selected_form.push('null')
                : ''
            "
          >
            +
          </div>
        </div>
      </div>
      <GrCollapsible v-if="flagNewBords">
        <GrCollapsibleItem :index="1">
          <template #header>
            <div class="collapsible-header">
              <label>Tipo de board de saída</label>
              <p>
                Escolha a melhor opção de saída para o seu crm.
              </p>
            </div>

          </template>
          <template #content>
            <div  class="flex-new-bord spacing-30" >
              <div class="content-new-bord">
                <div class="old" @click="getBordWinLoss = 0">
                  <div class="switch">
                    <label> Board Único</label>
                    <GrSwitchCircle :active="getBordWinLoss === 0" />
                  </div>
                  <div class="card-feature-win-loss">
                      <p class="color-bord color-bord-black">Tag de saída</p>
                  </div>
                </div>

                <div class="new" @click="getBordWinLoss = 1">
                  <div class="switch">
                    <label> Board Ganho e Perdido</label>
                    <GrSwitchCircle :active="getBordWinLoss === 1" />
                  </div>
                  <div class="cards">

                    <div v-for="(item) in listBords" class="card-feature-win-loss">
                      <p :class="`color-bord color-bord-${item.cor}`">{{ item.name }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </GrCollapsibleItem>
      </GrCollapsible>
    </div>
    <template v-slot:footer="{ cancel }">
      <BaseButton variant="link-info" class="mr-2" @click="cancel">
        Fechar
      </BaseButton>
      <BaseButton variant="danger" class="mr-2" @click="deletePipeline">
        Deletar CRM
      </BaseButton>
      <BaseButton variant="primary" @click="onSubmit" :disabled="loading">
        Salvar alterações
      </BaseButton>
    </template>
    
    <div class="d-flex justify-content-center" v-if="loading">
      <b-spinner label="Loading..."></b-spinner>
    </div>
    <ModalAddTagPipeline
      v-if="!isIframe"
      :tagsProps="tags_props"
    />
  </BaseModal>
</template>

<script>
import PipelineService from "@/services/resources/PipelineService";
const servicePipeline = PipelineService.build();
import { GrSwitchCircle } from "@/components/GrComponents/switch-circle";
import { GrCollapsible, GrCollapsibleItem } from "@/components/GrComponents/collaps";

import Vue from "vue";
import Multiselect from "vue-multiselect";

export default {
  props: ["selectedPipeline", "tags_props", "isIframe"],
  components: {
    Multiselect,
    GrSwitchCircle,
    GrCollapsible,
    GrCollapsibleItem,
    ModalAddTagPipeline: () => import('@/components/Pipeline/ModalAddTagPipeline')
  },
  data() {
    return {
      titleRequired: false,
      titleLength: false,
      titleSpace: false,
      titulo: "",
      tag: [],
      listBords: [
        {
          name: "Tag de saída",
          cor: "black"
        },
        {
          name: "Ganho",
          cor: "green"
        },
        {
          name: "Perdido",
          cor: "red"
        }
      ],
      loading: false,
      selected_form: [],
      stops: [],
      tag_saida: undefined,
      flagNewBords: false,
    };
  },
  methods: {
   printTost(message,status){
      this.$grToast.toast(message, {
              title: "CRM",
              variant: status,
              autoHideDelay: 5000,
              appendToast: true,
            });
    },
    openModal(data) {
      this.$bvModal.show(data);
    },
    diminuirContador(index) {
      this.selected_form.splice(index, 1);
    },
    moveUp(idx) {
      var moving = this.selected_form[idx];
      this.selected_form.splice(idx, 1);
      this.selected_form.splice(idx - 1, 0, moving);
    },
    moveDown(idx) {
      var moving = this.selected_form[idx];
      this.selected_form.splice(idx, 1);
      this.selected_form.splice(idx + 1, 0, moving);
    },
    deletePipeline() {
      var data = {
        id: this.selectedPipeline.id,
      };
      Vue.swal({
        title: "CRM",
        text: `Deseja realmente deletar este CRM?`,
        type: "danger",
        showCancelButton: true,
        confirmButtonText: "Deletar",
        cancelButtonText: "Cancelar",
        customClass: "sweet-container",
        confirmButtonClass: "button button-danger mt-3 mb-3",
        cancelButtonClass: "button button-link-info mr-4 mt-3 mb-3",
        buttonsStyling: false,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          servicePipeline.destroy(`delete/${data.id}`).then((resp) => {
           this.printTost('CRM deletado', 'info')
            this.$bvModal.hide("ModalPipelineEditar");
            // this.$emit("reload-list");
            this.$emit("prev_selectedPipeline_localStorage");
          });
        }
      });
    },
    fetchDataPipeline() {
      this.titulo = this.selectedPipeline.title;
      if (this.selectedPipeline.tag_exit && this.selectedPipeline.tag_exit > 0) {
        let tag_saida = this.selectedPipeline.tag_exit;
        this.tag_saida = this.tags_props.find(
          (tagFind) => tagFind.id == tag_saida
        );
      } else {
        this.tag_saida = {
          name: "Sem tag de saída",
          id: 0,
        }
      }
      let id_tags = this.selectedPipeline.tags;
      let array_tags = [];
      let pipeline = this.selectedPipeline.id;
      for (let i = 0; i < id_tags.length; i++) {
        const tag_id = id_tags[i];
        let tag = this.tags_props.find((tagFind) => tagFind.id == tag_id);
        if (tag && pipeline && pipeline.tag_exit != tag_id) {
          array_tags.push(tag);
        }
      }
      if (this.tag_saida.id) {
        array_tags.pop();
      }
      this.selected_form = array_tags;
    },
    hideDataPipeline() {
      this.titulo = "";
      this.tag_saida = "";
      this.selected_form = [];
    },
    onSubmit() {
      var new_selected_form = [];
      var result_tags;
      var pipeline = this.selectedPipeline;
      for (let i = 0; i < this.selected_form.length; i++) {
        const element = this.selected_form[i];
        new_selected_form.push(element.id);
      }

      new_selected_form.map(String);
      var valueArr = new_selected_form.map(function (item) {
        return item;
      });

      var isDuplicate = valueArr.some(function (item, idx) {
        return valueArr.indexOf(item) != idx;
      });

      var tag_saida_is_duplicate = this.selected_form.findIndex(
        (x) => x == this.tag_saida
      );

      if (tag_saida_is_duplicate !== -1) {
        new_selected_form.splice(tag_saida_is_duplicate, 1, pipeline.tag_exit);
      } else {
        new_selected_form.splice(new_selected_form.length, 1);
      }

      if (!this.tag_saida.id) {
       this.printTost('Selecione uma tag de saída', 'danger')
        return;
      }

      if (
        this.tag_saida &&
        this.tag_saida.id &&
        new_selected_form.findIndex((x) => x == this.tag_saida.id) != -1
      ) {
       this.printTost('A tag já está sendo utilizada como tag de saída', 'danger')
        return;
      }

      const regex = /  /;
      if (!this.titulo) {
        this.titleRequired = true;
        this.titleLength = false;
        this.loading = false;
        return;
      } else if (
        this.titulo.length < 6 ||
        this.titulo.length > 40 ||
        regex.test(this.titulo)
      ) {
        this.titleLength = true;
        this.titleRequired = false;
        this.loading = false;
        return;
      } else {
        this.titleRequired = false;
        this.titleLength = false;
      }

      if (isDuplicate === true) {
       this.printTost('Existem campos selecionados com os mesmos boards','danger');
        this.loading = false;
        return;
      } else {
        this.loading = true;
        this.$validator.validateAll().then((result) => {
          if (result) {
            if (
              this.selected_form.includes("null") ||
              this.selected_form.includes(null) ||
              this.selected_form.includes("null")
            ) {
              this.printTost('Obrigatório selecionar os campos de board', 'danger')
              this.loading = false;
              return;
            }
            var tags = Array.from(new_selected_form);
            var result_tags = [];
            for (let i = 0; i < tags.length; i++) {
              const element = tags[i];
              if (i <= this.selected_form.length - 1 && element != null) {
                result_tags.push(element + "");
              }
            }
            if (this.tag_saida && this.tag_saida.id) {
              result_tags.push(this.tag_saida.id.toString());
            }

            var data = {
              id: `edit/${this.selectedPipeline.id}`,
              tags: result_tags,
              tag_exit: this.tag_saida.id,
              title: `${this.titulo}`,
              column_out_separated: this.selectedPipeline.column_out_separated,
            };
            servicePipeline
              .update(data)
              .then(() => {
                this.$emit("update", result_tags,this.selectedPipeline.column_out_separated);
                this.printTost('CRM alterado com sucesso', "success")
                this.loading = false;
                this.$bvModal.hide("ModalPipelineEditar");
              })
              .catch((err) => {
                this.printTost('Erro ao alterar CRM', "danger")
              })
              .finally(() => {
                this.loading = false;
              });
          } else {
            if (this.titulo === "") {
              this.printTost('Obrigatório preencher o nome do CRM', "danger")
              this.loading = false;
              return;
            }
            if (this.selected_form.length === 0) {
              this.printTost('Obrigatório selecionar os campos de board', "danger")
              this.loading = false;
              return;
            }
            if (this.tag_saida === undefined) {
              this.printTost('Obrigatório selecionar a tag de saída', "danger")
              this.loading = false;
              return;
            }
          }
        });
      }
    },
  },
  computed:{
    getBordWinLoss:{
      get(){
        return this.selectedPipeline?.column_out_separated 
      },
      set(value){
        this.selectedPipeline.column_out_separated = value 
      }
    },
    listTagsAvailable:{
      get(){
        const filter = this.tags_props.filter((tag) => this.selected_form.every((form) => form.id != tag.id) )
        return filter
      }
    },
    listTagsBordAvailable:{
      get(){
        const filter = this.tags_props.filter((tag) =>{
          return this.selected_form.every((form) => form.id != tag.id) && this.tag_saida?.id != tag.id
        })
        return filter
      }
    }
  },
  async mounted(){

    this.flagNewBords = await this.$store.dispatch('getUniqueFlag', 'crm_out_separated') 
  },

};
</script>

<style lang="scss" scoped>
.w-100{
  width: 100%;
}
.color-bord{
  font-size: 16px;
  font-weight: 500;
  min-width: 180px ;
  &-black{
    color: var(--green-dark-800);
  }
  &-green{
    color: var(--greenn);
  }
  &-red{
    color: var(--red-500);
  }
}

.spacing{
  margin-top: 20px;
  &-30{
    margin-top: 30px !important;
  }
  &-bottom{
    margin-bottom: 30px !important;
  }
}

.collapsible-header{

  p{
    font-size: 14px;
    color: var(--gray-200)
  }
}
.card-feature-win-loss{
  background: var(--gray-50);
  padding: 20px 15px;
  border-radius: 10px;
}
.flex-new-bord{
  display: grid;
  gap: 1rem;
  margin-bottom: 1.5rem;
  align-items: top;
  .content-new-bord{
    display: flex;
    justify-content: space-between;
    .switch{
      top: 7px;
      display: flex;
      width: 100%;
      justify-content: space-between;
    }
    .old,.new{
      border: solid 1px #eaeaea ;
      position: relative;
      border-radius: 10px;
      height: 100%;
      gap: 5px;
      display: grid;
      position: relative;
      padding: 20px;
    }

    .old{
      width: 30%;
    }
    .new{
      width: 68%;
      .cards{
        display: flex;
        gap: 20px;
      }
      &::after{
        content: 'Novidade';
        position: absolute;
        padding: 2px 7px;
        right: 0px;
        top: -15px;
        font-size: 12px;
        background-color: var(--secondary-750);
        color: var(--gray-white);
        font-weight: 600;
        border-radius: 10px;
      }
    }
  }
}
.show-error{
  display: flex;
  flex-direction: column;
  gap: 17px;
  margin-top: 13px;
}
.boards {
  border: 1px solid #eaeaea;
  border-radius: 10px;
  padding: 10px;
  transition: all 0.3s;
}
.image-control {
  height: auto;
  display: flex;
  width: 35px;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
  button {
    height: 24px;
    width: 24px;
    border-radius: 10px;
    transition: all 0.3s;
    margin: 0;
    padding: 0;
    border: none;
    background-color: transparent;
    font-size: 25px;
    cursor: pointer;
    img {
      height: 15px;
    }
  }
  div {
    width: min-content;
  }
  .first-arrow {
    rotate: 90deg;
    height: 15px;
    filter: brightness(2);
  }
  .last-arrow {
    rotate: -90deg;
    height: 18px;
  }
  img {
    cursor: pointer;
  }
  .disabled-arrow {
    filter: invert(50%);
    cursor: default !important;
  }
}
.add-board {
  height: 50px;
  border-radius: 10px;
  width: 100%;
  background-color: transparent;
  border: 1px solid var(--greenn);
  color: var(--greenn);
  font-size: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s;
  &:hover {
    background-color: #05840a22;
  }
}
.img {
  cursor: pointer;
}
.nome {
  font-weight: 600;
  font-size: 14px;
  &-white{
    color: white; 
    font-weight: 500 !important;
  }
}
.flex-titulo {
  height: 8rem;
  display: flex;
  align-items: center;
  gap: 10px;
  .titleError {
    font-size: 12px;
    margin-top: -20px;
    font-weight: 500;
  }
}
.flex {
  display: flex;
  align-items: center;
  gap: 25px;
  position: fixed;
  right: 50px;
}
.grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
}
.b-flex {
  margin-top: 27px;
  display: flex;
  gap: 6px;
}
.info_color {
  background: var(--bluee);
}
.danger_color {
  background: var(--red);
}
.warning_color {
  background: var(--orange);
}
.primary_color {
  background: var(--greenn-light);
}
.success_color {
  background: #2ed7ee;
}
.default_color {
  background: var(--gray01);
}
</style>
