<template>
  <div class="container pt-4">
    <BaseHeader
      :navigation="navigation"
      title="Minha conta"
      :class="{ BaseHeaderMobile: isMobile }"
    ></BaseHeader>
    <b-tabs
      :class="{ 'mt-4': !isMobile }"
      nav-class="TabHeader no-border"
      active-nav-item-class="TabHeader--active"
      :no-nav-style="true"
    >
      <b-tab :title="`Meus dados`" active>
        <b-container
          fluid
          class="mt-1 wrapper container-user"
          v-show="!loading"
        >
          <h5 :class="{ 'my-3': !isMobile }">Dados pessoais</h5>
          <form data-vv-scope="user">
            <b-col class="foto-user" cols="12" md="12" v-if="!isMobile">
              <b-form-group label="Foto do perfil">
                <VueUploadMultipleImage
                  :multiple="false"
                  @upload-success="uploadImageSuccess"
                  @before-remove="beforeRemove"
                  @edit-image="editImage"
                  :data-images="user_picture"
                  idUpload="userImgUpdate"
                  editUpload="userImgEdit"
                  dragText=""
                  browseText="Procurar imagens"
                  :showPrimary="false"
                  :showEdit="false"
                ></VueUploadMultipleImage>
              </b-form-group>
            </b-col>
            <b-row class="mt-2">
              <b-col cols="12" md="6">
                <b-form-group label="Nome" label-for="name">
                  <b-form-input
                    id="name"
                    name="name"
                    v-model="user.first_name"
                    type="text"
                    placeholder="Escreva o seu nome"
                    v-validate="'required'"
                  ></b-form-input>
                  <b-form-invalid-feedback :state="!errors.has('user.name')">
                    Por favor, insira o seu nome
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6">
                <b-form-group label="Sobrenome" label-for="sobrenome">
                  <b-form-input
                    id="sobrenome"
                    name="sobrenome"
                    v-model="user.last_name"
                    type="text"
                    placeholder="Escreva o seu nome"
                    v-validate="'required'"
                  ></b-form-input>
                  <b-form-invalid-feedback
                    :state="!errors.has('user.sobrenome')"
                  >
                    Por favor, insira o seu sobrenome
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6" class="mt-3">
                <b-form-group label="Telefone" label-for="phone">
                  <b-form-input
                    id="phone"
                    name="phone"
                    v-model="user.phone"
                    type="text"
                    placeholder="Seu telefone"
                    v-mask="['(##) ####-####', '(##) #####-####']"
                  ></b-form-input>
                  <b-form-invalid-feedback :state="!errors.has('user.phone')">
                    Por favor, insira o seu telefone
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6" class="mt-3">
                <b-form-group label="Endereço de e-mail" label-for="email">
                  <b-form-input
                    id="email"
                    name="email"
                    v-model="user.email"
                    type="text"
                    disabled
                    placeholder="Insira seu e-mail"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row class="mt-2">
              <b-col cols="12" class="d-flex justify-content-end mt-3">
                <BaseButton @click="saveUser" variant="primary"
                  >Salvar Alterações</BaseButton
                >
              </b-col>
            </b-row>
          </form>
        </b-container>
      </b-tab>
      <b-tab :title="`Integrações e tokens`" v-if="!isMobile">
        <b-container
          fluid
          class="mt-1 wrapper container-user"
          v-show="!loading"
        >
          <h5 class="my-3">Google</h5>
          <div>
            <div id="googleLogin">
              <button @click="conectGoogle" id="login_button" class="btn">
                <img
                  id="google_img"
                  src="@/assets/img/icons/google.svg"
                  width="18"
                  height="15"
                />
                <span>
                  {{
                    google ? "Desconectar Google" : "Conectar com o Google"
                  }}</span
                >
              </button>
            </div>
          </div>

          <!-- <h5 class="my-3">Token sistema</h5>
          <b-form class="grid-tokens">
            <b-form-group label="Token" label-for="password">
              <b-form-input :value="user_key" disabled></b-form-input>
            </b-form-group>

            <b-form-group label="#ID" label-for="new_password" class="mt-3">
              <b-form-input :value="user_id" disabled></b-form-input>
            </b-form-group>
          </b-form> -->

          <!-- <h5 class="my-3">Integração pluga</h5>
          <b-form class="grid-tokens">
            <b-form-group label="Token" label-for="password">
              <b-form-input :value="pluga_key" disabled></b-form-input>
            </b-form-group>

            <b-form-group label="#ID" label-for="new_password" class="mt-3">
              <b-form-input :value="tenant_id" disabled></b-form-input>
            </b-form-group>
          </b-form> -->

          <!-- <h5 class="my-3">Chave publica</h5>
          <b-form>
            <b-form-group label="Chave" label-for="password">
              <b-form-input :value="public_key" disabled></b-form-input>
            </b-form-group>
          </b-form> -->

          <h5 class="my-3">Global token</h5>
          <b-form>
            <div class="">
              <b-form-group label="Chave" label-for="password">
                <b-form-input v-model="global_token" disabled></b-form-input>
              </b-form-group>
              <!-- <BaseButton @click="generateToken" variant="black"
                >Gerar token</BaseButton
              > -->
            </div>
          </b-form>

          <b-row class="mt-2">
            <b-col cols="12" class="d-flex justify-content-end mt-3">
              <!-- <BaseButton variant="primary">Salvar alterações</BaseButton> -->
            </b-col>
          </b-row>
        </b-container>
      </b-tab>

      <b-tab title="Meu plano" v-if="$store.getters.user.user.level == 'owner' || $store.getters.user.user.level == 'admin'">
        <b-container
          fluid
          class="mt-1 wrapper container-user"
          v-show="!loading"
        >
          <h5 class="my-3">Recursos</h5>
          <div class="resource">
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" width="72" height="72" fill="#4ea934" viewBox="0 0 256 256"><path d="M224,48H32a8,8,0,0,0-8,8V192a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V56A8,8,0,0,0,224,48Zm-96,85.15L52.57,64H203.43ZM98.71,128,40,181.81V74.19Zm11.84,10.85,12,11.05a8,8,0,0,0,10.82,0l12-11.05,58,53.15H52.57ZM157.29,128,216,74.18V181.82Z"></path></svg>
              <p>E-mails</p>
              <span>{{ emailLimit }}/mês</span>
            </div>
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" width="72" height="72" fill="#4ea934" viewBox="0 0 256 256"><path d="M117.25,157.92a60,60,0,1,0-66.5,0A95.83,95.83,0,0,0,3.53,195.63a8,8,0,1,0,13.4,8.74,80,80,0,0,1,134.14,0,8,8,0,0,0,13.4-8.74A95.83,95.83,0,0,0,117.25,157.92ZM40,108a44,44,0,1,1,44,44A44.05,44.05,0,0,1,40,108Zm210.14,98.7a8,8,0,0,1-11.07-2.33A79.83,79.83,0,0,0,172,168a8,8,0,0,1,0-16,44,44,0,1,0-16.34-84.87,8,8,0,1,1-5.94-14.85,60,60,0,0,1,55.53,105.64,95.83,95.83,0,0,1,47.22,37.71A8,8,0,0,1,250.14,206.7Z"></path></svg>
              <p>Usuários</p>
              <span>{{ $store.getters.recursos.nusers }}</span>
            </div>
            <div :class="{'block-resource' : !$store.getters.recursos.ntask}">
              <svg xmlns="http://www.w3.org/2000/svg" width="72" height="72" fill="#4ea934" viewBox="0 0 256 256"><path d="M243.28,68.24l-24-23.56a16,16,0,0,0-22.58,0L104,136h0l-.11-.11L67.25,100.62a16,16,0,0,0-22.57.06l-24,24a16,16,0,0,0,0,22.61l71.62,72a16,16,0,0,0,22.63,0L243.33,90.91A16,16,0,0,0,243.28,68.24ZM103.62,208,32,136l24-24,.11.11,36.64,35.27a16,16,0,0,0,22.52,0L208.06,56,232,79.6Z"></path></svg>
              <p>Atividades</p>
              <span>{{ $store.getters.recursos.ntask ? 'Liberado' : 'Bloqueado' }}</span>
            </div>
            <div :class="{'block-resource' : !$store.getters.recursos.nstrategic}">
              <svg xmlns="http://www.w3.org/2000/svg" width="72" height="72" fill="#4ea934" viewBox="0 0 256 256"><path d="M221.87,83.16A104.1,104.1,0,1,1,195.67,49l22.67-22.68a8,8,0,0,1,11.32,11.32l-96,96a8,8,0,0,1-11.32-11.32l27.72-27.72a40,40,0,1,0,17.87,31.09,8,8,0,1,1,16-.9,56,56,0,1,1-22.38-41.65L184.3,60.39a87.88,87.88,0,1,0,23.13,29.67,8,8,0,0,1,14.44-6.9Z"></path></svg>
              <p>Estratégico</p>
              <span>{{ $store.getters.recursos.nstrategic ? 'Liberado' : 'Bloqueado' }}</span>
            </div>
            <div :class="{'block-resource' : !$store.getters.recursos.nranking}">
              <svg xmlns="http://www.w3.org/2000/svg" width="72" height="72" fill="#4ea934" viewBox="0 0 256 256"><path d="M232,64H208V56a16,16,0,0,0-16-16H64A16,16,0,0,0,48,56v8H24A16,16,0,0,0,8,80V96a40,40,0,0,0,40,40h3.65A80.13,80.13,0,0,0,120,191.61V216H96a8,8,0,0,0,0,16h64a8,8,0,0,0,0-16H136V191.58c31.94-3.23,58.44-25.64,68.08-55.58H208a40,40,0,0,0,40-40V80A16,16,0,0,0,232,64ZM48,120A24,24,0,0,1,24,96V80H48v32q0,4,.39,8Zm144-8.9c0,35.52-28.49,64.64-63.51,64.9H128a64,64,0,0,1-64-64V56H192ZM232,96a24,24,0,0,1-24,24h-.5a81.81,81.81,0,0,0,.5-8.9V80h24Z"></path></svg>
              <p>Ranking</p>
              <span>{{ $store.getters.recursos.nranking ? 'Liberado' : 'Bloqueado' }}</span>
            </div>
            <div :class="{'block-resource' : !$store.getters.recursos.ncrm}">
              <svg xmlns="http://www.w3.org/2000/svg" width="72" height="72" fill="#4ea934" viewBox="0 0 256 256"><path d="M200,112a8,8,0,0,1-8,8H152a8,8,0,0,1,0-16h40A8,8,0,0,1,200,112Zm-8,24H152a8,8,0,0,0,0,16h40a8,8,0,0,0,0-16Zm40-80V200a16,16,0,0,1-16,16H40a16,16,0,0,1-16-16V56A16,16,0,0,1,40,40H216A16,16,0,0,1,232,56ZM216,200V56H40V200H216Zm-80.26-34a8,8,0,1,1-15.5,4c-2.63-10.26-13.06-18-24.25-18s-21.61,7.74-24.25,18a8,8,0,1,1-15.5-4,39.84,39.84,0,0,1,17.19-23.34,32,32,0,1,1,45.12,0A39.76,39.76,0,0,1,135.75,166ZM96,136a16,16,0,1,0-16-16A16,16,0,0,0,96,136Z"></path></svg>
              <p>CRM</p>
              <span>{{ $store.getters.recursos.ncrm ? 'Liberado' : 'Bloqueado' }}</span>
            </div>
            <div :class="{'block-resource' : !$store.getters.recursos.nwhatscrm}">
              <svg xmlns="http://www.w3.org/2000/svg" width="72" height="72" fill="#4ea934" viewBox="0 0 256 256"><path d="M200,112a8,8,0,0,1-8,8H152a8,8,0,0,1,0-16h40A8,8,0,0,1,200,112Zm-8,24H152a8,8,0,0,0,0,16h40a8,8,0,0,0,0-16Zm40-80V200a16,16,0,0,1-16,16H40a16,16,0,0,1-16-16V56A16,16,0,0,1,40,40H216A16,16,0,0,1,232,56ZM216,200V56H40V200H216Zm-80.26-34a8,8,0,1,1-15.5,4c-2.63-10.26-13.06-18-24.25-18s-21.61,7.74-24.25,18a8,8,0,1,1-15.5-4,39.84,39.84,0,0,1,17.19-23.34,32,32,0,1,1,45.12,0A39.76,39.76,0,0,1,135.75,166ZM96,136a16,16,0,1,0-16-16A16,16,0,0,0,96,136Z"></path></svg>
              <p>CRM GWhats</p>
              <span>{{ $store.getters.recursos.nwhatscrm ? 'Liberado' : 'Bloqueado' }}</span>
            </div>
            <div :class="{'block-resource' : !$store.getters.recursos.nhistory}">
              <svg xmlns="http://www.w3.org/2000/svg" width="72" height="72" fill="#4ea934" viewBox="0 0 256 256"><path d="M136,80v43.47l36.12,21.67a8,8,0,0,1-8.24,13.72l-40-24A8,8,0,0,1,120,128V80a8,8,0,0,1,16,0Zm-8-48A95.44,95.44,0,0,0,60.08,60.15C52.81,67.51,46.35,74.59,40,82V64a8,8,0,0,0-16,0v40a8,8,0,0,0,8,8H72a8,8,0,0,0,0-16H49c7.15-8.42,14.27-16.35,22.39-24.57a80,80,0,1,1,1.66,114.75,8,8,0,1,0-11,11.64A96,96,0,1,0,128,32Z"></path></svg>
              <p>Histórico</p>
              <span>{{ $store.getters.recursos.nhistory ? 'Liberado' : 'Bloqueado' }}</span>
            </div>
            <div v-if="$store.getters.recursos.npages">
              <svg xmlns="http://www.w3.org/2000/svg" width="72" height="72" fill="#4ea934" viewBox="0 0 256 256"><path d="M216,40H72A16,16,0,0,0,56,56V72H40A16,16,0,0,0,24,88V200a16,16,0,0,0,16,16H184a16,16,0,0,0,16-16V184h16a16,16,0,0,0,16-16V56A16,16,0,0,0,216,40ZM184,88v16H40V88Zm0,112H40V120H184v80Zm32-32H200V88a16,16,0,0,0-16-16H72V56H216Z"></path></svg>
              <p>Páginas</p>
              <span>{{ $store.getters.recursos.npages }}</span>
            </div>
            <div v-if="$store.getters.recursos.ndomain">
              <svg xmlns="http://www.w3.org/2000/svg" width="72" height="72" fill="#4ea934" viewBox="0 0 256 256"><path d="M128,24h0A104,104,0,1,0,232,128,104.12,104.12,0,0,0,128,24Zm88,104a87.61,87.61,0,0,1-3.33,24H174.16a157.44,157.44,0,0,0,0-48h38.51A87.61,87.61,0,0,1,216,128ZM102,168H154a115.11,115.11,0,0,1-26,45A115.27,115.27,0,0,1,102,168Zm-3.9-16a140.84,140.84,0,0,1,0-48h59.88a140.84,140.84,0,0,1,0,48ZM40,128a87.61,87.61,0,0,1,3.33-24H81.84a157.44,157.44,0,0,0,0,48H43.33A87.61,87.61,0,0,1,40,128ZM154,88H102a115.11,115.11,0,0,1,26-45A115.27,115.27,0,0,1,154,88Zm52.33,0H170.71a135.28,135.28,0,0,0-22.3-45.6A88.29,88.29,0,0,1,206.37,88ZM107.59,42.4A135.28,135.28,0,0,0,85.29,88H49.63A88.29,88.29,0,0,1,107.59,42.4ZM49.63,168H85.29a135.28,135.28,0,0,0,22.3,45.6A88.29,88.29,0,0,1,49.63,168Zm98.78,45.6a135.28,135.28,0,0,0,22.3-45.6h35.66A88.29,88.29,0,0,1,148.41,213.6Z"></path></svg>
              <p>Domínios</p>
              <span>{{ $store.getters.recursos.ndomain }}</span>
            </div>
          </div>
          <!-- <BaseButton v-if="!isBasic" @click="buyResources" variant="primary"
            >Comprar mais recursos</BaseButton
          > -->
          <h5 class="my-3">Contratos</h5>
          <div class="contracts">
            <div v-for="(contract, idx) in contracts" :key="idx">
              <svg xmlns="http://www.w3.org/2000/svg" width="72" height="72" fill="#4ea934" viewBox="0 0 256 256"><path d="M96,104a8,8,0,0,1,8-8h64a8,8,0,0,1,0,16H104A8,8,0,0,1,96,104Zm8,40h64a8,8,0,0,0,0-16H104a8,8,0,0,0,0,16Zm128,48a32,32,0,0,1-32,32H88a32,32,0,0,1-32-32V64a16,16,0,0,0-32,0c0,5.74,4.83,9.62,4.88,9.66h0A8,8,0,0,1,24,88a7.89,7.89,0,0,1-4.79-1.61h0C18.05,85.54,8,77.61,8,64A32,32,0,0,1,40,32H176a32,32,0,0,1,32,32V168h8a8,8,0,0,1,4.8,1.6C222,170.46,232,178.39,232,192ZM96.26,173.48A8.07,8.07,0,0,1,104,168h88V64a16,16,0,0,0-16-16H67.69A31.71,31.71,0,0,1,72,64V192a16,16,0,0,0,32,0c0-5.74-4.83-9.62-4.88-9.66A7.82,7.82,0,0,1,96.26,173.48ZM216,192a12.58,12.58,0,0,0-3.23-8h-94a26.92,26.92,0,0,1,1.21,8,31.82,31.82,0,0,1-4.29,16H200A16,16,0,0,0,216,192Z"></path></svg>
              <div>
                <h4>{{ contract.nome_produto ? contract.nome_produto.replace(/ - /g, "").replace(/anual/gi, "").replace(/mensal/gi, "") : "Contrato " + nameSystem  }} <span :class="contract.status == 1 || contract.status == -5 ? 'active' : 'inactive'">{{ contract.status == 1 || contract.status == -5 ? 'Ativo' : 'Inativo' }}</span></h4>
                <p>{{ returnPeriod(contract.produto_id === 132, contract.periodo) }}</p>
              </div>
              <span v-if="contract.status == 1" class="cancel" v-b-toggle="`plan${idx}`" >Cancelar</span>
              <b-collapse :id="`plan${idx}`" accordion="card-cancel" class="reasons">
                <b-form-group>
                  <b-form-radio
                    v-model="selected"
                    value="O valor do plano é muito alto"
                    >O valor do plano é muito alto</b-form-radio
                  >
                  <b-form-radio
                    v-model="selected"
                    value="Não gostei dos recursos da plataforma"
                    >Não gostei dos recursos da plataforma</b-form-radio
                  >
                  <b-form-radio
                    v-model="selected"
                    value="Tive problemas técnicos frequentes"
                    >Problemas técnicos frequentes</b-form-radio
                  >
                  <b-form-radio
                    v-model="selected"
                    value="Não estou utilizando o serviço de forma significativa"
                    >Não está utilizando o serviço de forma significativa</b-form-radio
                  >
                  <b-form-radio
                    v-model="selected"
                    value="Achei ofertas mais atraentes de concorrentes"
                    >Ofertas mais atraentes de concorrentes</b-form-radio
                  >
                </b-form-group>
                <div style="display: grid; gap: 10px; grid-template-columns: 1fr 1fr;">
                  <BaseButton @click="cancelContract" class="mt-2 w-100" variant="outline-danger"
                    >Solicitar cancelamento</BaseButton
                  >
                  <BaseButton v-b-toggle="`plan${idx}`" class="mt-2 w-100" variant="primary"
                    >Voltar</BaseButton
                  >
                </div>
              </b-collapse>
            </div>
          </div>
        </b-container>
      </b-tab>

      <b-tab v-if="!isGreenn" :title="`Avançado`">
        <b-container
          fluid
          class="mt-1 wrapper container-user"
          v-show="!loading"
        >
          <h5 class="my-3">Alterar senha</h5>
          <b-form>
            <!-- <div class="pt-2 pb-2"> -->
            <b-form-group
              class="password-most"
              label="Nova senha"
              label-for="password"
            >
              <b-form-input
                id="password"
                name="password"
                type="password"
                v-model="password"
                v-validate="'required'"
              ></b-form-input>
              <span
                v-if="password"
                class="mostrar-senha"
                ref="mostrar_senha"
                @click="mostrarSenha('password')"
                >Mostrar</span
              >
            </b-form-group>

            <br />

            <b-form-group
              label="Confirmar nova senha"
              label-for="new_password"
              class="password-most mt-3"
            >
              <b-form-input
                id="confirm_password"
                name="confirm_password"
                type="password"
                v-model="confirm_password"
              ></b-form-input>
              <span
                v-if="confirm_password"
                class="mostrar-senha"
                ref="mostrar_nova_senha"
                @click="mostrarSenha('confirm_password')"
                >Mostrar</span
              >
            </b-form-group>
          </b-form>

          <b-row class="mt-2">
            <b-col cols="12" class="d-flex justify-content-end mt-3">
              <BaseButton @click="savePassword" variant="primary"
                >Salvar senha</BaseButton
              >
            </b-col>
          </b-row>
        </b-container>
      </b-tab>
      <div
        key="loading"
        v-if="loading"
        class="py-5 my-5 d-flex justify-content-center align-items-center"
      >
        <b-spinner variant="dark" label="Spinning"> </b-spinner>
      </div>
    </b-tabs>
  </div>
</template>
<script>
import VueUploadMultipleImage from "vue-upload-multiple-image";

import UsersService from "@/services/resources/UsersService";
const serviceUser = UsersService.build();

import GlobalTokenService from "@/services/resources/GlobalTokenService";
const serviceGlobalToken = GlobalTokenService.build();

import GoogleService from "@/services/resources/GoogleService";
const serviceGoogle = GoogleService.build();

import Vue from "vue";

export default {
  data() {
    return {
      navigation: [{ link: "Minha conta", to: this.$route.fullPath }],
      google: false,
      loading: false,
      user_picture: [],
      user: {},
      password: "",
      confirm_password: "",
      global_token: "",
      client: {
        width: 0,
      },
      contracts: [],
      selected: 'Olá',
    };
  },
  components: {
    VueUploadMultipleImage,
  },

  computed: {
    nameSystem() {
      return this.$store.getters["getNameSystem"];
    },
    emailLimit(){
      const additional = this.$store.getters.recursos.nenvios ? this.$store.getters.recursos.nenvios : 0;
      return (this.$store.getters.recursos.nleads * this.$store.getters.recursos.nmultiplicationemails) + additional;
    },
    isMobile() {
      return this.client.width <= 768;
    },
    pluga_key() {
      return "Sem Token";
    },
    tenant_id() {
      return this.$store.getters.user.user.tenant_id;
    },
    user_key() {
      return this.$store.getters.user.tokenUsuario;
    },
    public_key() {
      return this.$store.getters.user.publicAuth;
    },
    user_id() {
      return this.$store.getters.user.user.id;
    },
    userX() {
      return this.$store.getters.user;
    },
    isBasic() {
      let contracts = JSON.parse(sessionStorage.getItem("userContracts"))
      if (!contracts) {
        return false
      }
      contracts = contracts.filter(contract => (contract.status == 1 || contract.status == -5) && contract.produto_id >= 118)
      // Se o usuário tiver apenas um contratato e esse contratato for o de teste grátis ou o do plano start
      if (contracts.some(contract => contract.produto_id !== 132 &&  contract.produto_id !== 133 )) {
        return false
      }
      return true
    },
    isGreenn() {
      return this.$store.getters["getIsGreenn"];
    },
  },
  methods: {
    returnPeriod(isTrial, period) {
      if (isTrial) {
        return 'Plano com duração de 7 dias'
      }
      switch (period) {
        case 12:
          return 'Plano anual';
        case 6:
          return 'Plano semestral';
        case 1:
          return 'Plano mensal';
        case 15:
          return 'Plano de migração';
        default:
          return `Plano com período de ${period} meses`;
      }
    },
    handleResize() {
      // this.client.width = window.innerWidth;
      this.client.width = window.innerWidth;
    },
    isURL(str) {
      var pattern = new RegExp(
        "^(https?:\\/\\/)?" + // protocol
          "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|" + // domain name
          "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
          "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
          "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
          "(\\#[-a-z\\d_]*)?$",
        "i"
      ); // fragment locator
      return pattern.test(str);
    },
    savePassword() {
      this.loading = true;

      if (this.password.length < 6) {
        this.$grToast.toast("Senha precisa ter mais de 6 caracteres", {
          title: "Minha conta",
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });
        this.loading = false;
        return;
      }

      if (this.confirm_password === this.password) {
        var data = {
          id: "change-password",
          password: this.password,
        };

        serviceUser
          .createId(data)
          .then(() => {
            this.$grToast.toast("Senha salva com sucesso", {
              title: "Minha conta",
              variant: "info",
              autoHideDelay: 5000,
              appendToast: true,
            });
            this.loading = false;
            this.confirm_password = "";
            this.password = "";
          })
          .catch((err) => {
            // console.log(err);
            this.loading = false;
          });
      } else {
        this.$grToast.toast("Preencha os dados corretamente", {
          title: "Minha conta",
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });
        this.loading = false;
        return;
      }
    },
    saveUser() {
      this.loading = true;

      if (
        this.user.first_name === "" ||
        this.user.last_name === "" ||
        (this.user.phone.length < 15 && this.user.phone.length > 0)
      ) {
        this.$grToast.toast("Preencha os dados corretamente", {
          title: "Minha conta",
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });
        this.loading = false;
        return;
      }

      var data = {
        id: "update",
        first_name: this.user.first_name,
        last_name: this.user.last_name,
        phone: this.user.phone,
      };

      if (
        this.user_picture &&
        this.user_picture.length &&
        this.user_picture[0] &&
        this.user_picture[0].path &&
        !this.isURL(this.user_picture[0].path)
      ) {
        data.photo = this.user_picture[0].path;
      }

      if(this.user_picture[0] === 'removed'){
        data.photo = 'removed';
      }

      serviceUser
        .update(data)
        .then(() => {
          this.$grToast.toast("Dados salvos com sucesso", {
            title: "Minha conta",
            variant: "info",
            autoHideDelay: 5000,
            appendToast: true,
          });
          this.updateDados();
          this.loading = false;
        })
        .catch((err) => {
          // console.log(err);
          this.loading = false;
        });
    }, 
    async updateDados() {
      await this.$store.dispatch("updateUserProfile");
      await this.initDados();
      await this.$root.$emit("updateUserProfile");
      // location.reload()
    },
    initDados() {
      this.user = JSON.parse(JSON.stringify(this.$store.getters.user.user));

      var metas = this.$store.getters.user.user.metas;

      var photo;
      if (metas.length) {
        photo = metas.find((x) => x.meta_key === "photo");
      }

      if (photo && photo.meta_value) {
        this.user_picture = [
          {
            default: 1,
            highlight: 1,
            name: "perfil",
            path: photo.meta_value,
          },
        ];
      }
    },
    // foto usuario
    uploadImageSuccess(formData, index, fileList) {
      this.user_picture = fileList;
    },
    beforeRemove(index, done, fileList) {
      Vue.swal({
        title: "Minha conta",
        text: `Deseja remover a imagem?`,
        type: "danger",
        showCancelButton: true,
        confirmButtonText: "Remover",
        cancelButtonText: "Cancelar",
        customClass: "sweet-container",
        confirmButtonClass: "button button-danger mt-3 mb-3",
        cancelButtonClass: "button button-link-info mr-4 mt-3 mb-3",
        buttonsStyling: false,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          done();
          this.user_picture[0] = 'removed';
        }
      });
    },
    editImage(formData, index, fileList) {
      this.user_picture = fileList;
    },
    mostrarSenha(seletor) {
      const input = document.querySelector(`#${seletor}`);
      this.showPassword = !this.showPassword;

      if (this.showPassword) {
        input.setAttribute("type", "text");
        if (seletor == "password") {
          this.$refs.mostrar_senha.innerText = "Ocultar";
        } else {
          this.$refs.mostrar_nova_senha.innerText = "Ocultar";
        }
      } else {
        input.setAttribute("type", "password");
        if (seletor == "password") {
          this.$refs.mostrar_senha.innerText = "Mostrar";
        } else {
          this.$refs.mostrar_nova_senha.innerText = "Mostrar";
        }
      }
    },
    generateToken() {
      serviceGlobalToken
        .createId({ id: "generate" })
        .then((resp) => {
          // console.log(resp);
          this.global_token = resp.token;
          this.$grToast.toast("Chave Gerada com sucesso", {
            title: "Minha conta",
            variant: "info",
            autoHideDelay: 5000,
            appendToast: true,
          });
        })
        .catch((err) => {
          // console.log(err);
        });
    },
    getGlobalToken() {
      serviceGlobalToken
        .search()
        .then((resp) => {
          // console.log(resp);
          this.global_token = resp.token;
        })
        .catch((err) => {
          // console.log(err);
        });
    },
    conectGoogle() {
      if (this.google) {
        serviceGoogle
          .destroy({ id: "client/" + this.user_id })
          .then((resp) => {
            // console.log(resp);
            this.getGoogleIntegration();
          })
          .catch((err) => {
            // console.log(err);
            this.getGoogleIntegration();
          });
      } else {
        serviceGoogle
          .read({ id: "auth/geturl2" })
          .then((response) => {
            var url = response.url;
            url = url.replace('v2/', '');
            const popup = window.open(
              url,
              "janela",
              "width=895, height=690, top=30, left=200, scrollbars=no, status=no, toolbar=no, location=no, menubar=no, resizable=no, fullscreen=no"
            );
            var that = this;
            const popupTick = setInterval(function () {
              that.getGoogleIntegration();
              if (popup.closed) {
                that.getGoogleIntegration();
                clearInterval(popupTick);
                // console.log("window closed!");
                setTimeout(function () {
                  if (that.google === false) {
                    that.$grToast.toast("Login não realizado", {
                      title: "Minha conta",
                      variant: "danger",
                      autoHideDelay: 5000,
                      appendToast: true,
                    });
                  } else {
                    that.$grToast.toast("Login Realizado com sucesso", {
                      title: "Minha conta",
                      variant: "info",
                      autoHideDelay: 5000,
                      appendToast: true,
                    });
                  }
                }, 1000);
              }
            }, 500);
          })
          .catch((err) => {
            // console.log(err);
          });
      }
    },
    getGoogleIntegration() {
      serviceGoogle
        .read({ id: "client" })
        .then((resp) => {
          // console.log(resp);
          if (resp && resp[0] && resp[0] == "cliente não encontrado") {
            this.google = false;
            return;
          }
          this.google = true;
        })
        .catch((err) => {
          // console.log(err);
        });
    },
    cancelContract(){
      $crisp.push(["set", "session:segments", [["financeiro"]]]);
      $crisp.push(["do", "chat:open"]);
      $crisp.push([
          "do",
          "message:send",
          ["text", `${this.selected}. Quero cancelar meu plano.`],
      ]);
    },
    buyResources(){
      $crisp.push(["set", "session:segments", [["financeiro"]]]);
      $crisp.push(["do", "chat:open"]);
      $crisp.push([
          "do",
          "message:send",
          ["text", `Quero contratar mais recursos!`],
      ]);
    },
  },
  mounted() {
    // this.loading = true;
    this.contracts = JSON.parse(sessionStorage.getItem("userContracts")) 
    this.initDados();
    this.getGlobalToken();
    this.getGoogleIntegration();
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>
<style lang="scss">
.reasons {
  .custom-control.custom-radio {
    background: #f7f7f7;
    padding: 10px;
    border-radius: 8px;
    cursor: pointer;
    width: 100%;
    margin-bottom: 8px;
  }

  label.custom-control-label {
    margin-left: 25px;
    max-width: 95%;
    margin-bottom: 0 !important;
    font-weight: 500 !important;
  }
  .custom-control.custom-radio > value {
    margin-left: 25px;
    cursor: pointer;
    margin-bottom: 0 !important;
    width: 100%;
    margin-bottom: 5px;
  }
}
</style>
<style scoped lang="scss">
.resource {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 15px;
  margin-bottom: 15px;
  > div {
    padding: 15px 15px 8px 15px;
    border-radius: 10px;
    border: 1px solid var(--white-medium);
    display: grid;
    justify-items: center;
    text-align: center;
    background: linear-gradient(180deg, transparent 110px, var(--greenn2) 110px);
    box-shadow: 2px 2px 10px #00000008;
    &.block-resource{
      filter: grayscale(1);
      box-shadow: none;
    }
    p {
      margin-bottom: 7px !important;
      font-weight: 600;
      color: var(--greenn);
    }
    span {
      font-size: 12px;
      margin-bottom: 0 !important;
      color: white;
      font-weight: 500;
      color: var(--greenn);
    }
  }
}

.contracts {
  display: flex;
  flex-flow: column;
  gap: 15px;
  > div {
    flex: 1;
    position: relative;
    padding: 15px 15px 15px 15px;
    border-radius: 10px;
    border: 1px solid var(--white-medium);
    display: grid;
    grid-template-columns: 72px calc(100% - 87px);
    gap: 10px;
    align-items: center;
    box-shadow: 2px 2px 10px #00000008;
    height: fit-content;
    &.cancel-contract{
      filter: grayscale(1);
      box-shadow: none;
    }
    h4 {
      color: var(--greenn);
      text-transform: uppercase;
      font-size: 20px;
      font-weight: 600;
      margin: 0 0 5px 0;
    }
    p {
      margin-bottom: 0 !important;
      font-size: 14px;
    }
    h4 span {
      padding: 3px 10px;
      border-radius: 10px;
      background-color: var(--greenn2);
      color: var(--greenn);
      font-weight: 600;
      font-size: 13px;
      text-transform: uppercase;
      display: inline-block;
      margin-top: -40px;
      &.inactive {
        background-color: rgba(255, 0, 0, 0.158);
        color: rgba(148, 20, 20, 0.966);
      }
    }
    .cancel {
      position: absolute;
      font-size: 12px;
      color: var(--gray05);
      bottom: 15px;
      right: 20px;
      cursor: pointer;
    }
    .reasons {
      grid-column: span 2;
    }
  }
}

/* .TabHeader .nav-item a { 
border: none !important; 
}  */
.BaseHeaderMobile {
  padding: 0px !important;
}
.grid-tokens {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}
.info-checkbox {
  margin: 0;
  margin-top: 20px;
  margin-left: 5px;
  font-weight: 500;
}
.descricao-checkbox {
  display: block;
  margin-top: 5px;
  font-weight: normal;
  color: #81858e;
  font-size: 13px;
  max-width: 80%;
  line-height: 1.5;
}
h5 {
  font-weight: 600;
  font-size: 18px;
  color: var(--greenn);
}

.container-user {
  padding: 50px;
}
@media screen and (max-width: 768px) {
  .container-user {
    padding: 30px;
  }
}
@media screen and (max-width: 500px) {
  .container-user {
    padding: 20px;
  }
}

.info-documento {
  margin: 0;
  font-weight: normal;
  color: #81858e;
  font-size: 13px;
  line-height: 1.5;
}

.info-documento + .info-documento {
  margin-top: 5px;
}
.btn-2 {
  color: var(--bluee);
  background: rgba(33, 51, 210, 0.1);
  font-size: 12px;
  font-weight: 600;
  padding: 10px 15px;
  border-radius: 10px;
}
.aprovacao {
  display: flex;
  align-items: center;
}
.aprovacao img {
  margin-right: 10px;
}
.DISAPPROVED {
  background: rgba(255, 12, 55, 0.1);
  color: var(--red);
}
.APPROVED {
  background: var(--greenn2);
  color: var(--greenn);
}
.link-aprovado {
  font-weight: 600;
  color: var(--bluee);
}
.ver-arquivo {
  font-size: 13px;
  display: inline-block;
  padding: 5px;
}

.top-status {
  display: flex;
  align-items: center;
  gap: 30px;
}

.email-company {
  margin-top: -75px;
}
@media screen and (max-width: 768px) {
  .top-status {
    display: block;
  }
  .ver-arquivo {
    margin-bottom: 20px;
    margin-right: 20px;
  }
  .email-company {
    margin-top: initial;
  }
}

.mostrar-senha {
  font-size: 13px;
  position: absolute;
  top: 55%;
  right: 20px;
  cursor: pointer;
  color: #81858e;
}

.password-most {
  position: relative;
}

.grid-global {
  display: grid;
  grid-template-columns: 1fr 200px;
  gap: 10px;
}

.grid-global button {
  margin-top: 27px;
}

.login_button {
  cursor: pointer;
}

#googleLogin button,
#googleLogout button {
  transition: 0.3s ease;
  justify-content: space-between;
  align-items: center;
  padding: 12px 25px;
  border: 1px solid #e5e5e5;
  height: 50px;
  display: flex;
  font-size: 13px;
  border-radius: 10px;
  font-weight: 600;
}
#googleLogin img,
#googleLogout img {
  width: 18px;
  margin-right: 15px;
}

#googleLogin button:hover,
#googleLogout button:hover {
  background: #f2f2f2;
}
</style>
