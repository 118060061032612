import Rest from "@/services/Rest";

/**
 * @typedef {PannelService}
 */
export default class PannelService extends Rest {
  /**
   * @type {String}
   */
  static resource = "pannel";
}
