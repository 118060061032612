const traducao_unlayer = {
    "buttons.add_column": "Adicionar Coluna",
    "buttons.add_content": "Adicionar conteúdo",
    "buttons.add_display_condition": "Adicionar condição de exibição",
    "buttons.add_field": "Adicionar campo",
    "buttons.add_new_field": "Adicionar Novo Campo",
    "buttons.add_new_item": "Adicionar novo item",
    "buttons.add_row": "Adicionar linha",
    "buttons.add_text": "Adicionar texto",
    "buttons.apply": "Aplicar",
    "buttons.apply_effects": "Aplicar efeitos e mais",
    "buttons.back": "Voltar",
    "buttons.background": "Fundo",
    "buttons.cancel": "Cancelar",
    "buttons.change": "Mudar",
    "buttons.change_image": "Trocar Imagem",
    "buttons.close": "Fechar",
    "buttons.corners": "Cantos",
    "buttons.crop": "Cortar",
    "buttons.delete": "Apagar",
    "buttons.deselect": "Desmarcar",
    "buttons.desktop": "Área de Trabalho",
    "buttons.done": "Feito",
    "buttons.draw": "Desenhar",
    "buttons.drawing": "Desenho",
    "buttons.duplicate": "Duplicado",
    "buttons.filter": "Filtro",
    "buttons.frame": "Moldura",
    "buttons.history": "Histórico",
    "buttons.merge": "Fundir",
    "buttons.mobile": "Móvel",
    "buttons.more_options": "Mais Opções",
    "buttons.open": "Abrir",
    "buttons.remove_column": "Remover Coluna",
    "buttons.resize": "Redimensionar",
    "buttons.save": "Salvar",
    "buttons.select": "Selecionar",
    "buttons.shape": "Forma",
    "buttons.shapes": "Formas",
    "buttons.show_fewer_options": "Exibir Menos Opções",
    "buttons.show_more_options": "Exibir Mais Opções",
    "buttons.sticker": "Adesivo",
    "buttons.stickers": "Adesivos",
    "buttons.transform": "Transformar",
    "buttons.update_field": "Atualizar campo",
    "buttons.upload": "Enviar",
    "buttons.upload_image": "Enviar Imagem",
    "buttons.zoom": "Aproximar",
    "colors.black": "Preto",
    "colors.ruby": "Rubi",
    "colors.white": "Branco",
    "content_tools.button": "Botão",
    "content_tools.columns": "Colunas",
    "content_tools.divider": "Divisor",
    "content_tools.form": "Formulario",
    "content_tools.html": "HTML",
    "content_tools.image": "Imagem",
    "content_tools.menu": "Menu",
    "content_tools.social": "Social",
    "content_tools.text": "Texto",
    "content_tools.timer": "Timer",
    "content_tools.video": "Vídeo",
    "editor.action_type.label": "Tipo de ação",
    "editor.align.label": "Alinhar",
    "editor.alignment.label": "Alinhamento",
    "editor.all_sides.label": "Todos os lados",
    "editor.alternate_text.label": "Texto alternativo",
    "editor.background_color.label": "Cor de Fundo",
    "editor.background_image.center": "Centralizar",
    "editor.background_image.cover_mode": "Modo de cobertura",
    "editor.background_image.full_width": "100% de largura",
    "editor.background_image.label": "Imagem de fundo",
    "editor.background_image.repeat": "Repetir",
    "editor.border.dashed": "Tracejada",
    "editor.border.dotted": "Pontuada",
    "editor.border.label": "Borda",
    "editor.border.solid": "Sólida",
    "editor.bottom.label": "Rodapé",
    "editor.button_link.label": "Link do botão",
    "editor.color.label": "Cor",
    "editor.colors.label": "Cores",
    "editor.columns_background.label": "Fundo das colunas",
    "editor.container_padding.label": "Espaçamento do container",
    "editor.content_background_color.label": "Cor de fundo do conteúdo",
    "editor.content_width.label": "Largura do conteúdo",
    "editor.digits_color.label": "Cor dos dígitos",
    "editor.digits_font.label": "Fonte dos dígitos",
    "editor.digits_font_size.label": "Tamanho da fonte dos dígitos",
    "editor.do_not_stack_on_mobile.label": "Não empilhe no celular",
    "editor.end_time.label": "Hora final",
    "editor.fields.label": "Campos",
    "editor.font_family.label": "Familia fonte",
    "editor.font_size.label": "Font Size",
    "editor.form.custom": "Personalizar",
    "editor.form.method": "Método",
    "editor.form_alignment.label": "Alinhamento do formulário",
    "editor.form_width.label": "Largura do formulário",
    "editor.full_width.label": "100% de Largura",
    "editor.height.label": "Altura",
    "editor.hide_on_desktop.label": "Ocultar na Área de trabalho",
    "editor.hide_on_mobile.label": "Ocultar em dispositivos móveis",
    "editor.hover_background.label": "Pairar no plano de fundo",
    "editor.hover_color.label": "Cor de passagem",
    "editor.hover_text.label": "Pairar no texto",
    "editor.hover_underline.label": "Pairar no sublinhado",
    "editor.icon_spacing.label": "Espaçamento de ícone",
    "editor.icon_type.label": "Tipo de ícone",
    "editor.image.added_drawing": "Adicionado: desenho",
    "editor.image.added_frame": "Adicionado: moldura",
    "editor.image.added_overlay_image": "Adicionado: imagem de sobreposição",
    "editor.image.added_shape": "Adicionado: forma",
    "editor.image.added_sticker": "Adicionado: adesivo",
    "editor.image.added_text": "Adicionado: texto",
    "editor.image.applied_crop": "Aplicado: cortar",
    "editor.image.applied_filter": "Aplicado: filtro",
    "editor.image.applied_resize": "Aplicado: redimensionar",
    "editor.image.applied_rounded_corners": "Aplicado: cantos arredondados",
    "editor.image.applied_transform": "Aplicado: transformar",
    "editor.image.auto": "Automático",
    "editor.image.auto_width": "Largura automática",
    "editor.image.auto_width_switch_off": "Desativar a largura automática para redimensionar a imagem manualmente",
    "editor.image.brush_color": "Cor do pincel",
    "editor.image.brush_size": "Tamanho do pincel",
    "editor.image.brush_type": "Tipo do pincel",
    "editor.image.canvas_background": "Fundo da tela",
    "editor.image.changed_background": "Alterado: fundo",
    "editor.image.changed_background_image": "Alterado: imagem de fundo",
    "editor.image.changed_style": "Alterado: estilo",
    "editor.image.drop_upload": "Arraste uma nova imagem aqui, ou clique para selecionar os arquivos para enviar.",
    "editor.image.full_width_mobile": "Largura total em dispositivos móveis",
    "editor.image.image_options": "Opções da Imagem",
    "editor.image.image_url": "URL da Imagem",
    "editor.image.initial": "Inicial",
    "editor.image.label": "Imagem",
    "editor.image.loaded_state": "Carregado: estado",
    "editor.image.maintain_aspect_ratio": "Manter a proporção",
    "editor.image.main_image": "Imagem principal",
    "editor.image.objects_merged": "Objetos: fundidos",
    "editor.image.offset_x": "Offset X",
    "editor.image.offset_y": "Offset Y",
    "editor.image.outline_width": "Largura do contorno",
    "editor.image.uploading": "Enviando",
    "editor.image.upload_error": "Ocorreu um erro ao enviar sua imagem. Confirme se é um arquivo de imagem válido com o tamanho menhor {mbSize} MB.",
    "editor.image.use_percentages": "Usar porcentagens",
    "editor.image_link.label": "Link da Imagem",
    "editor.inherit_body_styles.label": "Herdar estilos de corpo",
    "editor.labels_color.label": "Cor dos rótulos",
    "editor.labels_font.label": "Fonte dos rótulos",
    "editor.labels_font_size.label": "Tamanho da fonte dos rótulos",
    "editor.layout.label": "Layout",
    "editor.left.label": "Esquerda",
    "editor.line.label": "Linha",
    "editor.line_height.label": "Altura da Linha",
    "editor.link.body": "Corpo",
    "editor.link.call_phone": "Ligar para número de telefone",
    "editor.link.mailto": "Enviar para",
    "editor.link.new_tab": "Nova Aba",
    "editor.link.open_website": "Abrir site",
    "editor.link.phone": "Telefone",
    "editor.link.same_tab": "Mesma Aba",
    "editor.link.send_email": "Enviar e-mail",
    "editor.link.send_sms": "Enviar SMS",
    "editor.link.subject": "Assunto",
    "editor.link.target": "Alvo",
    "editor.link.url": "URL",
    "editor.link_color.label": "Cor do link",
    "editor.margin.label": "Margem",
    "editor.mobile.description": "No momento, você está editando para dispositivos móveis. Troque para a versão desktop para acessar todas as opções de estilo.",
    "editor.padding.label": "Espaçamento",
    "editor.placeholder.text": "Nenhum conteúdo aqui. Arraste o conteúdo da direita.",
    "editor.play_icon_color.label": "Cor do ícone de reprodução",
    "editor.play_icon_size.label": "Tamanho do ícone de reprodução",
    "editor.play_icon_type.label": "Tipo de ícone de reprodução",
    "editor.preheader_text.description": "O pré-cabeçalho é o texto resumido logo depois da linha de assunto quando se visualiza um e-mail na caixa de entrada.",
    "editor.preheader_text.label": "Texto do Pré-cabeçalho",
    "editor.right.label": "Direita",
    "editor.rounded_border.label": "Bordar arrendodada",
    "editor.separator.label": "Separador",
    "editor.social_links.label": "Links sociais",
    "editor.space_between_fields.label": "Espaço entre os campos",
    "editor.submit_action.label": "Enviar ação",
    "editor.text.label": "Texto",
    "editor.text_align.label": "Alinhamento do Texto",
    "editor.text_color.label": "Cor do Texto",
    "editor.top.label": "Topo",
    "editor.underline.label": "Sublinhado",
    "editor.video.arrow_only": "Somente seta",
    "editor.video.video_camera": "Câmera de vídeo",
    "editor.video_url.description": "Adicione uma URL do YouTube ou do Vimeo para gerar automaticamente uma imagem de previsão. A imagem será interligada à URL fornecida.",
    "editor.video_url.label": "URL de vídeo",
    "editor.width.label": "Largura",
    "editors_panel.title.content": "Conteúdo",
    "editors_panel.title.contents": "Conteúdo",
    "editors_panel.title.rows": "Linha",
    "fields.birthday": "Aniversário",
    "fields.name": "Nome",
    "fields.phone_number": "Número de telefone",
    "fields.website": "Site",
    "fields.zip_code": "Código postal",
    "labels.align_text": "Alinhar Texto",
    "labels.blur": "Desfocar",
    "labels.category": "Categoria",
    "labels.color_picker": "Seletor de Cor",
    "labels.desktop_preview": "Visualização de Área de Trabalho",
    "labels.display_conditions": "Condições de exibição",
    "labels.editor": "Editor",
    "labels.font": "Fonte",
    "labels.format_text": "Formatar texto",
    "labels.google_fonts": "Google Fonts",
    "labels.gradient": "Gradiente",
    "labels.horizontal": "Horizontal",
    "labels.language": "Idioma",
    "labels.loading": "Carregando",
    "labels.loading_images": "Carregando imagens",
    "labels.load_more": "Carregar mais",
    "labels.merge_tags": "Mesclar marcadores",
    "labels.mobile_preview": "Visualização móvel",
    "labels.no_images": "Sem imagens",
    "labels.no_results": "Sem resultados",
    "labels.objects": "Objetos",
    "labels.outline": "Contorno",
    "labels.page": "Página",
    "labels.radius": "Raio",
    "labels.redo": "Refazer",
    "labels.search": "Pesquisar",
    "labels.search_images": "Pesquise milhões de imagens",
    "labels.shadow": "Sombra",
    "labels.size": "Tamanho",
    "labels.special_links": "Links especiais",
    "labels.stock_photos_by": "Desenvolvido por Unsplash, Pexels, Pixabay.",
    "labels.stock_photos_license": "Todas as imagens licenciadas sob a Creative Commons Zero.",
    "labels.tags": "Etiquetas",
    "labels.texture": "Textura",
    "labels.text_style": "Estilo do texto",
    "labels.timezone": "Fuso horário",
    "labels.undo": "Desfazer",
    "labels.vertical": "Vertical",
    "modals.category.invalid": "Este é um nome de categoria inválido",
    "modals.category.placeholder": "Nome de categoria",
    "modals.delete.columns": "Você perderá {columns, number} {columns, plural, one {coluna} other {colunas}}. Você tem certeza?",
    "modals.delete.confirmation": "Você tem certeza que deseja apagar este item? Esta ação não poderá ser desfeita.",
    "modals.delete.title": "Apagar",
    "modals.display_conditions.title": "Selecione uma condição de exibição",
    "modals.preview.title": "Visualização",
    "modals.save_block.title": "Salve o bloco",
    "modals.tags.description": "Etiquetas são usadas para pesquisar. Várias etiquetas podem ser separadas por uma vírgula.",
    "modals.tags.placeholder": "etiqueta1, etiqueta2",
    "option_groups.action.title": "Ação",
    "option_groups.all.title": "Tudo",
    "option_groups.blank.title": "Branco",
    "option_groups.button.title": "Botões",
    "option_groups.colors.title": "Cores",
    "option_groups.columns.title": "Colunas",
    "option_groups.column_number.title": "Coluna {columnNumber}",
    "option_groups.column_properties.title": "Propriedades da coluna",
    "option_groups.countdown.title": "Contagem regressiva",
    "option_groups.default.title": "Geral",
    "option_groups.display_condition.title": "Condição de exibição",
    "option_groups.email_settings.title": "Definições de e-mail",
    "option_groups.fields.title": "Campos",
    "option_groups.form.title": "Formulário",
    "option_groups.html.title": "HTML",
    "option_groups.icons.title": "Ícones",
    "option_groups.image.title": "Imagem",
    "option_groups.labels.title": "Labels",
    "option_groups.last_saved.title": "Salvo pela Última Vez",
    "option_groups.layout.title": "Layout",
    "option_groups.line.title": "Linha",
    "option_groups.link.title": "Link",
    "option_groups.links.title": "Links",
    "option_groups.menu_items.title": "Itens do menu",
    "option_groups.mobile.title": "Móvel",
    "option_groups.responsive_design.title": "Design receptivo",
    "option_groups.row_properties.title": "Propriedades da linha",
    "option_groups.size.title": "Tamanho",
    "option_groups.spacing.title": "Espaçamento",
    "option_groups.styles.title": "Estilos",
    "option_groups.text.title": "Texto",
    "shapes.circle": "Círculo",
    "shapes.rectangle": "Retângulo",
    "shapes.round": "Redondo",
    "shapes.square": "Quadrado",
    "sizes.large": "Grande",
    "sizes.largest": "Maior",
    "sizes.medium": "Médio",
    "sizes.small": "Pequeno",
    "sizes.smallest": "Menor",
    "tools.form.field_label": "Etiqueta do campo",
    "tools.form.field_name": "Nome do campo",
    "tools.form.field_type": "Tipo de campo",
    "tools.form.field_value": "Valor do campo",
    "tools.form.new_field": "Novo campo",
    "tools.form.options_one_per_line": "Opções (uma por linha)",
    "tools.form.placeholder_text": "Texto de marcador de posição",
    "tools.form.required_field": "Campo necessário",
    "tools.form.show_label": "Exibir etiqueta",
    "tools.form.update_field": "Atualizar campo",
    "tools.social.click_icons_to_add": "Clique nos ícones para adicionar",
    "tools.tabs.blocks": "Blocos",
    "tools.tabs.body": "Corpo",
    "tools.tabs.content": "Conteúdo",
    "tools.tabs.images": "Imagens",
    "tools.tabs.row": "Linha",
    "tools.tabs.uploads": "Uploads",
    "tools.text.personalize": "Personalizar"
}

export default traducao_unlayer;