<template>
  <div class="container-editor">
    <Preview
      ref="isPreview"
      v-show="preview"
      :typeView="typeView"
      :path_name="dados2.path_name"
      :preview="preview"
      @view="changeView"
      @newTypeView="newTypeView"
      @changePreview="startEditor"
    >
    </Preview>
    <div class="not-preview" v-show="preview == false">
      <Menu
        ref="menubar"
        v-show="!preview"
        @view="changeView"
        @show="changeShow"
        @getNewPage="getPage"
        :typeView="typeView"
        :showHidden="showHidden"
        :isTemplater="isTemplater"
        :page="page"
        :preview="preview"
        :palette="palette"
        :history="history"
        :statusHist="statusHist"
        :currentPage="pagination_page.currentPage"
        :totalPages="pagination_page.totalPages"
        @resetarPreview="startEditor(false)"
        @prevHistory="prevHistory"
        @nextHistory="nextHistory"
        @updateTemplate="getTemplate"
        @toChangePageTitle="changePageTitle"
        @openLeft="openSideLeft = !openSideLeft"
        @saveHistory="apiSetHistoryPage"
        :page_data="page_data"
        :current_data="dados2"
        :openSideLeft="openSideLeft"
      />
      <div class="page-and-side">
        <div
          class="side-left"
          style="display: flex"
          :style="openSideLeft ? 'margin-left: 0;' : ''"
        >
          <ItemConfig
            @sendData="setData"
            @itemSelected="itemSelected"
            :hoverSideLeft="hoverSideLeft"
          />
          <div class="side-left-inner">
            <div class="flex">
              <div class="list-itens" @drop.prevent="draganDropOn = false" @dragover.prevent="hoverDragover">
                <Paginas
                  v-show="item_selecionado === 'paginas'"
                  :templates="templates"
                  :paginas="paginas_template"
                  :categories="categories"
                  :hasTemplates="$store.getters.recursos.ntemplates"
                  @enviarTemplate="setTemplate"
                  @somarTemplate="addTemplate"
                  @newPage="newPage"
                />
                <div ref="Colunas" v-show="item_selecionado === 'itens'">
                  <b-tabs pills justified small style="width: 100%">
                    <b-tab title="Linhas" class="mt-2">
                      <div>
                        <div
                          v-for="(colum, idx) in columns"
                          :key="idx"
                          class="item mb-3 item coluns-components"
                          :data-index="idx"
                          :id="idx"
                          :data-item="JSON.stringify(colum)"
                          data-type="colun"
                          draggable="true"
                          @dragstart="onDragging('columns',$event,false)"
                        >
                          <div
                            class="parent-block-coluns"
                            :id="idx"
                            data-type="colun"
                            :data-index="idx"
                            :data-item="JSON.stringify(colum)"
                          >
                            <div
                              :id="idx"
                              data-type="colun"
                              :data-index="idx"
                              :data-item="JSON.stringify(colum)"
                              v-for="(item, i) in colum.coluns"
                              :key="i"
                              class="block-coluns"
                              :class="
                                (colum.name == 'Direita maior' && i == 1) ||
                                (colum.name == 'Esquerda maior' && i == 0) ||
                                (colum.name == 'Centro maior' && i == 1)
                                  ? 'bigger-column'
                                  : ''
                              "
                            >
                              0{{ i + 1 }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </b-tab>
                    <b-tab title="Linhas salvas" class="mt-2">
                      <div class="no-line" v-if="!rows.length">
                        <span>Nenhuma linha salva encontrada</span>
                      </div>
                      <div>
                        <div
                          v-for="(row, idx) in rows"
                          :key="idx"
                          class="item mb-3 coluns-components coluns-components2"
                          :data-index="idx"
                          :id="idx"
                          :data-item="JSON.stringify(row)"
                          data-type="row"
                          draggable="true"
                          @dragstart="onDragging('columns',$event ,true)"
                          :style="`${row.value[0].propsRow.backgd}; background-size: 100%; position: relative;`"
                        >
                          <div class="parent-block-coluns">
                            <div
                              v-for="(item, i) in row.value[0].coluns"
                              :key="i"
                              class="block-coluns"
                              style="background-color: #0002; color: white"
                            >
                              0{{ i + 1 }}
                            </div>
                          </div>
                          <span class="row-name">
                            {{ row.title }}
                          </span>
                          <span
                            class="delete-row"
                            @click="deleteLine(row.id, row.title)"
                          >
                            Deletar
                          </span>
                        </div>
                        <div class="more-lines" v-if="moreRows">
                          <span @click="getLines()">Mostrar Mais</span>
                        </div>
                      </div>
                    </b-tab>
                  </b-tabs>
                </div>
                <Codigo
                  :page="page"
                  :dados="page_data"
                  @sendCss="addCSS"
                  @sendJs="addJS"
                  v-show="item_selecionado === 'codigo'"
                  v-if="this.isUser.level != 'templater' && (this.dados2.metas || !this.page_id)"
                />
                <ConfigPage
                  :item_selecionado="item_selecionado"
                  :dados="page_data"
                  :selected_pageDomain="selected_pageDomain"
                  :categories="categories"
                  @envMarcaD="envMarcaD"
                  @sendData="setData"
                  @sendMarca="setMarca"
                  v-show="item_selecionado === 'config'"
                  ref="config"
                />
                <Palette
                  v-if="item_selecionado === 'palette'"
                  :palette="palette"
                  @updatePalette="updatePalette"
                />
                <div
                  ref="Componenentes.vue"
                  v-if="item_selecionado === 'componentes'"
                >
                  <div>
                    <div
                      v-for="(group, groupIndex) in componentGroups"
                      :key="groupIndex"
                    >
                      <div
                        class="d-flex justify-content-between"
                        v-b-toggle="getCollapseId(groupIndex)"
                        style="padding: 10px 0px"
                      >
                        <label
                          style="
                            cursor: pointer;
                            margin: 0 !important;
                            margin-left: 8px !important;
                          "
                          class="titulo"
                          >{{ group.head }}</label
                        >
                        <img
                          class="chevron mt-0 icon"
                          src="@/assets/editor/chevron-right.svg"
                        />
                      </div>
                      <b-collapse
                        :id="getCollapseId(groupIndex)"
                        role="tabpanel"
                        :visible="isCategoryOpen(groupIndex)"
                        accordion="component-accordion"
                      >
                        <div
                          class="p-2"
                          style="
                            position: relative;
                            padding-top: 0px !important;
                          "
                        >
                          <div
                            class="item mb-3 coluns-components components-class"
                            v-for="(
                              component, componentIndex
                            ) in group.components"
                            :key="componentIndex"
                            :data-index="
                              returnIndex(componentIndex, groupIndex)
                            "
                            :id="returnIndex(componentIndex, groupIndex)"
                            :data-item="JSON.stringify(component)"
                            draggable="true"
                            data-type="component"
                            @dragstart="onDragging('components',$event,false)"
                          >
                            <img
                              :data-index="
                                returnIndex(componentIndex, groupIndex)
                              "
                              :id="returnIndex(componentIndex, groupIndex)"
                              :data-item="JSON.stringify(component)"
                              data-type="component"
                              :src="
                                require(`@/assets/editor/${component.icon}.svg`)
                              "
                              :alt="component.type"
                              style="height: 20px; width: 20px"
                            />
                            <div style="text-align: center; line-height: 0.8; color: #333;">
                              <p>{{ component.name }}</p>
                              <span style="font-size: 12px; color: var(--white-dark);">Segure e arraste</span>
                            </div>
                            <img
                              src="@/assets/editor/arrow_component.svg"
                              alt="arrow"
                            />
                          </div>
                        </div>
                      </b-collapse>
                      <div class="separador-left"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="contain"
          ref="containResponsive"
          :class="resizing ? 'resize-cursor' : ''"
          @dragenter.prevent="openSideLeft = false"
          @dragover.prevent="hoverDragover"
          @drop.prevent="dropItem"     
        >
          <div         
            class="contain-page"
            ref="containEditor"
            v-show="render"
            :style="`${dynamicContainPage}${responsiveCustonPage}`"
            style="overflow: scroll;"      
            >
            <div v-show="!loadingIframeShow" class="loadingIframe">
              <div class="row-loading-iframe">
                <div class="shine" style="height: 100%; width: 30%;"></div>
                <div class="shine" style="height: 100%; width: 70%;"></div>
              </div>
              <div class="row-loading-iframe">
                <div class="shine" style="height: 100%; width: 70%;"></div>
                <div class="shine" style="height: 100%; width: 30%;"></div>
              </div>
              <div class="row-loading-iframe">
                <div class="shine" style="height: 100%; width: 100%;"></div>
              </div>
            </div>
            <iframe
            v-if="isLoadingPageLink"
            ref="iframe"
            @load="startEditor(true)"
            style="height: 100%; background-color: white;"
            id="iframe"
            sandbox="allow-same-origin allow-scripts allow-forms"
            :style="`${responsiveCustonPage}${computadDraganDropOn}`"          
            :src="`${returnProcessEnvNuxtApp}/editor`"
            frameborder="0"> 
            </iframe>
            <div :style="`${draganDropOn ? 'z-index:100;': 'z-index:-1;'}`" class="scroll_Top" @dragenter="scrollStart(-1)" @dragleave="scrollStop()">
              <img           
                src="@/assets/editor/chevrons-scroll.svg"
              />
            </div>
            <div :style="`${draganDropOn ? 'z-index:100;': 'z-index:-1;'}`" class="scroll_Bottom" @dragenter="scrollStart(1)" @dragleave="scrollStop()">
              <img           
                src="@/assets/editor/chevrons-scroll.svg"
              />
            </div>
          </div>
        </div>
      </div>
      <Transition name="AnimationRight" v-show="openSideRight">
        <SideRight
          ref="sideRight"
          v-show="openSideRight"
          :editaItem="editaItem"
          :index_page="index_page"
          :indexColumn="indexColumn"
          :indexComponent="indexComponent"
          :indexLine="indexLine"
          :fontOptions="fontOptions"
          :typeView="typeView"
          :palette="palette"
          @exit_right="openSideRight = false"
          @editIframe="seveEditor"
          @cancel="recoverEdit()"
          @updateOverlap="applyAllOverlaps"
          @coluns2="changeColumn"
          @equalize="changeEqualize"
          @emitColumn="changeEmitColumn"
          @emitTypeIcon="changeView"
          @fixWidth="fixWidth"
          @openClosePopUP="emitOpenClose"
          @disablePoiter="disablePoiterEvent"
          @chargeFont="chargeFontNux"
        />
      </Transition>

      <ModalRows
        :theRow="page[indexLine]"
        :page="page"
        @newLine="addNewLine()"
      />
      <ModalMark :canChangeMark="canChangeMark" @changeMark="changeMark" />
      <ModalTemplates
        @viewTemplate="viewTemplate"
        v-if="$store.getters.recursos.ntemplates"
        @enviarTemplate="setTemplate"
        :templates="templates"
        :categories="categories"
        @newPage="newPage"
      />
    </div>
    <div class="insert-ghost"></div>
  </div>
</template>

<script>
// DEBOUNCE PARA O RENDER
import _ from "lodash";
// Draggable para Linhas e Colunas
import draggable from "vuedraggable";
// MENU
import ModalRows from "@/components/Editor/ModalSaveRow.vue";
import ModalMark from "@/components/Editor/ModalMark.vue";
import ModalTemplates from "@/components/Editor/ModalTemplate.vue";
import Menu from "@/components/Editor/Menu.vue";
// SIDEBAR
// SIDELEFT ESTÀ SENDO RENDERIZADA DIRETO NO EDITOR
import ItemConfig from "@/components/Editor/SideBar/ItemConfig.vue";
import SideRight from "@/components/Editor/SideBar/SideRight.vue";
import Preview from "@/components/Editor/SideBar/preview.vue";
// PROPS DE LINHAS E COLUNAS
// import PropsRowGlobal from "@/components/Editor/Components/PropsRowGlobal.js";
import PropsColumnGlobal from "@/components/Editor/Components/PropsColumnGlobal.js";
// COMPONENTS && PROPS
import pageBasec from "@/components/Editor/Components/NewPage.js";
// COMPONENTES E COLUNAS
import Coluns from "@/components/Editor/Components/Columns.js"
import Components from "@/components/Editor/Components/Components.js"
import linstAllFonts from "@/components/Editor/Components/fontsOptions.json"
// CONTEUDO SIDE LEFT
import Paginas from "@/components/Editor/SideBar/typeItem/Paginas.vue";
import Codigo from "@/components/Editor/SideBar/typeItem/Codigo.vue";
import ChatIA from "@/components/Editor/SideBar/typeItem/ChatIA.vue";
import ConfigPage from "@/components/Editor/SideBar/typeItem/ConfigPage.vue";
import Palette from "@/components/Editor/SideBar/typeItem/Palette.vue";
import Tooltip from "@/components/Editor/Tooltip.vue";
// ANIMAÇÕES
import "./Animation.scss";
// Vue
import Vue, { ref } from "vue";
("");
// Services
import PageService from "@/services/resources/PageService";
const servicePage = PageService.build();
import LineService from "@/services/resources/LineService";
const serviceLine = LineService.build();
import LineDeleteService from "@/services/resources/LineDeleteService";
const serviceLineDelete = LineDeleteService.build();
import TemplateService from "@/services/resources/TemplateService";
const serviceTemplate = TemplateService.build();
import PageListService from "@/services/resources/PageListService";
const PaginaListService = PageListService.build();
//
import { getGoogleFonts } from "@/components/Editor/GoogleFonts.js";

export default {
  components: {
    Tooltip,
    // MENU
    ModalRows,
    ModalMark,
    ModalTemplates,
    Menu,
    draggable,
    // SIDEBAR
    ItemConfig,
    SideRight,
    // CONTEUDO SIDE LEFT
    Paginas,
    Codigo,
    ChatIA,
    ConfigPage,
    Palette,
    Preview,
  },
  computed: {
    dynamicContainPage(){
      return `${this.disableEvents? 'pointer-events: none;' : ''}${this.draganDropOn? 'z-index:99;': ''}`
    },
    computadDraganDropOn(){ 
      return this.draganDropOn ? 'pointer-events: none; position: relative; z-index:1;': ''
    },
    returnProcessEnvNuxtApp(){
      return `${process.env.NUXT_IFRAME ?? `https://3d1t05.gdigital.com.br` }`
    },
    responsiveCustonPage() {
      if (this.typeView == "desktop") {
        return `width: 100% !important; `;
      } else if (this.typeView == "mobile") {
        return `width: 400px !important; overflow: hidden !important;`;
      } else if (this.typeView == "tablet") {
        return `width: 700px !important; overflow: hidden !important;`;
      }
    },
    returnType() {
      if (this.typeView == 'desktop') {
        return 'monitor'
      } else if (this.typeView == 'tablet') {
        return 'tablet'
      } else {
        return 'phone'
      }
    },
    hasMenuVertical(){
      return (this.page[0] && this.page[0][0].coluns[0][0].type == 'MenuVertical')
    },
    componentGroups() {
      // Agrupar componentes pelo Head -->>
      const groups = {};
      this.components.forEach((component, index) => {
        if (!groups[component.head]) {
          groups[component.head] = {
            head: component.head,
            components: [],
          };
        }
        groups[component.head].components.push(component);
      });
      return Object.values(groups);
    },
  },
  methods: {
    //Salva o historico da pagina
    async onSaveHistory(page) {
        // Tipo de Debounce para não alterar apenas na ultima modificação
        if (this.blockHist) {
          this.blockHist = false
          return
        }
        if (this.page_id) {
          // adiciona o estado atual do array no histórico
          this.history.push(JSON.stringify(page));
          // incrementa o índice atual do histórico
          this.currentIndex++;
          await this.apiSetHistoryPage();
          if (this.history.length >= 10) {
            while (this.history.length >= 10) {
              // Se o array já tem 20 itens, remover o primeiro item
              this.history.shift();
            }
          }
        }
      },
    debounceOnSaveHistory: _.debounce(function(){
      this.onSaveHistory(this.page)
    },1500),

    // funcoes base 
    handlePostMessage(event) {
      const { data } = event;

      if (data && data.type) {
        this.indexLine = data.indexLine;
        const method = this[data.type];

        if (typeof method !== 'function'){
          console.error(`${data.type} não encontrado`);
          return;
        } 
        method(data);
      }
    },
 
    iframePostMessage(array){
      const iframe = this.$el.querySelector('#iframe')?.contentWindow

      if(!iframe) return;
      iframe.postMessage(array, this.returnProcessEnvNuxtApp);
    },

    async editingRelatedFunction(event){  
      this.openSideRight = false
      if(this.blockSideBar){
        this.$refs.sideRight.renderEditaItem = false
        const propsDataComponente = await this.page[event.indexLine][event.indexSubLine].coluns[event.indexColumn][event.indexComponent]
        const propsDataRow = this.page[event.indexLine][event.indexSubLine]
        this.indexLine = event.indexLine
        this.indexSubLine = event.indexSubLine
        this.indexColumn = event.indexColumn
        this.indexComponent = event.indexComponent
        if(event.indexLine !== null){
          if(event.item == "row"){
            propsDataRow.component_type = "row"
            this.editaItem = await propsDataRow
            this.historicEditaItem = JSON.parse(JSON.stringify(propsDataRow))
          }else if(event.component){
            propsDataRow.component_type = "column"
            this.editaItem = await  propsDataComponente 
            this.historicEditaItem = JSON.parse(JSON.stringify(propsDataComponente))
          }else{
            propsDataRow.component_type = "column"
            this.editaItem = await propsDataRow
            this.historicEditaItem = JSON.parse(JSON.stringify(propsDataRow))
          }
        this.$refs.sideRight.renderEditaItem = true
        this.openSideRight = true
        }
      }else{
        this.blockSideBar = true
      }
    },

    async statusEditor(){
      if(JSON.stringify(this.page) === JSON.stringify(pageBasec)){
        this.openModal("Modal-Templates")
      }
      this.loadingIframeShow = true
    },

    async startEditor(isMode, returnPreview){
      let fonts = [];
      const message ={
          type: 'startEditor',
          mode: isMode,
        };
      if(returnPreview){
        this.changeView(returnPreview)
      }
      this.preview = !isMode
      if(this.preview){
        this.openSideRight = false
        message.preview = true
        this.$refs.isPreview.viewType(this.typeView)
        this.$refs.isPreview.chargeFontPreview(await this.$refs.menubar.getAllFontFamilies())
        this.$refs.isPreview.renderPreview([
          message,
          this.synchronizePage(true),
          this.applyAllOverlaps(true),
          this.addCSS(this.getMetaValue("dynamic_css"), true),
          this.addJS(this.getMetaValue("dynamic_js"), true)
        ])
      }
      if(!this.preview){
        this.requireFont ? fonts = await this.$refs.menubar.getAllFontFamilies() : false
        fonts.includes('Montserrat') ? null : fonts.push('Montserrat')
        this.requireFont ? await this.chargeFontNux(fonts) : false
        this.synchronizePage()
        this.applyAllOverlaps()
        this.iframePostMessage(message)
        this.addCSS(this.getMetaValue("dynamic_css"))
        this.addJS(this.getMetaValue("dynamic_js"))
      }
      this.requireFont = false;
      this.statusEditor();
    }, 
    scrollStart(direction){
        const message = {
          type: "scrollStart",
          value: direction
        }
        this.iframePostMessage(message)      
    }, 
    scrollStop(){
        const message = {
          type: "scrollStop",
        }
        this.iframePostMessage(message)      
    }, 
    seveEditor(){
        const message = {
          type: "synchronizePage",
          linePage: this.page[this.indexLine],
          indexLine: this.indexLine,
          location:'index.vue',
        }
        this.iframePostMessage(message)    
        this.debounceOnSaveHistory()  
    },
    synchronizePage(isReturnObject){
      const message ={
        type: "synchronizePage",
        location:'index.vue',
        page: this.page,
      }
      if(isReturnObject){
        return message 
      }
      this.iframePostMessage(message)
      this.debounceOnSaveHistory()
    },   
    envHistoryPage(newHistory){
      const message = {type: "synchronizePage", page:newHistory, location:'index.vue',}
      this.iframePostMessage(message)
    },
    // funcoes relacionadas a edicao dos componentes 
    removeItem(position4, position, position2, position3, empty, props) {
      let page = JSON.parse(JSON.stringify(this.page));
      if (empty) {
        if (this.editaItem == this.page[position4][position]) {
          this.openSideRight = false;
        }
        let array = page[position4][position].coluns[position2];
        if (position3 == 0 && array[position3 + 1]) {
          array[1].propsColumn.alignment = array[0].propsColumn.alignment;
          array[1].propsColumn.width = array[0].propsColumn.width;
          array[1].propsColumn.spacing = array[0].propsColumn.spacing;
          array[1].propsColumn.visibility = array[0].propsColumn.visibility;
        }
        array.splice(position3, 1);
        page[position4][position].coluns[position2] = array;
        this.render = false;
        this.page = page;
        this.render = true;
        this.seveEditor()
        return;
      }
      let empty2 = {
        empty: true,
        propsColumn: props,
      };
      if (
        this.editaItem &&
        this.editaItem.id ==
          this.page[position4][position].coluns[position2][position3].id
      ) {
        this.openSideRight = false;
      }

      this.render = false;
      page[position4][position].coluns[position2][position3] = empty2;
      this.page = page;
      this.render = true;
      this.seveEditor()
    },
    recoverEdit() {
      if (!this.openSideRight ||this.historicEditaItem.id != this.editaItem.id) {
        this.$grToast.toast("Não foi possível recuperar as alterações", {
          title: "Editor",
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });
        return;
      }
      if (this.editaItem.component_type && this.editaItem.component_type != "component") {
        this.page[this.indexLine].splice(this.indexSubLine,1 ,this.historicEditaItem);

      } else if (this.historicEditaItem.component_type == "component") {

        this.page[this.indexLine][this.indexSubLine].coluns[this.indexColumn].splice(this.indexComponent, 1, this.historicEditaItem);

      } else {
        this.$grToast.toast("Não foi possível recuperar as alterações", {
          title: "Editor",
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });
        return;
      }
      this.editaItem = JSON.parse(JSON.stringify(this.historicEditaItem));
      this.seveEditor()
      this.applyAllOverlaps()
      this.openSideRight = false;
      setTimeout(() => {
        this.$refs.sideRight.rerenderSide();
      }, 300);
    },
    async duplicateLine(event) {
      this.openSideRight = false;
      var page = JSON.parse(JSON.stringify(this.page));
      // a constante newItem passa a conter todo o conteúdo da linha a ser copiada, sem fazer referência direta
      const newItem = JSON.parse(JSON.stringify(page[event.indexLine]));

      for (let index = 0; index < newItem.length; index++) {
        const element = newItem[index];
        // cria um novo ID para a cópia, de forma a evitar conflitos
        element.id = Math.floor((1 + Math.random()) * 0x10000)
          .toString(16)
          .substring(1);
      }

      // a linha cópia é adicionada uma posição depois de sua linha origem
      page.splice(event.indexLine + 1, 0, newItem);
      this.render = false;
      this.page = await page;
      await this.applyAllOverlaps();
      this.render = true;
      this.synchronizePage()
    },
    copyItem(event) {
      this.itemOnCopy = JSON.parse(JSON.stringify(event.mensageTo));
    },
    async pasteItem(event) {
      let oldColunm = JSON.parse(JSON.stringify(event.mensageTo.propsColumn));
      let newColunm = JSON.parse(JSON.stringify(this.itemOnCopy));
        newColunm.propsColumn = oldColunm;
        newColunm.id = Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
        var page = JSON.parse(JSON.stringify(this.page));
      page[event.indexLine][event.indexSubLine].coluns[event.indexColumn][event.indexComponent] = newColunm;
      this.page = await page;
      this.seveEditor()
      this.iframePostMessage(mensage)
    },
    dropItemsAllocated(event){
      const oriIndx = this.saveOrigin;
      if(event.mensageTo){
        this.saveOrigin = event
        return;
      }
      let origin = JSON.parse(JSON.stringify(this.page[oriIndx.indexLine][oriIndx.indexSubLine].coluns[oriIndx.indexColumn][oriIndx.indexComponent]));
      let destino = JSON.parse(JSON.stringify(this.page[event.indexLine][event.indexSubLine].coluns[event.indexColumn][event.indexComponent]))
      let PropsOrigin = origin.propsColumn
      let PropsDestino = destino.propsColumn
      if(destino.empty){        
        this.page[oriIndx.indexLine][oriIndx.indexSubLine].coluns[oriIndx.indexColumn][oriIndx.indexComponent] = destino
        this.page[oriIndx.indexLine][oriIndx.indexSubLine].coluns[oriIndx.indexColumn][oriIndx.indexComponent].propsColumn = PropsOrigin
        this.page[event.indexLine][event.indexSubLine].coluns[event.indexColumn][event.indexComponent] = origin
        this.page[event.indexLine][event.indexSubLine].coluns[event.indexColumn][event.indexComponent].propsColumn = PropsDestino

        if(event.indexLine == oriIndx.indexLine){
          this.indexLine = event.indexLine
          this.seveEditor()
        }else{
          this.synchronizePage()
        }
      }

    },
    async duplicateItem(event) {
      var page = JSON.parse(JSON.stringify(this.page));
      let newItem = JSON.parse(JSON.stringify(event.mensageTo[event.indexComponent]));
      newItem.id = Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);

      page[event.indexLine][event.indexSubLine].coluns[event.indexColumn].length < this.limite_columns
        ? page[event.indexLine][event.indexSubLine].coluns[event.indexColumn].splice(
            event.indexComponent + 1,
            0,
            newItem
          )
        : this.limiteColumns();
      this.render = false;
      this.page = await page;
      this.render = true;
      this.seveEditor()
    },
    // Funcoes relacionadas a edição das Columns
    async addNewLineBottom(event) {
      // seleciona a opção "uma coluna" no array de colunas
      let item = JSON.parse(JSON.stringify(this.columns[0]));
      // atribui um id aleatório para a nova linha
      item.id = Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
      // seta a largura da coluna única como 100
      item.coluns[0][0].propsColumn.width = 100;
      this.index_page = 0;
      this.indexLine = event.indexLine;
      // insere a linha nova no page
      let page = JSON.parse(JSON.stringify(this.page));
      page.splice(event.indexLine + 1, 0, []);
      page[event.indexLine + 1].push(item);
      this.page = await page;
      this.synchronizePage()
    },
    duplicateColuna(event) {
      var page = JSON.parse(JSON.stringify(this.page));
      let newItem = JSON.parse(JSON.stringify([event.mensageTo][0]));
      for (let i = 0; i < newItem.length; i++) {
        const element = newItem[i];
        if (!element.empty) {
          element.id = Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
        }
      }
      // Aqui valida se a linha atingiu o limite de coluna
      page[event.indexLine][event.indexSubLine].coluns.length < this.limite_columns
        ? page[event.indexLine][event.indexSubLine].coluns.splice(event.indexColumn + 1, 0, newItem)
        : this.limiteColumns();
      if (this.SideRight === true) {
        this.SideRight = false;
      }

      // Aqui pega o valor que cada coluna vai ter de acordo com o número de colunas que possuem ao menos um item visível para monitores
      var coluns_length_value = Math.round(
        100 /
          page[event.indexLine][event.indexSubLine].coluns.filter(
            (colArray) =>
              colArray[0].propsColumn.visibility.monitor &&
              (colArray.some((item) => item.empty === true) ||
                colArray.some(
                  (item) => item.propsData.visibility.monitor === true
                ))
          ).length
      );

      // Aqui distribui o valor do width de cada coluna de acordo com o coluns.length
      for (let i = 0; i < page[event.indexLine][event.indexSubLine].coluns.length; i++) {
        for (let x = 0; x < page[event.indexLine][event.indexSubLine].coluns[i].length; x++) {
          page[event.indexLine][event.indexSubLine].coluns[i][x].propsColumn.width =
            coluns_length_value;
        }
      }
      this.render = false;
      this.page = page;
      this.render = true;
      this.seveEditor()
      // this.fixWidth(index4, position)
    },
    sizeWidthColumns(event){
      if(event.mensageTo == 'blockSideBar'){
        this.blockSideBar = false
      }else{
        this.page[event.indexLine][event.indexSubLine].coluns[event.indexColumn][0].propsColumn.width = event.mensageTo.Column
        this.page[event.indexLine][event.indexSubLine].coluns[event.mensageTo.indexColumnParent][0].propsColumn.width = event.mensageTo.ColumnParent
        this.fixWidth(event.indexLine,event.indexSubLine)
      }
    },
    limiteColumns() {
      this.$grToast.toast("Limite de coluna atingido", {
        title: "Editor",
        variant: "danger",
        autoHideDelay: 5000,
        appendToast: true,
      });
    },
    async addColumnAboveBelow(event) {
      var page = JSON.parse(JSON.stringify(this.page));
      let newIndex = event.mensageTo ? event.indexComponent + 1 : event.indexComponent;
      let newProps = JSON.parse(JSON.stringify(PropsColumnGlobal));
      if (newIndex == 0) {
        let firstColunm =
          page[event.indexLine][event.indexSubLine].coluns[event.indexColumn][0].propsColumn;
        newProps.alignment = firstColunm.alignment;
        newProps.width = firstColunm.width;
        newProps.spacing = firstColunm.spacing;
        newProps.visibility = firstColunm.visibility;
      }
      page[event.indexLine][event.indexSubLine].coluns[event.indexColumn].length < this.limite_columns
        ? page[event.indexLine][event.indexSubLine].coluns[event.indexColumn].splice(newIndex, 0, {
            empty: true,
            propsColumn: newProps,
          })
        : this.limiteColumns();
      this.render = false;
      this.openSideRight = false;
      this.page = await page;
      this.render = true;
      this.synchronizePage()
    },
    addColumnSide(event) {
      let page = JSON.parse(JSON.stringify(this.page));
      if (page[event.indexLine][event.indexSubLine].coluns.length >= this.limite_columns) {
        this.limiteColumns();
        return;
      }
      let newIndex = event.mensageTo ? event.indexColumn : event.indexColumn + 1;
      let newProps = JSON.parse(JSON.stringify(PropsColumnGlobal));
      newProps.width = 25;
      page[event.indexLine][event.indexSubLine].coluns.splice(newIndex, 0, [
        { empty: true, propsColumn: newProps },
      ]);
      this.render = false;
      this.page = page;
      this.render = true;
      this.fixWidth(event.indexLine, event.indexSubLine);
    },
    async addColumnLevel(event) {
      // copia o page
      let page = JSON.parse(JSON.stringify(this.page));
      // seleciona a opção "uma coluna" no array de colunas
      let item = JSON.parse(JSON.stringify(this.columns[0]));
      // atribui um id aleatório para a nova linha
      item.id = Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
      // seta a largura da coluna única como 100
      item.coluns[0][0].propsColumn.width = 100;
      // insere a linha nova no page
      page[event.indexLine].push(item);

      this.render = false;
      this.page = await page;
      this.render = true;
      this.synchronizePage()

    },
    removerItemColumn(event){
      this.indexLine = event.indexLine
        const item = this.page[event.indexLine][event.indexSubLine]
        const empty = this.page[event.indexLine][event.indexSubLine].coluns[event.indexColumn][event.indexComponent].empty
        const propsColumn = this.page[event.indexLine][event.indexSubLine].coluns[event.indexColumn][event.indexComponent].propsColumn
        if(event.mensageTo || empty && item.coluns[event.indexColumn].length == 1){
          this.removeColumn(item,event.indexSubLine,event.indexColumn,event.indexComponent,event.indexLine)
          return;
        }else{
          this.removeItem(event.indexLine,event.indexSubLine,event.indexColumn,event.indexComponent,empty,propsColumn)
          return;
        }
    },
    removeColumn(item, indexSubLine, indexColumn, indexComponent, indexLine) {
      // fecha a side bar
      this.openSideRight = false;
      // Valida se a coluna é a última da sub-linha
      if (item.coluns.length == 1) {
        let cloneInLestSubline = JSON.parse(JSON.stringify(this.page))
        // Valida se a sub-linha é a última da linha
        // Caso seja, apaga a linha
        if (cloneInLestSubline[indexLine].length == 1) {
          const array = cloneInLestSubline;
          if (this.indexColumn === indexLine) {
            this.openSideRight = false;
          }
          array.splice(indexLine, 1);
          cloneInLestSubline = array;
          this.synchronizePage() 
          return;
          // Caso não seja, apaga apenas a sub-linha
        } else {
          const array = [...cloneInLestSubline[indexLine]];
          array.splice(indexSubLine, 1);
          if(indexSubLine == 0){
            let cloneProps = cloneInLestSubline[indexLine][0]
            array[0].propsRow = cloneProps.propsRow
            array[0].id = cloneProps.id
          }
          cloneInLestSubline[indexLine] = array;
          this.page = cloneInLestSubline
          this.seveEditor()
          return;
        }
      }

      // copia o page
      var page = JSON.parse(JSON.stringify(this.page));
      let array = page[indexLine][indexSubLine].coluns;

      // caso a coluna não seja visível no monitor, ela é simplesmente deletada e a função termina
      if (!item.coluns[indexColumn][indexComponent].propsColumn.visibility.monitor) {
        // Remove a coluna
        array.splice(indexColumn, 1);
        this.render = false;
        this.page = page;
        this.render = true;
        this.seveEditor()
        return;
      }

      // pega o valor do Width da coluna que vai remover
      const valueRemovedColumn = JSON.parse(
        JSON.stringify(item.coluns[indexColumn][indexComponent].propsColumn.width)
      );

      // Cria o array visible, no qual cada item equivale a visibilidade da coluna de mesmo index na sublinha
      let visible = page[indexLine][indexSubLine].coluns.map(
        (item) => item[0].propsColumn.visibility.monitor
      );
      for (let index = 0; index < visible.length; index++) {
        if (visible[index]) {
          if (
            page[indexLine][indexSubLine].coluns[index].some(
              (item2) => item2.empty
            ) == false &&
            page[indexLine][indexSubLine].coluns[index].some(
              (item2) => item2.propsData.visibility.monitor
            ) == false
          ) {
            visible[index] = false;
          }
        }
      }

      // Encontra a posição da coluna anterior que é visível no monitor
      let nextColumnPosition = indexColumn - 1;
      while (nextColumnPosition >= 0 && !visible[nextColumnPosition]) {
        nextColumnPosition--;
      }

      // Caso não tenha encontrado uma coluna anterior, procura por uma coluna posterior
      if (nextColumnPosition < 0) {
        nextColumnPosition = indexColumn + 1;
        while (
          nextColumnPosition <= array.length &&
          !visible[nextColumnPosition]
        ) {
          nextColumnPosition++;
        }
      }

      // Caso a posição encontrado seja válido, adiciona a largura da coluna deletada a coluna com a posição encontrada
      if (nextColumnPosition >= 0 && nextColumnPosition < array.length) {
        array[nextColumnPosition][0].propsColumn.width += valueRemovedColumn;
      }

      // Remove a coluna
      array.splice(indexColumn, 1);

      this.render = false;
      this.page[indexLine][indexSubLine].coluns = array;
      this.render = true;
      this.openSideRight = false;
      // chama a função fix-Width par avalidar a largura das colunas e ajusta-las, caso necessário
      this.fixWidth(indexLine, indexSubLine, indexComponent);
      this.seveEditor() 
    },
    fixWidth(line, subline, column) {
      let item = JSON.parse(JSON.stringify(this.page[line][subline]));
      // Pega a largura de alguma das colunas visiveis para monitores
      const customizedWidths = item.coluns
        .filter(
          (colArray) =>
            colArray.some(
              (item) => item.empty || item.propsData.visibility.monitor === true
            ) && colArray[0].propsColumn.visibility.monitor
        )
        .map((colArray) => parseInt(colArray[0].propsColumn.width * 100) / 100);

      if (customizedWidths.length) {
        const sum = customizedWidths.reduce((total, width) => total + width, 0);
        if (sum != 100) {
          // Se a soma de todas as larguras diferir de 100, o código seguinte compensa a diferença modificando todos as larguras proporcionalmente
          const scaleFactor = 100 / sum;
          customizedWidths.forEach((width, i) => {
            customizedWidths[i] = Math.round(width * scaleFactor * 100) / 100;
          });

          // Se ainda sim a soma não se igualar a 100 (por conta do math.round), o código seguinte joga a diferença para a primeira coluna
          const valoresFinais = customizedWidths;
          const totalCustomizedWidth = valoresFinais.reduce(
            (a, b) => parseFloat(a) + parseFloat(b)
          );
          const resultToDistribuition = 100 - totalCustomizedWidth;
          if (resultToDistribuition !== 0) {
            customizedWidths[0] = customizedWidths[0] + resultToDistribuition;
          }
        }

        let visible = this.page[line][subline].coluns.map(
          (item) => item[0].propsColumn.visibility.monitor
        );
        for (let index = 0; index < visible.length; index++) {
          if (visible[index]) {
            if (
              this.page[line][subline].coluns[index].some(
                (item2) => item2.empty
              ) == false &&
              this.page[line][subline].coluns[index].some(
                (item2) => item2.propsData.visibility.monitor
              ) == false
            ) {
              visible[index] = false;
            }
          }
        }

        // Insere os novos valores de largura de volta em seu lugar de origem
        let y = 0;
        for (let i = 0; i < item.coluns.length; i++) {
          if (visible[i]) {
            this.page[line][subline].coluns[i][0].propsColumn.width =
              customizedWidths[y];
            y++;
          }
        }
        if (
          (column || column === 0) &&
          this.page[line][subline].coluns[column][0].propsColumn.visibility
            .monitor == false
        ) {
          let newWidth =
            this.page[line][subline].coluns[column][0].propsColumn.width *
            (100 /
              (100 -
                this.page[line][subline].coluns[column][0].propsColumn.width));
          this.page[line][subline].coluns[column][0].propsColumn.width =
            newWidth;
        }
      }
      this.seveEditor()
    },
    // Funcoes relacionadas a edição das linhas
    async spliceNewLinePage(event){
      let page = JSON.parse(JSON.stringify(this.page))
      let lineName = this.itemDragging.dataTransfer.name
      if(lineName == 'Direita maior' || lineName == 'Esquerda maior'|| lineName == 'Centro maior' ){
        if(lineName == 'Direita maior'){
          this.itemDragging.dataTransfer.coluns[0][0].propsColumn.width = 30
          this.itemDragging.dataTransfer.coluns[1][0].propsColumn.width = 70
        }else if(lineName == 'Esquerda maior'){
          this.itemDragging.dataTransfer.coluns[0][0].propsColumn.width = 70
          this.itemDragging.dataTransfer.coluns[1][0].propsColumn.width = 30
        }else {
          this.itemDragging.dataTransfer.coluns[0][0].propsColumn.width = 20
          this.itemDragging.dataTransfer.coluns[1][0].propsColumn.width = 60
          this.itemDragging.dataTransfer.coluns[2][0].propsColumn.width = 20
        }
      }
      if(event.mensageTo == "lineUp" || !event.indexLine && !event.indexSubLine){
        const dataTransfer = this.itemDragging.customLine ? this.itemDragging.dataTransfer : [...[this.itemDragging.dataTransfer]]
        dataTransfer[0].id = Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
        if(event.mensageTo == "lineUp"){
          await page.splice(event.indexLine,0,dataTransfer)
        }else if(!event.indexLine && !event.indexSubLine){
          await page.splice(this.page.length,0,dataTransfer)
        }
      } else if(event.mensageTo == "colunUp" || event.mensageTo == "colunDown"){
        const dataTransfercolumns = this.itemDragging.dataTransfer
        if(event.mensageTo == "colunUp"){
          let line = page[event.indexLine]
          let savePropsrow = JSON.parse(JSON.stringify(line[0].propsRow)) 
          let saveNewPropsrow = JSON.parse(JSON.stringify(dataTransfercolumns.propsRow))
          dataTransfercolumns.id = line[0].id 

          await page[event.indexLine].splice(event.indexSubLine,0,dataTransfercolumns)

          line[0].propsRow = savePropsrow
          line[1].propsRow = saveNewPropsrow
          line[1].id = Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
        }
        if(event.mensageTo == "colunDown"){
          dataTransfercolumns.id = Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);       
          await page[event.indexLine].splice(event.indexSubLine+1,0,dataTransfercolumns)
        }
      } else{
        const dataTransfer = this.itemDragging.customLine ? this.itemDragging.dataTransfer : [...[this.itemDragging.dataTransfer]]
        dataTransfer[0].id = Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
        await page.splice(this.page.length,0,dataTransfer)
        
      }
      this.page = await page
      this.synchronizePage()
    },
    findCustomIndex(event, page, direction){
      let index
      if(direction == "next"){
        for (let i = event.indexLine + 1; i < page.length; i++) {          
          const line = page[i][0]
          if(line.propsRow[this.returnType]){
            index = i 
            break;
          }
        }
      }else if (direction == "reverse"){
        for (let i = event.indexLine - 1; i >= 0; i--) {
          const line = page[i][0];
          if(line.propsRow[this.returnType] == true){
            index = i
            break;
          }
        }
      }
      return index
    },
    async moveUpOrDownRow(event) {
      this.openSideRight = false
      let page = JSON.parse(JSON.stringify(this.page));
      let element = page.splice(event.indexLine, 1)[0];
      let indexSplice;
      if(!this.showHidden){
        if(event.mensageTo){
          indexSplice = this.findCustomIndex(event, this.page,"next")
        }else{
          indexSplice = this.findCustomIndex(event, this.page,"reverse")
        }
      }else{
        indexSplice = event.mensageTo ? event.indexLine + 1 : event.indexLine - 1
      }
      await page.splice(indexSplice, 0, element);

      this.page = await page;
      this.synchronizePage()
    }, 
    removeRow(event) {
      this.openSideRight = false;
      const array = this.page;
      if (this.indexColumn === event.indexLine) {
        this.openSideRight = false;
      }
      array.splice(event.indexLine, 1);
      this.page = array;
      this.synchronizePage()
    },
    async moveUp(event) {
      this.openSideRight = false
      let page = JSON.parse(JSON.stringify(this.page));
      // Verificar se é possível mover o elemento para cima -->>
      if (event.indexSubLine > 0) {
        if (event.indexSubLine == 1) {
          page[event.indexLine][1].id = page[event.indexLine][0].id;
          page[event.indexLine][0].id = Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
        }
        // Obtém a variável propsRow do índice 0 -->>
        let propsRow = page[event.indexLine][0].propsRow;
        // Remove o elemento do índice atual -->>
        let element = page[event.indexLine].splice(event.indexSubLine, 1)[0];
        // Inseri o elemento no índice acima -->>
        await page[event.indexLine].splice(event.indexSubLine - 1, 0, element);
        // Atribui a variável propsRow ao novo índice -->>
        page[event.indexLine][event.indexSubLine - 1].propsRow = propsRow;
        this.page = page;
      }
      this.seveEditor()
    },
    async moveDown(event) {
      this.openSideRight = false
      var page = JSON.parse(JSON.stringify(this.page));
      // Verificar se é possível mover o elemento para baixo -->>
      if (event.indexSubLine != page[event.indexLine].length - 1) {
        if (event.indexSubLine == 0) {
          page[event.indexLine][1].id = page[event.indexLine][0].id;
          page[event.indexLine][0].id = Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
        }
        // Obtém a variável propsRow do índice anterior -->>
        var propsRow = page[event.indexLine][event.indexSubLine].propsRow;
        // Remove o elemento do índice atual -->>
        var element = page[event.indexLine].splice(event.indexSubLine, 1)[0];
        // Inseri o elemento no índice abaixo -->>
        await page[event.indexLine].splice(event.indexSubLine + 1, 0, element);
        // Atribui a variável propsRow ao índice anterior -->>
        page[event.indexLine][event.indexSubLine].propsRow = propsRow;
        this.page = page;
      }
      this.seveEditor()
    },
  // Outros
    getMetaValue(key = false){
      if(!key){return}
        return this.page_data.metas.find(meta=>{
          return meta.meta_key == key
        })?.meta_value
    },
    updatePalette(data) {
      this.palette = data;
    },
    changeMark(dados) {
      let data = {marca:dados,title:this.page_data.title};
      this.$refs.config.updateMark(data);
    },
    changePageTitle(name) {
      this.page_data.title = name;
    },
    setMarca(marca) {
      this.page_data.marca = marca;
      this.viewMarca = marca;
    },
    returnIndex(componentIndex, groupIndex) {
      let total = componentIndex;
      for (let i = 0; i < groupIndex; i++) {
        total += this.componentGroups[i].components.length;
      }
      return total;
    },
    getCollapseId(index) {
      return `collapse-${index}`;
    },
    isCategoryOpen(idx) {
      if (idx === 0) {
        return idx === 0;
      }
      return this.openCategories.includes(idx);
    },
    stopResize() {
      this.resizing = false;
      this.isBefore = false;
      this.fixWidth(this.resizingElement.index4, this.resizingElement.index);
    },
    openModal(data) {
      this.$bvModal.show(data);
    },
    emitTypeIcon(type) {
      this.typeView = type;
    },
    //Preview e historico
    newTypeView(newValue){
      this.typeView = newValue
    },
    nextAndPrev(event) {
      // verifica se as teclas CTRL e Z ou Y foram pressionadas
      if (event.ctrlKey && event.key === "z") {
        // impede o comportamento padrão da tecla (ex: abrir o menu de histórico do navegador)
        event.preventDefault();
        this.prevHistory();
      }
      if (event.ctrlKey && event.key === "y") {
        this.nextHistory();
      }
    },
    async nextHistory() {
      this.openSideRight = false;
      if (this.page_id) {
        if (this.pagination_page.currentPage === 1) {
          this.$grToast.toast("Não há alterações para refazer", {
            title: "Editor",
            variant: "danger",
            autoHideDelay: 5000,
            appendToast: true,
          });
          return;
        }
        this.pagination_page.currentPage--;
        this.actionHist = 'redo'
        await this.apiGetHistoryPage();
      } else {
        // Busca o no sessionStorage o ultimo save
        var getHistory = JSON.parse(
          sessionStorage.getItem("prevHistoryUnsaved")
        );
        if (getHistory) {
          // Restaura a ultima modificação feita quando clicou pra voltar
          this.page = JSON.parse(getHistory);
        }
        const mensage = this.synchronizePage(true)
        this.iframePostMessage(mensage)
      }
    },
    async prevHistory() {
      // Busca o no sessionStorage
      this.openSideRight = false;
      if (this.page_id) {
        if (
          this.pagination_page.currentPage > this.pagination_page.totalPages
        ) {
          this.$grToast.toast("Não há alterações para desfazer!", {
            title: "Editor",
            variant: "danger",
            autoHideDelay: 5000,
            appendToast: true,
          });
          return;
        }
        this.pagination_page.currentPage++;
        this.actionHist = 'undo'
        await this.apiGetHistoryPage();
      } else {
        var getHistory = JSON.parse(
          sessionStorage.getItem("historyEditorUnsaved")
        );
        if (getHistory && getHistory.length > 1) {
          // Se existir , volta na alteração anterior
          var returnIndexHistory = getHistory[getHistory.length - 2];
          // Quando voltar , pega o valor do index
          var currentIndex = getHistory.length - 2;
          // Pega o ultimo save e salva pra desfazer o voltar
          var returnNextHistory = getHistory[getHistory.length - 1];
          sessionStorage.setItem(
            "prevHistoryUnsaved",
            JSON.stringify(returnNextHistory)
          );
          getHistory.pop();
          this.history.pop();
          sessionStorage.setItem(
            "historyEditorUnsaved",
            JSON.stringify(getHistory)
          );
          // Coloca this.page com o ultimo elemento
          this.blockHist = true
          this.openSideRight = false
          this.page = JSON.parse(returnIndexHistory);
          // Volta o novo valor do Index
          this.currentIndex = currentIndex;
          const mensage = this.synchronizePage(true)
          this.iframePostMessage(mensage)
        }
      }
    },
    clickHistory(index) {
      this.page = JSON.parse(this.history[index]);
    },
    async apiSetHistoryPage() {
      if (!this.history.length) {
        return
      }
      var data = {
        id: `/save-historic`,
        historic: this.page ? this.page : JSON.parse(this.history[this.history.length - 1])
      };
      if (this.deleteHist.length > 1) {
        this.deleteHist.pop()
        data.historics_delete = this.deleteHist
      }
      this.statusHist = 'load'
      if (this.isUser.level == "templater") {
        data.template_id = this.page_id;
        await serviceTemplate
          .createId(data)
          .then((resp) => {
            this.statusHist = 'save'
            this.deleteHist = [resp.id];
            this.pagination_page.currentPage = 1;
            this.pagination_page.totalPages = resp.total 
          })
          .catch((error) => {
            this.deleteHist = [];
            this.statusHist = 'error'
          })  
          .finally(() => {
          });
        return;
      }
      data.page_id = this.page_id;
      await servicePage
        .createId(data)
        .then((resp) => {
          this.statusHist = 'save'
          this.deleteHist = [resp.id];
          this.pagination_page.currentPage = 1;
          this.pagination_page.totalPages = resp.total 
        })
        .catch((error) => {
          this.deleteHist = [];
          this.statusHist = 'error'
        })
        .finally(() => {
        });
    },
    async apiGetHistoryPage() {
      var data = {
        id: `/get-historic/${this.page_id}?page=${
          this.pagination_page.currentPage
        }`,
      };
      this.statusHist = 'undo'
      if (this.isUser.level == "templater") {
        serviceTemplate
          .read(data)
          .then((resp) => {
            this.pagination_page.totalPages = resp.historic[0].total;
            this.pagination_page.currentPage = resp.historic[0].current_page;
            this.currentIndex = 1;
            if (
              resp &&
              resp.historic &&
              resp.historic.length &&
              resp.historic[0] && 
              resp.historic[0].data && 
              resp.historic[0].data.length
            ) {
              var new_history = Array.isArray(resp.historic[0].data[0].historic) ? resp.historic[0].data[0].historic : JSON.parse(resp.historic[0].data[0].historic);
              this.history.push(new_history);
              this.envHistoryPage(new_history)
              sessionStorage.setItem(
                `historyEditor${this.page_id ? this.page_id : id}`,
                JSON.stringify([new_history])
              );
              this.blockHist = true;
              this.page = new_history;
              if (this.actionHist == 'undo') {
                this.deleteHist.push(resp.historic[0].data[0].id)
              } else if (this.actionHist == 'redo') {
                this.deleteHist.pop()
              }
            } else {
              if (id) {
                this.page_id = id;
              }
            }
            this.statusHist = 'none'        
          })
          .catch((error) => {
            this.statusHist = 'error'
          });
        return;
      }
      await servicePage
        .read(data)
        .then((resp) => {
          this.pagination_page.totalPages = resp.historic[0].total;
          this.pagination_page.currentPage = resp.historic[0].current_page;
          this.currentIndex = 1;
          if (
            resp &&
            resp.historic &&
            resp.historic.length &&
            resp.historic[0] && 
            resp.historic[0].data && 
            resp.historic[0].data.length
          ) {
            var new_history = Array.isArray(resp.historic[0].data[0].historic) ? resp.historic[0].data[0].historic : JSON.parse(resp.historic[0].data[0].historic);
            this.blockHist = true;
            this.page = new_history;
            this.envHistoryPage(new_history)
            if (this.actionHist == 'undo') {
              this.deleteHist.push(resp.historic[0].data[0].id)
            } else if (this.actionHist == 'redo') {
              this.deleteHist.pop()
            }
          } else {
            if (this.page.length == 0) {
              this.openModal("Modal-Templates");
            }
            if (id) {
              this.page_id = id;
              this.apiSetHistoryPage();
            }
          }
          this.statusHist = 'none'
        })
        .catch((error) => {
          this.statusHist = 'error'
        });
    },
    //
    checkText: _.debounce(function () {
      this.render = true;
    }, 100),
    //sideRight emit functions 
    disablePoiterEvent: _.debounce(function (bool) {
      this.disableEvents = bool;
    }, 100),
    emitOpenClose(bool, props){
      //console.log("CHOGOUU",{type:"emitOpenClose", props: props, boolean:bool} );
      this.iframePostMessage({type:"emitOpenClose", props: props, boolean:bool})
    },
    closePopUp(){
      this.editaItem.propsData.button.viewPopup = false
    },
    changeEmitColumn(data) {
      var editaItem = JSON.parse(JSON.stringify([...this.page]));
      editaItem[data.index].coluns[data.index2][data.index3];
      for (
        let i = 0;
        i < editaItem[data.index].coluns[data.index2].length;
        i++
      ) {
        const element = editaItem[data.index].coluns[data.index2][i];
        element.propsColumn = data.new_props;
      }
      this.page = editaItem;
    },
    changeEqualize(data) {
      var editaItem = JSON.parse(JSON.stringify([...this.page]));
      for (let i = 0; i < editaItem[data.index_page].coluns.length; i++) {
        const element = editaItem[data.index_page].coluns[i];
        for (let x = 0; x < element.length; x++) {
          const element2 = element[x];
          element2.propsColumn.height[this.typeView].height = data.height;
        }
      }
      this.page = editaItem;
    },
    changeColumn(coluns) {
      this.page[this.indexLine][this.index_page].coluns = coluns;
    },
    applyAllOverlaps(preview, template) {
      let overlaps = "";
      let pagenew = this.page 
      if(template){
        pagenew = template
      }
      if(pagenew && pagenew.length){
        pagenew.forEach((element)=>{
          let row = element[0];
          if (
            row.propsRow.backgd_und == "Imagem" &&
            row.propsRow.backgd_overlap_und != "Nenhuma"
          ) {
            if (row.propsRow.backgd_overlap_und == "Cor") {
              overlaps += `.overlap_${row.id}::after {content: ""; position: absolute; top: 0; left: 0; width: 100%; height: 100%; background-color: ${row.propsRow.backgd_overlap_cor}; } `;
            } else {
              overlaps += ` .overlap_${row.id}::after { content: ""; position: absolute; top: 0; left: 0; width: 100%; height: 100%; background: linear-gradient(${row.propsRow.backgd_overlap_deg}deg, ${row.propsRow.backgd_overlap_c1}, ${row.propsRow.backgd_overlap_c2}); }`;
            }
          }
        })
        const mensage = { type:"overlapClass", class: overlaps}
        if(preview){
          return mensage
        }
        this.iframePostMessage(mensage)
      }
    },
    async chargeFontNux(font){
      let fonts = []
      font.forEach(async(item)=>{
        this.fontsLoaded.includes(item) ? null : fonts.push(item) && this.fontsLoaded.push(item)
      })
      const mensage = {type:'chargeFontOnly', fontOnly:fonts}
      if(mensage.fontOnly.length){
        this.$refs.isPreview.renderPreview([mensage])
        this.iframePostMessage(mensage)
      }
    },
    //Side left
    envMarcaD(bool){
      const mensage = { type:'responsiveWatermark',watermark: bool}
      this.iframePostMessage(mensage)
    },
    itemSelected(item) {
      this.openSideLeft = true;
      this.item_selecionado = item;
    },
    setData(dados) {
      this.page_data = {...this.page_data, ...dados};
    },
    // controle da this.page
    getPage(page_id) {
      return new Promise((resolve, reject) => {
        let data = {
          id: `get/${page_id}`,
        };

        servicePage
          .read(data)
          .then((resp) => {
            this.dados2 = resp;
            this.page_data = resp;
            this.current_data = resp;
            this.page = resp.json;
            // busca o meta para verificar se a pagina ja existe
            let isNew = resp.metas.find(x => x.meta_key == 'isNew');
            if(!isNew){
              this.openModal("Modal-Templates")
              this.loadingIframeShow = true
            }
            this.lgpd_meta = resp.metas.some(function (item) {
              return item.meta_key === "lgpd" && item.meta_value === "true";
            });
            if (resp.metas.find((obj) => obj.meta_key === "palette")) {
              this.palette = resp.metas.find(
                (obj) => obj.meta_key === "palette"
              ).meta_value
                ? JSON.parse(
                    resp.metas.find((obj) => obj.meta_key === "palette")
                      .meta_value
                  )
                : [
                    "#FF2222",
                    "#FF6600",
                    "#FFC107",
                    "#22C34A",
                    "#2196F3",
                    "#002266",
                    "#000000",
                    "#FFFFFF",
                  ];
            }
            const metas = resp.metas;
            const marcaKey = metas.find((obj) => obj.meta_key === "marca");
            const pageDomain = metas.find(
              (obj) => obj.meta_key === "page_domain"
            );
            this.selected_pageDomain = pageDomain;
            if (marcaKey) {
              this.viewMarca = marcaKey.meta_value;
            }
            if (!this.page.length) {
              this.page = resp.json;
            }
            this.isLoadingPageLink = true
            resolve(resp); // Resolva a promessa com a resposta
          })
          .catch((error) => {
            reject(error); // Rejeite a promessa com o erro
          }).finally(()=>{
            if(this.page.length == 0){
              this.newPage()
            }
          });
      });
    },
    changeView(typeViewNew) {
      this.typeView = typeViewNew;
      this.iframePostMessage({ type:"responsiveEditor", typeView: typeViewNew , location: 'index.vue'})
    },
    changeShow(isShow) {
      this.showHidden = isShow;
      this.iframePostMessage( { type:"showHiddenFunction", showAll: isShow, typeView: this.typeView ,location: 'index.vue'})
    },
    addCSS(stringCss, informPreview) {
      const mensagen={type:"createOrUpdateJsCss", css:stringCss}
      if(informPreview){
        return mensagen
      }
      let cssMetaValue = this.getMetaValue("dynamic_css")
      if(cssMetaValue){
        cssMetaValue = stringCss
      }
      this.$refs.menubar.updateMeta("dynamic_css",stringCss)
      this.iframePostMessage(mensagen)
    },
    addJS(stringJs, informPreview) {
      const mensagen = {type:"createOrUpdateJsCss", js:stringJs}
      if(informPreview){
        return mensagen
      }
      let jsMetaValue = this.getMetaValue("dynamic_js")
      if(jsMetaValue){
        jsMetaValue = stringJs
      }
      this.$refs.menubar.updateMeta("dynamic_js",stringJs)
      this.iframePostMessage(mensagen)
    },
    getPages(search = null) {
      if (this.isUser.level == "templater") {
        return;
      }
      let data = {
        page: this.pagination.currentPage,
      };

      if (this.search) {
        data.search = this.search;
      }

      PaginaListService.create(data)
        .then((response) => {
          this.paginas_template = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async newPage(valid) {
      // Limpa a página
      this.page = [];
      this.page = await JSON.parse(JSON.stringify(pageBasec))
      this.synchronizePage()
    },
    validStoryLocalPage() {
      var getHistory = JSON.parse(localStorage.getItem("historyEditorUnsaved"));
      if (
        (getHistory && getHistory.length != 2) ||
        !Array.isArray(JSON.parse(getHistory))
      ) {
        Vue.swal({
          title: "Editor",
          text: `Deseja recuperar alterações não salvas do editor ou iniciar uma nova página?`,
          // type: "danger",
          showCancelButton: true,
          confirmButtonText: "Recuperar",
          cancelButtonText: "Nova página",
          customClass: "sweet-container",
          confirmButtonClass: "button button-black mt-3 mb-3",
          cancelButtonClass: "button button-link-info mr-4 mt-3 mb-3",
          buttonsStyling: false,
          reverseButtons: true,
        }).then((result) => {
          if (result.isConfirmed) { 
            this.page = JSON.parse(getHistory);
          } else {
            sessionStorage.removeItem("historyEditorUnsaved");
            sessionStorage.removeItem("prevHistoryUnsaved");
            // SET LOCAL -->
            localStorage.removeItem("historyEditorUnsaved");
            this.newPage();
            this.openModal("Modal-Templates");
          }
        });
      } else {
        this.newPage();
        this.openModal("Modal-Templates");
      }
    },
    //controle dos templates
    async getTemplates() {
      let data = {
        id: `all`,
      };

      await serviceTemplate.read(data).then((resp) => {
        this.templates = resp;
      });
    },
    async setTemplate(template) {
      var temp = JSON.stringify(template ?? []);
      this.page = await JSON.parse(temp).json;
      this.chargeFontNux(await this.$refs.menubar.getAllFontFamilies(this.page))
      await this.applyAllOverlaps();
      this.synchronizePage()
    },
    async viewTemplate(template) {
      let fonts = []
      const message = {type: "synchronizePage", page: template.json, location:'index.vue',}
      fonts = await this.$refs.menubar.getAllFontFamilies(template.json)
      await this.$refs.isPreview.chargeFontPreview(fonts)
      const menssageClass = await this.applyAllOverlaps(true,template.json)

      this.$nextTick((call) =>{
        this.$refs.isPreview.renderPreview([message,menssageClass])
        this.preview = true
      })
    },
    async getTemplate(template_id) {
      this.page_id = template_id;
      let data = {
        id: `/${this.isUser.tenant_id}/${template_id}`,
      };

      await serviceTemplate.read(data).then((resp) => {
        this.page_data = resp;
        this.page = resp.json;
        this.dados2 = resp;
        this.isLoadingPageLink = true
        if (resp.metas.find((obj) => obj.meta_key === "palette")) {
          this.palette = resp.metas.find((obj) => obj.meta_key === "palette")
            .meta_value
            ? JSON.parse(
                resp.metas.find((obj) => obj.meta_key === "palette").meta_value
              )
            : [
                "#FF2222",
                "#FF6600",
                "#FFC107",
                "#22C34A",
                "#2196F3",
                "#002266",
                "#000000",
                "#FFFFFF",
              ];
        }
      });
    },
    async addTemplate(template) {
      var temp = await JSON.parse(JSON.stringify(template ?? [])).json;
      this.page = await [...this.page, ...temp];
      this.applyAllOverlaps();
      this.synchronizePage()
    },
    async listCategory() {
      await serviceTemplate.read({ id: "category" }).then((resp) => {
        this.categories = resp;
      });
    },
    deleteLine(id, title) {
      Vue.swal({
        title: "Editor",
        text: `Excluir a linha "${title}"?`,
        type: "danger",
        showCancelButton: true,
        confirmButtonText: "Excluir",
        cancelButtonText: "Cancelar",
        customClass: "sweet-container",
        confirmButtonClass: "button button-danger mt-3 mb-3",
        cancelButtonClass: "button button-link-info mr-4 mt-3 mb-3",
        buttonsStyling: false,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          serviceLineDelete
            .destroy(id)
            .then((resp) => {
              this.addNewLine();
            })
            .catch((err) => {
              this.$grToast.toast("Erro ao deletar linha", {
                title: "Editor",
                variant: "danger",
                autoHideDelay: 5000,
                appendToast: true,
              });
            })
            .finally(() => {
              this.loading = false;
              this.$grToast.toast("Linha deletado com sucesso", {
                title: "Editor",
                variant: "info",
                autoHideDelay: 5000,
                appendToast: true,
              });
            });
        }
      });
    },
    getLines() {
      let data = {
        page: this.linePagination,
        per_page: 20,
      };
      serviceLine.search(data).then((response) => {
        if (response[0].data.length < 20) {
          this.moreRows = false;
        }
        const rows = this.rows;
        for (let i = 0; i < response[0].data.length; i++) {
          const element = response[0].data[i];
          rows.push({
            title: element.title,
            value: JSON.parse(element.json),
            id: element.id,
          });
        }
        this.rows = rows;
      });
      this.linePagination++;
    },
    addNewLine() {
      this.moreRows = true;
      this.linePagination = 1;
      this.rows = [];
      this.getLines();
    },
    saveLine(event){
      this.indexLine = event.indexLine
      this.openModal('Modal-rows')
    },
    // controla drad and drop e hover deles
    onDragging(type, event ,customLine) {
      this.draganDropOn = true
      const dataItem = customLine ? [...JSON.parse(event.srcElement.getAttribute('data-item')).value] : JSON.parse(event.srcElement.getAttribute('data-item'));
      this.itemDragging = {type:type, dataTransfer: dataItem, customLine:customLine}
    },
    async setUpBaseline(){
      let item = JSON.parse(JSON.stringify(this.columns[0]));
        // atribui um id aleatório para a nova linha
        item.id = Math.floor((1 + Math.random()) * 0x10000)
          .toString(16)
          .substring(1);
        // seta a largura da coluna única como 100
        item.coluns[0][0].propsColumn.width = 100;
        delete item.coluns[0][0].empty
        let objdrop = {...item.coluns[0][0],...this.itemDragging.dataTransfer}
        // item é uma objeto de sublinha
        item.coluns[0][0] = objdrop
        return [item]
    },
    async dropSpliceComponent(event){
      if (this.itemDragging.dataTransfer.name === "Botão Whatsapp") {
        if (this.isWhatsappIconAlreadyExists()) {
          this.$grToast.toast("Já existe um botão de WhatsApp na página", {
            title: "Editor",
            variant: "danger",
            autoHideDelay: 5000,
            appendToast: true,
          });
          return;
        }
        let item = await this.setUpBaseline()
        // insere a linha nova no page
        let page = JSON.parse(JSON.stringify(this.page));
        page.push(item);
        this.page = await page
        this.synchronizePage()
        return;
      }
      if(this.itemDragging.dataTransfer.name === "Menu lateral"){
        if(this.page[0][0].coluns[0][0].name === "Menu lateral"){
          this.$grToast.toast("Só pode ser inserido um menu vertical.", {
            title: "Editor",
            variant: "danger",
            autoHideDelay: 5000,
            appendToast: true,
          });
          return
        }
        let item = await this.setUpBaseline()
        // insere a linha nova no page
        let page = JSON.parse(JSON.stringify(this.page));
        page.splice(0, 0, item);
        this.page = await page
        this.synchronizePage()
        
      }else if(!event.indexLine){
        let item = await this.setUpBaseline()
        // insere a linha nova no page
        let page = JSON.parse(JSON.stringify(this.page));
        page.push(item);
        this.page = await page
        this.synchronizePage()
        return
      }else{
        const colunaDesejada = this.page[event.indexLine][event.indexSubLine].coluns[event.indexColumn][event.indexComponent]
        if(colunaDesejada.empty){
          delete colunaDesejada.empty
          const objConcatItem = {...colunaDesejada, ...this.itemDragging.dataTransfer}
          this.page[event.indexLine][event.indexSubLine].coluns[event.indexColumn][event.indexComponent] = objConcatItem
          this.seveEditor()
          event.component = "component"
          this.editingRelatedFunction(event)
        }else{
          this.$grToast.toast("Apenas permitido em colunas vazias.", {
            title: "Editor",
            variant: "danger",
            autoHideDelay: 5000,
            appendToast: true,
          });
        }
      }


    },
    isWhatsappIconAlreadyExists() {
      for (let i = 0; i < this.page.length; i++) {
        const row = this.page[i];
        for (let j = 0; j < row.length; j++) {
          const columns = row[j].coluns;
          for (let k = 0; k < columns.length; k++) {
            const element = columns[k];
            for (let l = 0; l < element.length; l++) {
              const item = element[l];
              if (item.name === 'Botão Whatsapp') {
                return true; 
              }
            }
          }
        }
      }
      return false; 
    },
    dropItem(event){
      this.draganDropOn = false
      this.scrollStop()
      const mensage = {
        type:"dropItemPosition", 
        clientX:this.returnPositionX(event.clientX), 
        clientY:event.clientY - 74,
        typedragging: this.itemDragging.type,
        customLine: this.itemDragging.customLine
      }
      this.iframePostMessage(mensage)
    },
    returnPositionX(clientX){
      if(this.typeView == 'tablet'){
        let width = this.$refs.containResponsive.clientWidth
        let discountWidth = (width - 700)/2 
        return clientX - discountWidth
      }else if (this.typeView == 'mobile'){
        let width = this.$refs.containResponsive.clientWidth
        let discountWidth = (width - 400)/2 
        return clientX - discountWidth
      }else{
        return clientX
      }
    },
    hoverDragover(event){
      const mensage = {
        type:"hoverDragover", 
        clientX: this.returnPositionX(event.clientX), 
        clientY:event.clientY - 74,
        typedragging: this.itemDragging.type,
        customLine: this.itemDragging.customLine,
      }
      this.iframePostMessage(mensage)
      this.debounceEndDragover()
    },
    debounceEndDragover: _.debounce(function (event){
      const mensage = {
        type:"dropItemPosition", 
        typedragging:false
      }
      this.iframePostMessage(mensage)
      this.draganDropOn = false
      this.scrollStop()
    },1000),
  },
  data() {
    return {
      disableEvents:false,
      saveOrigin:{},
      scrollTo:0,
      fontsLoaded:[],
      requireFont: true,
      loadingIframeShow:false,
      blockSideBar: true,
      isLoadingPageLink: false,
      itemDragging: null,
      draganDropOn:false,
      pagination_page: {
        totalPages: 1,
        currentPage: 1,
      },
      blockHist: true,
      statusHist: 'none',
      deleteHist: [],
      actionHist: '',
      palette: [
        "#FF2222",
        "#FF6600",
        "#FFC107",
        "#22C34A",
        "#2196F3",
        "#002266",
        "#000000",
        "#FFFFFF",
      ],
      stopCategory: false,
      categories: [],
      canChangeMark: true,
      selected_pageDomain: null,
      itemOnCopy: null,
      viewMarca: null,
      showHidden: false,
      openCategories: [],
      isBefore: false,
      resizing: false,
      moreRows: true,
      linePagination: 1,
      isTemplater: false,
      lgpd_meta: false,
      loading_page: false,
      hoverSideLeft: true,
      page_id: this.$route.params.page_id,
      fontOptions: linstAllFonts.options,
      index_page: 0,
      history: [],
      currentIndex: -1,
      debounceTimeoutId: null,
      preview: false,
      templates: [],
      paginas_template: [],
      pagination: {
        totalPages: 1,
        currentPage: 1,
        perPage: 12,
      },
      indexColumn: 0,
      indexComponent: 0,
      indexLine: 0,
      indexSubLine:0,
      limite_columns: 9,
      typeView: "desktop",
      editaItem: "null",
      historicEditaItem: "null",
      render: true,
      page_data: {},
      dados2: {},
      item_selecionado: "componentes",
      openSideRight: false,
      openSideLeft: false,
      page: [],
      pageTemplate:[],
      rows: [],
      columns: Coluns,
      components: Components,
      isUser: this.$store.getters.user.user,
    };
  },
  async mounted() {
    this.listCategory();
    this.getTemplates();
    this.getPages();
    this.getLines();
    await getGoogleFonts()
      .then((resp) => {
        this.fontOptions = resp;
      })
      .catch((e) => {
        this.fontOptions = ["Nenhuma fonte encontrada"];
      });
    this.loading_page = true;
    //adiciona o ouvinte do nuxt
    window.addEventListener('message', this.handlePostMessage);
    // Adiciona funcionalizade de voltar ou avancar historico com as teclas CTRL Z / CTRL Y
    window.addEventListener("keydown", this.nextAndPrev);
    if (this.page_id) {
      // Valida se é diferente de Templater
      if (
        this.isUser.tenant_id &&
        this.page_id &&
        this.isUser.level !== "templater"
      ) {
        await this.getPage(this.page_id);
        await this.apiGetHistoryPage();
      }
      // Valida se é Templater
      if (
        this.isUser.tenant_id &&
        this.page_id &&
        this.isUser.level === "templater"
      ) {
        await this.getTemplate(this.page_id);
        await this.apiGetHistoryPage();
      }
    } else {
      var getHistory = JSON.parse(
        sessionStorage.getItem("historyEditorUnsaved")
      );
      if (getHistory && getHistory[getHistory.length - 1] != []) {
        this.validStoryLocalPage();
      } else {
        this.openModal("Modal-Templates");
      }
    }
    this.loading_page = false;

    var contract = JSON.parse(sessionStorage.getItem("userContracts"));
    for (let i = 0; i < contract.length; i++) { 
      const element = contract[i];
      if (
          element.produto_id == 118 ||
          element.produto_id == 119 ||
          element.produto_id == 120 ||
          element.produto_id == 121 ||
          element.produto_id == 122 ||
          element.produto_id == 123 ||
          element.produto_id == 124 ||
          element.produto_id == 125 ||
          element.produto_id == 126 ||
          element.produto_id == 127 ||
          element.produto_id == 128 ||
          element.produto_id == 129 
      ) {
        this.canChangeMark = false;
      }
    }
    
  },
  beforeDestroy() {
    // Remove o eventos adicionados no mounted()
    window.removeEventListener('message',this.handlePostMessage)
    window.removeEventListener("keydown", this.nextAndPrev);
  },
};
</script>

<style lang="scss">
body:has(.container-editor) div:has(#crowd-shortcut) {
  display: none !important;
}
.row-loading-iframe{
  // height: 315px;
  display: flex;
  gap: 15px;
}
.loadingIframe{
  width: 100vw;
  display: grid;
  position: fixed;
  gap: 10px;
  height: 100vh;
  padding: 30px;
  background-color: var(--white);
}
.insert-ghost {
  position: absolute;
  z-index: 999;
  top: -100px;
  //
  align-items: center;
  display: flex;
  justify-content: center;
}

.ghost-element {
  position: absolute;
  align-items: center;
  display: flex;
  justify-content: center;
  border: 3px solid black;
  background: white;
  font-weight: 600;
  text-align: center;
  width: 200px;
  padding: 10px;
  // border-radius: 6px;
  color: black;
  margin-top: 10px;
  text-transform: uppercase;
}
p {
  margin-bottom: unset;
  margin-block-end: 0;
}
#editor-pop-up {
  .back {
    transition: width 0.5s;
    height: calc(100vh - 74px);
  }
}
.editor-mobile-pop-up {
  .back {
    width: 400px !important;
  }
}
.editor-tablet-pop-up {
  .back {
    width: 700px !important;
  }
}
.editor-side-pop-up {
  .back {
    width: calc(100vw - 413px) !important;
  }
}
</style>

<style scoped lang="scss">
.no-line {
  width: -webkit-fill-available;
  text-align: center;
  background-color: #f7f7f7;
  height: 70px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #888;
}
.container-editor {
  @keyframes background-animation {
    0% {
      background-color: #68baf177;
    }

    25% {
      background-color: #008cff77;
    }

    50% {
      background-color: #68baf177;
    }

    75% {
      background-color: #008cff77;
    }

    100% {
      background-color: #68baf177;
    }
  }
  .not-preview .the-menu {
    position: relative !important;
  }
  .cke_notification_warning{
    display: none;
  }
  .chevron {
    transition: all 0.3s;
    margin-top: 1px;
    height: 20px;
  }
  .collapsed > .chevron {
    transform: rotate(0deg);
  }
  .not-collapsed > .chevron {
    transform: rotate(90deg);
  }
  .separador-left {
    width: calc(100% + 30px);
    height: 1px;
    margin-left: -15px;
    background-color: #ededf0;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .resize-cursor {
    cursor: ew-resize;
  }

  .more-lines {
    display: flex;
    width: 100%;
    justify-content: center;
    user-select: none;

    span {
      color: var(--gray01);
      text-transform: uppercase;
      font-size: 12px;
      text-align: center;
      cursor: pointer;
    }
  }

  .row-name {
    font-size: 9px;
    color: black;
    text-transform: uppercase;
    position: absolute;
    margin-left: -10px;
    margin-top: 3px;
    background-color: #fff5;
    padding: 0 3px;
    border-radius: 3px;
  }

  .delete-row {
    cursor: pointer;
    font-size: 9px;
    color: black;
    text-transform: uppercase;
    position: absolute;
    right: 10px;
    margin-top: 3px;
    background-color: #fff5;
    padding: 0 3px;
    border-radius: 3px;
  }

  p {
    margin: 0;
    margin-bottom: 0 !important;
  }

  @mixin scrolls($position) {
    position: absolute;
    left: 0;
    width: 100vw;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    #{$position}: -2px;
    img {
      width: 40px;
      @if $position == top {
        transform: rotate(90deg);
        animation: arrow-anim 1s infinite alternate;
      } @else {
        transform: rotate(270deg);
        animation: arrow-anim2 1s infinite alternate;
      }
    }
  }
  .scroll_Top {
    @include scrolls(top);
  }
  .scroll_Bottom {
    @include scrolls(bottom);
  }
  @keyframes arrow-anim2 {
    0% {
      opacity: 1;
      transform: scale(1), rotate(270deg);
    }
    100% {
      opacity: 0.5;
      transform: scale(0.9) rotate(270deg);
    }
  }

  .components-class {
    display: flex;
    gap: 15px;
    justify-content: space-between;
    text-align: center;
    align-items: center;
    transition: all 0.3s;
    cursor: grab;
    p {
      font-size: 12px;
      text-align: center;
      margin-bottom: 0;
      width: 100%;
    }
    &:hover {
      background: var(--white-medium);
    }
  }

  .components-class:last-child {
    margin-bottom: 0 !important;
  }

  .block-coluns {
    background: #e4e4e4;
    height: 30px;
    border-radius: 2px;
    width: -webkit-fill-available;
    border: 1px dashed #d2d2d2;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: #81858e;
    transition: all 0.3s;
  }

  .coluns-components:hover {
    .block-coluns {
      background: #d2d2d2;
      border: 1px dashed #e4e4e4;
    }
  }

  .parent-block-coluns {
    display: flex;
    gap: 4px;
  }

  .bigger-column {
    width: 700px !important;
  }

  .contain {
    width: 100%;
    justify-content: center;
    display: flex;
    background-color: #f3f4f6;
  }

  .page-and-side {
    position: absolute;
    top: 74px;
    width: 100vw;
    min-height: calc(100vh - 74px);
    display: flex;
  }
  .contain-page {
    padding: 0px;
    justify-content: center;
    display: flex;
    min-height: calc(100vh - 74px);
    width: 100%;
    transition: all 0.5s;
    overflow: auto;
    user-select: none;
    -webkit-user-drag: none;
    position: relative;
    label {
      all: unset !important;
      color: blue !important
    }
  }

  // SIDELEFT
  .flex {
    display: flex;
    box-shadow: -2px 2px 8px rgba(0, 0, 0, 0.2);
  }

  .list-itens {
    width: 100%;
    padding: 15px;
    overflow-y: scroll;
    height: calc(100vh - 74px);
  }

  .list-itens::-webkit-scrollbar-thumb {
    background-color: var(--greenn) 50;
    border-radius: 20px;
    transition: all 0.5s;
  }

  .list-itens::-webkit-scrollbar-track {
    background-color: transparent;
  }

  .list-itens::-webkit-scrollbar {
    width: 4px;
    background: transparent;
  }
  .side-left {
    height: calc(100vh - 74px);
    display: flex;
    transition: all 0.5s;
    position: relative;
    z-index: 8;
    width: fit-content;
    margin-left: -413px;
  }
  .side-left-inner {
    height: 100%;
    width: 315px;
    background-color: white !important;
    transition: all 0.5s;
  }

  .container-editor {
    display: flex;
    max-width: 100vw !important;
  }

  // ANIMATION SIDERS

  @keyframes example {
    0% {
      transform: translateY(10px);
      opacity: 0;
    }

    100% {
      transform: translateY(0px);
      opacity: 1;
    }
  }

  .AnimationRight-enter-active {
    animation: example 0.3s;
    animation-timing-function: ease-out;
  }

  .AnimationRight-leave-active {
    animation: example 0.3s reverse;
    animation-timing-function: ease-in;
  }

  .coluns-components {
    padding: 20px;
    cursor: move;
    background: #f7f7f7;
    border-radius: 10px;
  }

  .coluns-components2 {
    border: 1px solid #ddd;
  }

  strong,
  em s,
  b {
    color: inherit;
  }
  strong {
    font-weight: bolder !important;
  }
}
</style>
