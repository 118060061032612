import Rest from '@/services/Rest';

/**
 * @typedef {ConfigService}
 */
export default class ConfigService extends Rest {
  /**
   * @type {String}
   */
  static resource = 'settings'


}