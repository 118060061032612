import ActiveCampaignService from "./ActiveCampaignService";
import ActivitiesService from "./ActivitieService";
import AdicionalService from "./AdicionalService";
import AppointmentService from "./AppointmentService";
import AtividadesService from "./AtividadesService";
import AuthService from "./AuthService";
import BackupService from "./BackupService";
import BannerService from "./BannerService";
import CalendarService from "./CalendarService";
import CampaignService from "./CampaignService";
import ClonarFluxoService from "./ClonarFluxoService";
import ClubService from "./ClubService";
import CompanyService from "./CompanyService";
import ConfigProdutosService from "./ConfigProdutosService";
import ConfigService from "./ConfigService";
import ContractPlan from "./contractPlan";
import ContratoService from "./ContratoService";
import CopyPageService from "./CopyPageService";
import DashboardService from "./DashboardService";
import DashboardTraffic from "./DashboardTraffic";
import DistribuicaoService from "./DistribuicaoService";
import DomainService from "./DomainService";
import EmailDomainService from "./EmailDomainService";
import EmailGraphService from "./EmailGraphService";
import EmailSenderService from "./EmailSenderService";
import EmailService from "./EmailService";
import EmailTestService from "./EmailTestService";
import EmailTotalGraphService from "./EmailTotalGraphService";
import EqualService from "./EqualService";
import ExportLeadsService from "./ExportLeadsService";
import ExportOpportunitiesService from "./ExportOpportunitiesService";
import FlagService from "./FlagService";
import FormDadosService from "./FormDadosService";
import FormService from "./FormService";
import FunilListService from "./FunilListService";
import FunilService from "./FunilService";
import FunilSpeedService from "./FunilSpeedService";
import FunnelService from "./FunnelService";
import FunnelTemplateService from "./FunnelTemplateService";
import GlobalSettingsService from "./GlobalSettingsService";
import GlobalTokenService from "./GlobalTokenService";
import GoogleService from "./GoogleService";

import GreennService from "./GreennService";
import GrupoService from "./GrupoService";
import ImportLeadsService from "./ImportLeadsService";
import ImportService from "./ImportService";
import JornadaService from "./JornadaService";
import LeadAdFormService from "./LeadAdFormService";
import LeadAdLeadService from "./LeadAdLeadService";
import LeadgenForIntegration from "./leadgenForIntegration";
import LeadListaService from "./LeadListaService";
import LeadMessageService from "./LeadMessageService";
import LeadsAutomationService from "./LeadsAutomationService";
import LeadService from "./LeadService";
import LeadValuePipelineService from "./LeadValuePipelineService";
import LineCreateService from "./LineCreateService";
import LineDeleteService from "./LineDeleteService";
import LineService from "./LineService";
import ListarTagService from "./ListarTagService";
import MensageService from "./MensageService";
import MetasService from "./MetasService";
import MidiaListaService from "./MidiaListaService";
import MidiaService from "./MidiaService";
import MoverLeadsService from "./MoverLeadsService";
import NotaService from "./NotaService";
import OpportunitiesService from "./OpportunitiesService";
import OptionService from "./OptionService";
import PageCountService from "./PageCountService";
import PageListAllService from "./PageListAllService";
import PageListService from "./PageListService";
import PageService from "./PageService";
import PannelService from "./PannelService";
import PermissionService from "./PermissionService";
import PipelineService from "./PipelineService";
import PipelineUserService from "./PipelineUserService";
import PixelService from "./PixelService";
import PlanService from "./PlanService";
import ProductIntegrationService from "./ProductIntegrationService";
import ProductQuestionsService from "./ProductQuestion";
import RankingService from "./RankingService";
import RecursesService from "./RecursesService";
import RedirectService from "./RedirectService";
import RevokeService from "./RevokeService";

import SellerAutomationService from "./SellerAutomation";
import SellerListService from "./SellerListService";
import SellerService from "./SellerService";
import SenhaService from "./SenhaService";
import SettingsService from "./SettingsService";
import TagAutomationService from "./TagAutomationService";
import TagService from "./TagService";
import TemplateListService from "./TemplateListService";
import TemplateService from "./TemplateService";
import TokenService from "./TokenService";
import TrafficService from "./TrafficService";
import TrayCorpService from "./TrayCorpService";
import TrialService from "./TrialService";
import UpdatePanelService from "./UpdatePanel";
import UserLoggedService from "./UserLoggedService";
import UserResourcesService from "./UserResourcesService";
import UsersService from "./UsersService";
import UsuariosEditService from "./UsuariosEditService";
import UsuariosService from "./UsuariosService";
import VerifyEmailService from "./VerifyEmailService";
import VerifyIntegrationService from "./VerifyIntegrationService";
import WebmailService from "./WebmailService";
import WhatsappService from "./WhatsappService";
import WhatsappServiceNew from "./WhatsappServiceNew";
import WizardFunilService from "./WizardFunilService";
import WizzardService from "./WizzardService";

export const serviceActiveCampaign = ActiveCampaignService.build();
export const serviceActivities = ActivitiesService.build();
export const serviceAdicional = AdicionalService.build();
export const serviceAppointment = AppointmentService.build();
export const serviceAtividades = AtividadesService.build();
export const serviceAuth = AuthService.build();
export const serviceBackup = BackupService.build();
export const serviceBanner = BannerService.build();
export const serviceCalendar = CalendarService.build();
export const serviceCampaign = CampaignService.build();
export const serviceClonarFluxo = ClonarFluxoService.build();
export const serviceClub = ClubService.build();
export const serviceCompany = CompanyService.build();
export const serviceConfigProdutos = ConfigProdutosService.build();
export const serviceConfig = ConfigService.build();
export const serviceContractPlan = ContractPlan.build();
export const serviceContrato = ContratoService.build();
export const serviceCopyPage = CopyPageService.build();
export const serviceDashboard = DashboardService.build();
export const serviceDashboardTraffic = DashboardTraffic.build();
export const serviceDistribuicao = DistribuicaoService.build();
export const serviceDomain = DomainService.build();
export const serviceEmailDomain = EmailDomainService.build();
export const serviceEmailGraph = EmailGraphService.build();
export const serviceEmailSender = EmailSenderService.build();
export const serviceEmail = EmailService.build();
export const serviceEmailTest = EmailTestService.build();
export const serviceEmailTotalGraph = EmailTotalGraphService.build();
export const serviceEqual = EqualService.build();
export const serviceExportLeads = ExportLeadsService.build();
export const serviceExportOportunities = ExportOpportunitiesService.build();
export const serviceFlags = FlagService.build();
export const serviceFormDados = FormDadosService.build();
export const serviceForm = FormService.build();
export const serviceFunilList = FunilListService.build();
export const serviceFunil = FunilService.build();
export const serviceFunilSpeeds = FunilSpeedService.build();
export const serviceFunnel = FunnelService.build();
export const serviceFunnelTemplates = FunnelTemplateService.build();
export const serviceGlobalSettings = GlobalSettingsService.build();
export const serviceGlobalTokens = GlobalTokenService.build();
export const serviceGoogle = GoogleService.build();

export const serviceGreen = GreennService.build();
export const serviceGrupo = GrupoService.build();
export const serviceImportLeads = ImportLeadsService.build();
export const serviceImport = ImportService.build();
export const serviceJornada = JornadaService.build();
export const serviceLeadAddForm = LeadAdFormService.build();
export const serviceLeadAdd = LeadAdLeadService.build();
export const serviceLeadGenFortIntegration = LeadgenForIntegration.build();
export const serviceLeadList = LeadListaService.build();
export const serviceLeadMessage = LeadMessageService.build();
export const serviceLeadsAutomation = LeadsAutomationService.build();
export const serviceLead = LeadService.build();
export const serviceLeadValuePipeline = LeadValuePipelineService.build();
export const serviceLineCreate = LineCreateService.build();
export const serviceLineDelete = LineDeleteService.build();
export const serviceLine = LineService.build();
export const serviceListarTag = ListarTagService.build();
export const serviceMensage = MensageService.build();
export const serviceMetas = MetasService.build();
export const serviceMidiaLista = MidiaListaService.build();
export const serviceMidia = MidiaService.build();
export const serviceMoverLeads = MoverLeadsService.build();
export const serviceNota = NotaService.build();
export const serviceOpportunities = OpportunitiesService.build();
export const serviceOption = OptionService.build();
export const servicePageCount = PageCountService.build();
export const servicePageListAll = PageListAllService.build();
export const servicePageList = PageListService.build();
export const servicePage = PageService.build();
export const servicePanel = PannelService.build();
export const servicePermission = PermissionService.build();
export const servicePipeline = PipelineService.build();
export const servicePipelineUser = PipelineUserService.build();
export const servicePixel = PixelService.build();
export const servicePlan = PlanService.build();
export const serviceProductIntegration = ProductIntegrationService.build();
export const serviceProductQuestions = ProductQuestionsService.build();
export const serviceRanking = RankingService.build();
export const serviceRecuress = RecursesService.build();
export const serviceRedirect = RedirectService.build();
export const serviceRevoke = RevokeService.build();

export const serviceSellerAutomation = SellerAutomationService.build();
export const serviceSellerList = SellerListService.build();
export const serviceSeller = SellerService.build();
export const serviceSenha = SenhaService.build();
export const serviceSettings = SettingsService.build();
export const serviceTagAutomation = TagAutomationService.build();
export const serviceTag = TagService.build();
export const serviceTemplateList = TemplateListService.build();
export const serviceTemplate = TemplateService.build();
export const serviceToken = TokenService.build();
export const serviceTraffic = TrafficService.build();
export const serviceTrayCorp = TrayCorpService.build();
export const serviceTrial = TrialService.build();
export const serviceUpdatePanel = UpdatePanelService.build();
export const serviceUserLogged = UserLoggedService.build();
export const serviceUserResources = UserResourcesService.build();
export const serviceUsers = UsersService.build();
export const serviceUsuariosId = UsuariosEditService.build();
export const serviceUsuarios = UsuariosService.build();
export const serviceVerifyEmail = VerifyEmailService.build();
export const serviceVerifyIntegration = VerifyIntegrationService.build();
export const serviceWebmail = WebmailService.build();
export const serviceWhatsapp = WhatsappService.build();
export const serviceWhatsappNew = WhatsappServiceNew.build();
export const serviceWizardFunil = WizardFunilService.build();
export const serviceWizzard = WizzardService.build();