import Rest from '@/services/Rest';

/**
 * @typedef {OptionService}
 */
export default class OptionService extends Rest {
  /**
   * @type {String}
   */
  static resource = 'option'


}
