import Rest from '@/services/Rest';

/**
 * @typedef {UsuariosService}
 */
export default class UsuariosService extends Rest {
    /**
     * @type {String}
     */
    static resource = 'user/distinct-user'


}