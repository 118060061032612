import Rest from '@/services/Rest';

/**
 * @typedef {ActiveCampaignService}
 */
export default class ActiveCampaignService extends Rest {
    /**
     * @type {String}
     */
    static resource = 'ActiveCampaign/auth'


}