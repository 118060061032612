import Rest from '@/services/Rest';

/**
 * @typedef {FunilService}
 */
export default class FunilService extends Rest {
    /**
     * @type {String}
     */
    static resource = 'funil'
}