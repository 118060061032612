import Rest from "@/services/Rest";

/**
 * @typedef {VerifyEmailService}
 */
export default class VerifyEmailService extends Rest {
  /**
   * @type {String}
   */
  static resource = "verify";
}
