<template>
  <BaseModal
    name="optin-modal"
    id="optin-modal"
    idModal="optin-modal"
    size="xl"
    title="Opt-in"
    @shown="openModal"
  >
    <b-row>
      <b-col cols="12">
        <b-form data-vv-name="nome">
          <b-row>
            <b-col>
              <b-form-group class="mail-group">
                <EmailEditor
                  ref="emailEditor"
                  v-on:load="editorLoaded"
                  v-on:designUpdated="designUpdated"
                  :designTags="designTags"
                  :options="options_unlayer"
                  locale="pt-BR"
                  :translations="traducaoUnlayer"
                  :projectId="168946"
                  :templateId="369449"
                  apiKey="xTBzcM8XoHvPMhcWa1H83iT6j3qqTuddUfsLGFhiLO5SI9ET2XSqbHIqgmHUh1cI"
                />
                <!-- <b-form-textarea
                  id="textarea"
                  v-model="mensage"
                  placeholder="Assunto"
                  no-resize
                  style="height: 200px !important"
                ></b-form-textarea> -->
                <label>Assunto do e-mail</label>
                <input
                  type="text"
                  class="w-100"
                  name="subject"
                  id="subject"
                  v-model="subject"
                />
                <label>Remetente</label>
                <!-- <multiselect
                  v-model="selectedRemetente"
                  class="multiselect"
                  label="email"
                  track-by="id"
                  placeholder="Selecione um remetente"
                  selectLabel=""
                  deselectLabel=""
                  selectedLabel=""
                  open-direction="bottom"
                  :options="options_email"
                  :multiple="false"
                  :searchable="true"
                  :internal-search="false"
                  :clear-on-select="false"
                  :close-on-select="true"
                  :show-no-results="true"
                  :hide-selected="true"
                >
                  <span slot="noOptions">Pesquise pela emetente</span>
                  <span slot="noResult"
                    >Oops! Nenhum remetente encontrado.</span
                  >
                </multiselect> -->

                <BaseSelect
                  :loading="loadingRemetente"
                  @search="debounceRemetente"
                  :selectModel="selectedRemetente"
                  placeholder="Selecione o Remetente"
                  track-by="id"
                  trackname="email"
                  :array="options_email"
                  class="select-filter-options"
                  selectLabel=""
                  deselectLabel=""
                  noResult="Oops! Nenhum remetente encontrado."
                  @callback="getRemetente($event)"
                  @change="selectedRemetente = $event"
                  :watch="true"
                >
                </BaseSelect>
                <label>Página após Inscrição do Double Optin</label>
                <input
                  class="w-100"
                  type="text"
                  name="pag"
                  id="pag"
                  placeholder="http://seudominio.com.br/sua-pagina-de-agradecimento"
                  v-model="pagina"
                />
                <label>Texto do link de inscrição</label>
                <input
                  class="w-100"
                  type="text"
                  name="pag"
                  id="pag"
                  placeholder="Clique aqui para confirmar inscrição"
                  v-model="inscricao"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </b-col>
    </b-row>

    <template v-slot:footer="{ cancel }">
      <BaseButton variant="link-info" class="mr-4" @click="cancel">
        Cancelar
      </BaseButton>
      <BaseButton variant="black" @click="onSubmit"> Salvar </BaseButton>
    </template>
  </BaseModal>
</template>
<script>
import Multiselect from "vue-multiselect";
import SettingsService from "@/services/resources/SettingsService";
const serviceSettings = SettingsService.build();
import { EmailEditor } from "../../../node_modules/vue-email-editor";
import traducao_unlayer from "@/services/libs/traducao_unlayer.js";
import sample from "../../assets/unlayer/sample.json";
import _ from "lodash";

export default {
  components: {
    Multiselect,
    EmailEditor,
  },
  data() {
    return {
      loadingRemetente: false,
      pageRemetente: "",
      searchRemetente: "",
      stopRemetente: false,
      data_final: {},
      stop: false,
      mensage: "",
      options_email: [],
      selectedRemetente: "",
      already_open: false,
      pagina: "",
      inscricao: "",
      subject: "",
      html: "",
      raw: sample,
      traducaoUnlayer: traducao_unlayer,
      options_unlayer: {
        mergeTags: [
          { name: "Nome do cliente", value: "{{nome}}" },
          { name: "Primeiro nome do cliente", value: "{{primeiro_nome}}" },
          { name: "E-mail do cliente", value: "{{lead}}" },
        ],
        features: {
          textEditor: {
            cleanPaste: false,
          },
        },
      },
      designTags: {
        business_name: this.$store.getters["getNameSystem"],
        current_user_name: this.$store.getters["getNameSystem"],
        link_botao: "https://google.com.br",
      },
    };
  },
  props: ["dados"],
  methods: {
    openModal() {
      if (this.data_final && this.data_final.json && this.data_final.html) {
        this.raw = JSON.parse(this.data_final.json);
        this.html = this.data_final.html;
      }
      // this.selectedRemetente = "";
      // this.getRemetente();
      // this.raw = sample;
    },
    onSubmit() {
      if (!this.subject.length) {
        this.$grToast.toast("Insira um assunto para o e-mail", {
          title: "Opt-in",
          variant: "danger",
          autoHideDelay: 300,
          appendToast: true,
        });
        return;
      }
      if (!this.selectedRemetente) {
        this.$grToast.toast("Selecione um remetente", {
          title: "Opt-in",
          variant: "danger",
          autoHideDelay: 300,
          appendToast: true,
        });
        return;
      }
      if (!this.pagina.length) {
        this.$grToast.toast("Insira a URL de uma página", {
          title: "Opt-in",
          variant: "danger",
          autoHideDelay: 300,
          appendToast: true,
        });
        return;
      }
      if (!this.inscricao.length) {
        this.$grToast.toast("Insira texto de inscrição", {
          title: "Opt-in",
          variant: "danger",
          autoHideDelay: 300,
          appendToast: true,
        });
        return;
      }

      let data = {
        json: JSON.stringify(this.raw),
        html: this.html,
        sender: this.selectedRemetente.id,
        subject: this.subject,
        register_page: this.pagina,
        register_text: this.inscricao,
      };

      this.data_final = data;

      this.$emit("setOptin", data);
      this.$bvModal.hide("optin-modal");
    },
    getMeta(metas, chave) {
      var result = metas.find((x) => x.meta_key == chave).meta_value;
      if (!result) {
        result = null;
      }
      return result;
    },
    editorLoaded() {
      let email = this.raw;
      this.$refs.emailEditor.editor.setLocale('pt-BR');
      this.$refs.emailEditor.editor.loadDesign(this.raw);
      // 1 is the templateId
    },
    designUpdated() {
      this.$refs.emailEditor.editor.setLocale('pt-BR');
      this.$refs.emailEditor.editor.exportHtml((data) => {
        // this.AllData.html = data.html;
        // this.htmlmail = data.html;
        this.raw = data.design;
        this.html = data.html;
        // console.log(this.raw);
        // this.template_selected = data.design;
      });
    },
    debounceRemetente: _.debounce(function (query) {
      this.pageRemetente = 1;
      this.searchRemetente = query;
      // this.options_email = [];
      this.stopRemetente = false;
      this.getRemetente(this.pageRemetente, query);
    }, 500),
    // getRemetente(page = 1, query = "") {
    //   if (this.stopRemetente || this.loadingRemetente) {
    //     return;
    //   }

    //   this.loadingRemetente = true;

    //   var data = {
    //     id: `sender?page=${page}&order_by=email&order=ASC&search=${query}`,
    //   };

    //   serviceSettings
    //     .read(data)
    //     .then((resp) => {
    //       if (resp.data.length < resp.per_page) {
    //         this.stopRemetente = true;
    //       }

    //       this.options_email = this.options_email.concat(resp.data);
    //     })
    //     .catch((err) => {})
    //     .finally(() => {
    //       this.loadingRemetente = false;
    //     });
    // },

    getRemetente(page = 1, query = "") {
      if (this.stopRemetente || this.loadingRemetente) {
        return;
      }

      var data = {
        id: `sender?page=${page}&order_by=email&order=ASC&search=${query}`,
      };

      serviceSettings
        .read(data)
        .then((resp) => {
          if (resp.data.length < resp.per_page) {
            this.stopRemetente = true;
          }

          this.options_email = this.options_email.concat(resp.data);
          this.options_email = this.options_email.filter(
            (item, index, opt) =>
              index === opt.findIndex((t) => t.id === item.id)
          );

          if (!this.dados) {
            return;
          }
          this.selectedRemetente = this.options_email.find(
            (x) => x.id == this.getMeta(this.dados.metas, "sender")
          );

          if (!this.already_open) {
            this.subject = this.getMeta(this.dados.metas, "subject");
            this.pagina = this.getMeta(this.dados.metas, "register_page");
            this.inscricao = this.getMeta(this.dados.metas, "register_text");
            this.already_open = true;
          }

          if (!JSON.parse(this.getMeta(this.dados.metas, "json"))) {
            // this.$refs.emailEditor.editor.loadTemplate(369449);
            return;
          }
          this.raw = JSON.parse(this.getMeta(this.dados.metas, "json"));
          this.$refs.emailEditor.editor.loadDesign(this.raw);
        })
        .catch((err) => {
          // console.log(err);
        })
        .finally(() => {
          this.loadingRemetente = false;
        });
    },
  },
};
</script>

<style scoped>
.mail-group label {
  margin: 15px 0;
}
</style>
