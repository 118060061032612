import Rest from '@/services/Rest';

/**
 * @typedef {WizardFunilService}
 */
export default class WizardFunilService extends Rest {
    /**
     * @type {String}
     */
    static resource = 'intro/wizardFunil'


}