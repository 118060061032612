import Rest from '@/services/Rest';

/**
 * @typedef {FunilSpeedService}
 */
export default class FunilSpeedService extends Rest {
    /**
     * @type {String}
     */
    static resource = 'wizzard/funil/simples/'


}