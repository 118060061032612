<template>
  <form class="container" v-if="!loading">
    <div class="text">
      <p>Revisão e Atribuição de Dados</p>
      <span>Verifique os dados importados, atribua o campo correto a cada informação e desative os dados que não deseja incluir em sua base de leads.</span>
    </div>
    <div class="form-validate">
      <div class="field-name field">
        <p class="center">Validação</p>
        <p>Dados do campo</p>
        <p>Atribuição de campo</p>
      </div>
      <div 
        class="field"
        v-for="(item, index) in rawSample"
        :key="index"
      >
        <div class="validate" :class="validate[index] == true ? 'validateTrue' : ''">
          <div>
            <svg width="12" height="10" viewBox="0 0 11 9" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1.35999 5.66547L3.03917 7.82442C3.09541 7.89738 3.16732 7.95678 3.2496 7.99823C3.33187 8.03968 3.4224 8.06212 3.51451 8.06389C3.60662 8.06566 3.69795 8.04672 3.78175 8.00847C3.86556 7.97021 3.9397 7.91362 3.9987 7.84287L9.3561 1.35986" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
        </div>
        <div class="data">
          <p> {{ item }} </p>
        </div>
        <div class="attribute">
          <multiselect
          v-model="selected[index]"
          id="attribute"
          label="text"
          track-by="value"
          placeholder=""
          selectLabel=""
          deselectLabel=""
          selectedLabel=""
          open-direction="bottom"
          :options="opcoes"
          :multiple="false"
          :searchable="false"
          :internal-search="false"
          :clear-on-select="false"
          :close-on-select="true"
          :show-no-results="true"
          :hide-selected="true"             
          @change="
              selected[index] && selected[index].value === 'additional'
                ? (additional[index] = '')
                : (additional[index] = null)
            "
        ></multiselect>
            <input
              v-if="selected[index] && selected[index].value == 'additional'"
              type="text"
              name="additional"
              class="b-input"
              placeholder="Nome do campo"
              v-model="additional[index]"
            />
        </div>
      </div>
    </div>
    <div class="container-buttons">
      <BaseButton
        @click="deleteImport"
        variant="link-info"
        >Cancelar</BaseButton
      >
      <BaseButton
        @click="nextStep"
        variant="primary"
        :disabled="isDuplicate"
        >Avançar</BaseButton
      >
    </div>
  </form>
  <div v-else class="d-flex justify-content-center" style="display: flex; align-items: center; justify-content: center; min-height: 500px" >
    <b-spinner label="Loading..."></b-spinner>
  </div>
</template>
<script>
import BaseButton from "@/components/BaseButton";
import Multiselect, { multiselectMixin } from "vue-multiselect";
import Vue from "vue";

import ImportService from "@/services/resources/ImportService";
const serviceImport = ImportService.build();

export default {
  props: ['importacao', 'importData'],
  components: {
    BaseButton,
    Multiselect,
  },  
  data(){
    return{
      rawSample: null,
      loading: false, 
      selected: [],
      additional: [],
      sample: [],
      selected: [],
      isDuplicate: false,
      validate: [],
      selects: [],
      opcoes: [
        { value: "name", text: "Nome" },
        { value: "email", text: "E-mail" },
        { value: "phone", text: "Telefone" },
        { value: "additional", text: "Outros" },
      ],
    }
  },
  methods: {
    openModal(){
      if(this.importacao != null){
        this.fetchRawSample(this.importacao.id);
      } else {
        this.fetchRawSample(this.importData.id)
      }
    },
    setAdicional(content, index) {
      if (content == "name" || content == "email" || content == "phone") {
        return;
      }
      this.selected[index] = "additional";
    },
    nextStep() {
      const arrayValue = []
      for (let x = 0; x < this.selected.length; x++) {
        const element = this.additional[x];
        if (this.selected[x].value) {
            const value = this.selected[x].value;
            arrayValue.push(value);
        }

        if (typeof element === 'string' && element.trim() !== '') {
          for (let option of this.opcoes) {
                if (option.text.toLocaleLowerCase() === element.toLocaleLowerCase()) {
                    arrayValue.push(option.value);
                    break;
                }
            }
        }
        if (
          this.selected[x].value == 'additional' && (!element || element == '')
        ) {
          this.$grToast.toast("Por favor, preencha o campo adicional", {
            title: "Importação de leads",
            variant: "danger",
            autoHideDelay: 5000,
            appendToast: true,
          });
          return;
        }
      }
      if(!arrayValue.includes('phone') && !arrayValue.includes('email')) {
          this.$grToast.toast("Selecione o campo de E-mail ou Tefefone", {
            title: "Importação de leads",
            variant: "danger",
            autoHideDelay: 5000,
            appendToast: true,
          });
          return;
        }
      this.selects = []
      for(let a = 0; a < this.selected.length; a++){
        if (this.selected[a].value != 'additional') {
          this.selects.push(this.selected[a].value)
        } else {
          this.selects.push(this.additional[a])
        }
      }
      const hasDuplicates = (arr) => new Set(arr).size !== arr.length;
      if (hasDuplicates(this.selects)) {
        this.$grToast.toast("Existem campos duplicados na seleção", {
          title: "Importação de leads",
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });
        return;
      }
      var data = {};
      
      data.headers = this.selects
      if (this.importacao) {
        data.id = `/${this.importacao.id}`;
      } else {
        data.id = `/${this.importData.id}`;
      }
      serviceImport.createId(data)
        .then(async () => {
          if (!this.importacao) {
            await this.fetchSample(this.importData.id, true);
            return;
          }
          await this.fetchSample(this.importacao.id, true);
        })
    },
    typeText(text){
      if(text == 'phone'){
        return 'Telefone'
      }
      if(text == 'name'){
        return 'Nome'
      }
      if(text == 'email'){
        return 'E-mail'
      }
    },
    async fetchSample(id, teste = false) {
      this.loading = true;
      serviceImport
        .read(`/${id}/sample`)
        .then(async (resp) => {
          this.loading = false;
          this.sample = await resp;
        })
        .catch((error) => {
          this.loading = false;
        })
        .finally(() => {
          if(teste == true){
            const data = {
            sample: this.sample,
            selects: this.selects
          }
          this.$emit('submit', data)
          }
        })
    },
    async fetchRawSample(id) {
      this.loading = true;
      serviceImport.read(`/${id}/rawSample`).then(async (resp) => {
        this.rawSample = resp[1];
        // this.selected = await resp[0];
        for(let a = 0; a < resp[0].length; a++){
          const type = resp[0][a]
          if(type == 'email' || type == 'phone' || type == 'name'){
            this.selected.push({value: type, text: this.typeText(type)}) 
          } else {
            this.selected.push({value: 'additional', text: 'Outro'})
          }
        }
        for(let b = resp[0].length; b < resp[1].length; b++){
          this.selected.push({value: 'additional', text: 'Outro'})
        }
        for (let i = 0; i < this.selected.length; i++) {
          this.additional[i] = null;
        }
        if (!this.importacao) {
          this.loading = false;
          await this.fetchSample(this.importData.id);
          return;
        }
        this.loading = false;
        await this.fetchSample(this.importacao.id);
      });
    },
    deleteImport() {
      Vue.swal({
        title: "Deletar importação",
        text: `Deseja deletar a importação?`,
        type: "danger",
        showCancelButton: true,
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
        customClass: "sweet-container",
        confirmButtonClass: "button button-danger mt-3 mb-3",
        cancelButtonClass: "button button-link-info mr-4 mt-3 mb-3",
        buttonsStyling: false,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          var data = {};
          if (this.importacao) {
            data.id = `/${this.importacao.id}`;
          } else {
            data.id = `/${this.importData.id}`;
          }
          this.loading = true;
          serviceImport
            .destroy(data)
            .finally(() => {
              this.loading = false;
              this.$emit('delete')
              this.$grToast.toast("Importação deletada com sucesso", {
                title: "Importação de leads",
                variant: "info",
                autoHideDelay: 5000,
                appendToast: true,
              });
            })
            .catch(() => {
              this.$grToast.toast("Erro ao deletar importação", {
                title: "Importação de leads",
                variant: "danger",
                autoHideDelay: 5000,
                appendToast: true,
              });
            })
            .finally(() => {
              this.loading = false;
              this.$emit('cancel')
            });
        }
      });
    },
    verifyValidate(){
      const array = []      
      this.selected.forEach((item, index) => {
        array.push(item.value)
        if(array[index] === 'additional'){
          array[index] = this.additional[index]
        }
      })
      this.selected.forEach((_, index) => {
        if(array[index] != null && array[index].trim() != '' ){
          if(array.filter((val, i) => val === array[index] && i !== index).length === 0) {
            this.validate[index] = true
          } else {
            this.validate[index] = false 
          }
        } else {
          this.validate[index] = false
        }
      })
      if(this.validate.some(val => val === false)) {
        this.isDuplicate = true
      } else {
        this.isDuplicate = false
      }
    },
    verifyDuplicate(){
      const array = [];
      const arrayAdditional = []
      this.selected.map((item, index) => {
        if (item.value != "additional") {
          array.push(item.value);
        } else if (item.value == 'additional' && this.additional[index] != null) {
          arrayAdditional.push(this.additional[index]);
        }
      });
      const isDuplicate = array.some((item, idx) => array.indexOf(item) != idx);
      const isDuplicateAdditional = arrayAdditional.some((item, idx) => arrayAdditional.indexOf(item) != idx);
      if (isDuplicate === true || isDuplicateAdditional === true) {
        this.$grToast.toast("Campos duplicados, verifique e tente novamente", {
          title: "Mensagem manual",
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });
        this.loading = false;
        this.isDuplicate = true;
        return;
      }
      this.isDuplicate = false;
    },
  },
  watch: {
    selected() {
      this.verifyDuplicate()
      this.verifyValidate()
    },
    additional(){
      this.verifyDuplicate()
      this.verifyValidate()
    },
  },
  mounted(){
    this.openModal()
  }
}

</script>
<style lang="scss" scoped>
.container{
  display: grid;
  gap: 35px;
  .text {
    display: grid;
    gap: 10px;
    p {
      font-weight: 600;
      font-size: 18px;
      color: var(--gray01);
    }
    span {
      font-weight: 400;
      font-size: 15px;
      color: #81858E;
    }
  }
  .container-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 15px;
  }
  .form-validate{
    padding: 0 30px;
    .field{
      display: grid;
      grid-template-columns: 1fr 3fr 3fr;
      gap: 56px;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      margin-top: 30px;
      .validate{
        display: flex;
        justify-content: center;
        div{
          min-width: 30px;
          min-height: 30px;
          max-width: 30px;
          max-height: 30px;
          border-radius: 100%;
          background-color: var(--white-light);
          display: flex;
          justify-content: center;
          align-items: center;
          align-content: center;
          path {
            stroke: #141414;
          }
        }
      }
      .validateTrue{
        div{
          background-color: #00B72F;
          path{
            stroke: white
          }
        }
      }
      .data{
        p{
          color: var(--gray01);
          font-size: 14px;
          font-weight: 500;
        }
      }
      .attribute{
        position: relative;
        .b-select{
          margin-bottom: 0 !important;
        }
        .b-input{
          position: absolute;
          top: 0;
          left: 0px;
          width: 200px;
        }
      }
    }
    .field-name{
      border-bottom: 40px;
      .center{
        text-align: center
      }
      p{
        font-size: 15px;
        font-weight: 600;
        color: var(--gray01);
      }
    }
  }
}
</style>