import Rest from '@/services/Rest';

/**
 * @typedef {FunilListService}
 */
export default class FunilListService extends Rest {
    /**
     * @type {String}
     */
    static resource = 'funil/lista'


}