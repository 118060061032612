import Rest from '@/services/Rest';

/**
 * @typedef {WizardService}
 */
export default class WizardService extends Rest {
    /**
     * @type {String}
     */
    static resource = 'wizzard'


}