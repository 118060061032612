import FlagService from "@/services/resources/FlagService";

const serviceFlag = FlagService.build();

let isFetchingFlags = false;

export default {
  async getAllFlags({ commit, getters }) {
    if (isFetchingFlags || getters.flags.length > 0) return getters.flags;

    try {
      isFetchingFlags = true; 

      const allFlags = await serviceFlag.read("feature-flags");
      commit("setAllflags", allFlags);

      return allFlags;
    }  catch (error) {
      console.error(error);
    }
  },

  async getUniqueFlag({ commit, dispatch, getters }, findFlag) {
    const flags = getters.flags;

    if (flags.length <= 0 || !flags.includes(findFlag)) {
      await dispatch("getAllFlags");
      return getters.flags.includes(findFlag)
    }
    return flags.includes(findFlag);
  },
};
