import LeadService from "@/services/resources/LeadService";
const serviceLeads = LeadService.build();

export default {
  async fetchOrigins({ commit, getters }) {
    if (getters["getOrigins"].length > 0) return;
    const { messages } = await serviceLeads.read("/origins");

    commit("setOrigins", messages);
  },
};
