import Rest from '@/services/Rest';

/**
 * @typedef {TrayCorpService}
 */
export default class TrayCorpService extends Rest {
  /**
   * @type {String}
   */
  static resource = 'tray'
}
