<template>
  <GrModal
    ref="add-lead"
    name="add-lead"
    id="add-lead"
    idModal="add-lead"
    size="md"
    title="Cadastrar lead"
    @shown="openModal"
    @hidden="limparCampos()"
  >
    <b-tabs
      nav-class="TabHeaderAddLead"
      active-nav-item-class="TabHeader--active"
    >
      <b-tab
        :title="`Informações básicas`"
        active
        @click="typeList = 'Próximos'"
      >
        <div style="padding-bottom: 10px">
          <b-form-group label="Nome*">
            <b-form-input
              v-model="nome"
              name="nome"
              v-validate="{ required: true, min: 6, max: 40 }"
            ></b-form-input>
            <span id="errorName" v-if="titleSpace"> Espaços inválidos </span>
          </b-form-group>
          <label for="E-mail" class="mt-3">E-mail</label>
          <input
            class="w-100"
            v-model="email"
            name="email"
            style="margin-bottom: 0 !important"
            v-validate="{ required: true, email: true }"
          />
          <label for="E-mail" class="mt-3">Telefone*</label>
          <VuePhoneNumberInput
            :translations="translations"
            :default-country-code="defaultCountry"
            :fetch-country="returnFlag(telefone)"
            :color="'#4ea934'"
            :maxLength="15"
            id="formattedNumber"
            @update="getNumber"
            v-model="telefone"
            :only-countries="countriesISO"
          />
        </div>
      </b-tab>

      <b-tab
        :title="`Endereço`"
        @click="typeList = 'Endereço'"
        v-if="leadsUnification"
      >
        <div class="grid-address-2">
          <div>
            <label>CEP</label>
            <input @input="debounceZipCode" v-mask="'#####-###'" type="text" v-model="zipCode" placeholder="CEP">
          </div>

          <div>
            <label>Estado</label>
            <input @input="validateFields($event, 'state')" type="text" v-model="state" placeholder="Estado">
          </div>
        </div>

        <div class="grid-address-3">
          <div>
            <label>Cidade</label>
            <input @input="validateFields($event, 'city')" type="text" v-model="city" placeholder="Cidade">
          </div>
          <div>
            <label>Bairro</label>
            <input @input="validateFields($event, 'neighborhood')" ref="neighborhood" type="text" v-model="neighborhood" placeholder="Bairro">
          </div>
          <div>
            <label>Número/Bloco</label>
            <input type="text" v-model="number" placeholder="Número">
          </div>
        </div>

        <div>
          <label>Logradouro</label>
          <input @input="validateFields($event, 'street')" type="text" v-model="street" placeholder="Digite o logradouro">
        </div>
      </b-tab>
      <b-tab :title="`Mais opções`" @click="typeList = 'Pendentes'">
        <label for="E-mail">Vendedor</label>
        <router-link
          :to="{ name: 'Usuarios' }"
          v-if="!sellers.length && stops.includes('seller') && verificUserLevel"
          class="add-link"
          >Deseja criar um vendedor?</router-link
        >
        <multiselect
          v-model="vendedor"
          :options="sellers"
          class="w-100"
          label="email"
          track-by="id"
          placeholder="Pesquise pelo e-mail"
          selectLabel=""
          deselectLabel=""
          selectedLabel=""
          :allow-empty="true"
          :searchable="true"
        >
          <template slot="option" slot-scope="props">
            <div class="option__desc select-seller-option">
              <div :style="hasPictureSeller(props.option) ? `background-image: url(${hasPictureSeller(props.option)}); color: transparent` : ''">{{ returnInitialSeller(props.option) }}</div>
              <div>
                <p class="option__title">{{ props.option.first_name }} {{ props.option.last_name }}</p>
                <p class="option__subtitle">{{ props.option.email }}</p>
              </div>
            </div>
          </template>
        </multiselect>

        <label class="mt-3">Formulário</label>
        <router-link
          :to="{ name: 'CampanhasLista' }"
          v-if="!forms.length && stop && verificUserLevel"
          class="add-link"
          >Deseja criar um formulário?</router-link
        >
        <BaseSelect
          :selectModel="formulario"
          label=""
          track-by="id"
          :array="forms"
          :multiple="false"
          :searchable="true"
          :taggable="true"
          class=""
          selectLabel=""
          deselectLabel=""
          selectedLabel=""
          @search="debounceForms($event)"
          @callback="getForms($event)"
          @change="formulario = $event"
          :loading="loadingForms"
          ><span slot="noResult">Nenhum formulário encontrado.</span>
        </BaseSelect>
      </b-tab>
    </b-tabs>

    <template v-slot:footer="{ cancel }">
      <BaseButton variant="link-info" class="mr-4" @click="cancel">
        Cancelar
      </BaseButton>
      <BaseButton variant="primary" @click="onSubmit" :disabled="loading">
        Adicionar lead
      </BaseButton>
    </template>

    <div
      style="
        position: absolute;
        transform: translate(-50%, -50%);
        left: 50%;
        top: 50%;
      "
      v-if="loading"
    >
      <b-spinner label="Loading..."></b-spinner>
    </div>
  </GrModal>
</template>
<script>
import leadScript from "@/mixins/lead.js";

import _ from 'lodash';
import Multiselect from "vue-multiselect";

import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";

import LeadService from "@/services/resources/LeadService";
const serviceLead = LeadService.build();
import FormService from "@/services/resources/FormService";
const serviceForm = FormService.build();
import AdicionalService from "@/services/resources/AdicionalService";
const serviceAdicional = AdicionalService.build();

import Vue from "vue";

export default {
  mixins: [leadScript],
  props: ["sellers", "item", "newLeadPhone", "newLeadPicture", "newLeadName", "isIframe", "leadsUnification"],
  components: {
    Multiselect,
    VuePhoneNumberInput,
  },
  data() {
    return {
      stops: [],
      search: "",
      vendedor: {email:"Selecione um vendedor"},
      titleSpace: false,
      loading_select: false,
      nome: "",
      email: "",
      telefone: "",
      formulario: null,
      loading: false,
      translations: {
        countrySelectorLabel: "",
        countrySelectorError: "",
        phoneNumberLabel: "",
        example: "",
      },
      forms: [],
      antiDuplicate: "",
      stop: false,
      

      loadingForms: false,
      pageForm: 1,
      searchForm: "",
      stopForm: false,
      realNumber: "",
      zipCode: '',
      countryCode: '',
      state: '',
      city: '',
      neighborhood: '',
      street: '',
      number: '',
      open: false
    };
  },
  computed: {
    verificUserLevel() {
      var user = this.$store.getters.user.user.level;
      if (user === "owner" || user === "admin" || user == "gerent_seller") {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    async openModal() {
      await this.ajustTab();
    },
    async ajustTab() {
      await this.$nextTick();
      if(!this.leadsUnification){
        const element2 = document.querySelector('.TabHeaderAddLead .nav-item a[aria-posinset="2"]');
        element2.style.borderRadius = '0 10px 0 0';
        element2.style.borderRight = '1px solid var(--white-medium)';
      }
    },
    validateFields(event, property) {
      this[property] = event.target.value.replace(/\d/g, '');
    },
    returnInitialSeller(user) {
      if (user.first_name && user.last_name) {
        return `${user.first_name[0]}${user.last_name[0]}`
      } else if (user.first_name) {
        return `${user.first_name[0]}${user.first_name[1]}`
      } else if (user.email) {
        return `${user.email[0]}${user.email[1]}`
      } else {
        return '??'
      }
    },
    hasPictureSeller(user) {
      let picMeta = user.metas?.find(meta => meta.meta_key === 'photo')
      if (picMeta) {
        return picMeta.meta_value
      } else {
        return ''
      }
    },
    validSpace() {
      const regex = /  /;
      this.titleSpace = regex.test(this.nome);
    },
    limparCampos() {
      this.nome = this.newLeadName ?? "";
      this.email = "";
      this.telefone = this.newLeadPhone ?? "";
      if (this.newLeadPhone) {
        this.getNumber({formattedNumber: this.telefone})
      }
      this.formulario = null;
      this.vendedor = {email:"Selecione um vendedor"};
      this.zipCode =  '';
      this.countryCode =  '';
      this.state =  '';
      this.city =  '';
      this.neighborhood =  '';
      this.street =  '';
      this.number =  '';
      this.$emit('closeModal')
    },
    
    saveLeadAddress(leadId, address){
      serviceLead
        .createId({id: `address/${leadId}`, ... address});
    },
    
    onSubmit() {
      var emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,10}$/g;

      this.loading = true;

      this.email = this.email.replace(/\s+/g, '');

      if (this.email.length && !this.email.match(emailRegex)) {
        this.$grToast.toast("Insira um e-mail válido", {
          title: "Cadastro manual",
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });
        this.loading = false;
        return
      }
      
      const regex = /  /;
      if (!this.nome || regex.test(this.nome)) {
        this.$grToast.toast("Insira o nome do lead", {
          title: "Cadastro manual",
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });
        this.loading = false;
        return;
      }

      if (
        (!this.telefone || this.telefone.length < 9) &&
        (!this.email.match(emailRegex) || this.email.length < 4)
      ) {
        this.$grToast.toast("Insira um telefone ou um e-mail válido", {
          title: "Cadastro manual",
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });
        this.loading = false;
        return;
      }
      
      const address = {
        zipCode: this.zipCode,
        countryCode: this.countryCode,
        state: this.state,
        city: this.city,
        neighborhood: this.neighborhood,
        street: this.street,
        number: this.number
      }
      
      const addressFilled =  Object.values(address).every(valor => valor !== null && valor !== undefined && valor !== '');

      if(address.zipCode.length && !addressFilled){
        this.$bvToast.toast("Preencha todos os campos de endereço do lead", {
          title: "Cadastro manual",
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });
        this.loading = false;
        return;
      }

      let data = {
        name: this.nome,
        email: this.email,
        phone:
          typeof this.realNumber == "string"
            ? this.realNumber.replace(/[^0-9+]/g, '')
            : "",
        origin: this.isIframe ? 'G Whats' : "Cadastro manual",
        user_id: this.vendedor && this.vendedor.id ? this.vendedor.id : null,
      };
      if (this.formulario && this.formulario.id) {
        data.form_id = this.formulario.id
      }
      serviceLead
        .create(data)
        .then((resp) => {
          this.$grToast.toast("Lead cadastrado com sucesso", {
            title: "Cadastro manual",
            variant: "info",
            autoHideDelay: 3000,
            appendToast: true,
          });

          if(addressFilled){
            this.saveLeadAddress(resp.lead.id, address);
          }

          if (this.isIframe && this.newLeadPicture && resp.lead) {
            this.createPicture(resp.lead?.id)
            let newItem = {
              meta_key: 'photo-lead',
              meta_value: this.newLeadPicture,
            };
            resp.lead.metas = [newItem]
          }
          let leadsOvertaking = parseInt(
            sessionStorage.getItem("LeadsOvertaking")
          );

          leadsOvertaking = Number(leadsOvertaking) + 1;
          sessionStorage.setItem("LeadsOvertaking", leadsOvertaking);
          this.$emit("add-lead", resp.lead?.id);
          this.limparCampos();
          this.$emit("closeModal");
          this.$grModal.hide('add-lead', this);
        })
        .catch((err) => {
          const response = err.response;
          if(response.status === 400){
            this.$grToast.toast(`O lead está associado(a) ${response.data && response.data.seller_name ? 'ao(à) vendedor(a) ' + response.data.seller_name : 'a um vendedor'}`, {
              title: "Cadastro manual",
              variant: "danger",
              autoHideDelay: 5000,
              appendToast: true,
            });
            setTimeout(() => {
              this.displayNotifySwal(response.data.lead_id);
            }, 1000);
            return;
          }
          this.$grToast.toast("Erro ao cadastrar lead", {
            title: "Cadastro manual",
            variant: "danger",
            autoHideDelay: 3000,
            appendToast: true,
          });
          this.limparCampos();
          this.$emit("closeModal");
          this.$grModal.hide('add-lead', this);
        })
        .finally(() => {
          this.nome = "",
          this.email = "",
          this.telefone = "",
          this.realNumber = "",
          this.vendedor = null,
          this.formulario = null,
          this.loading = false;
        });
    },
    displayNotifySwal(leadId){
      Vue.swal({
          title: "Cadastro manual",
          text: `Deseja notificar o(a) vendedor(a)?`,
          type: "danger",
          showCancelButton: true,
          confirmButtonText: "Sim",
          cancelButtonText: "Não",
          customClass: "sweet-container",
          confirmButtonClass: "button button-primary mt-3 mb-3",
          cancelButtonClass: "button button-link-info mr-4 mt-3 mb-3",
          buttonsStyling: false,
          reverseButtons: true,
        }).then((result) => {
          if (result.isConfirmed) {
            serviceLead
              .createId({id: `notify-attempt/${leadId}`})
              .then(() => {
                this.$grToast.toast(`Vendedor(a) será notificado(a)`, {
                title: "Cadastro manual",
                variant: "success",
                autoHideDelay: 5000,
                appendToast: true,
              });
            })
          }
        });
    },
    debounceZipCode: _.debounce(function (zipCode) {
      const code = zipCode.target.value.replace('-', '');
      if(code.length < 8) {
        return;
      }
      this.checkZipCode(code);
    }, 100),
    async checkZipCode(zipCode) {
      this.$refs.neighborhood.focus();
      const address = await this.getAddress(zipCode);
      if(address.erro) {
        this.$bvToast.toast("Endereço não encotrado. Por favor, confira o CEP", {
          title: "Cadastro manual",
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });
        return;        
      }
      this.state = address.uf;
      this.city = address.localidade;
      this.neighborhood = address.bairro;
      this.street = address.logradouro;
      this.countryCode = address.uf;
    },
    async getAddress(zipCode) {
      return new Promise((resolve) => {
        fetch(`https://viacep.com.br/ws/${zipCode}/json`)
        .then((request) => request.json())
        .then((data) => {
          resolve(data);
        });
      });
    },
    createPicture(leadId) {
      if (!leadId) { return }
      let data = {
        id: 'photo',
        leadId: leadId,
        photoUrl: this.newLeadPicture,
      };
      serviceLead.createId(data)
    },
    getNumber(e) {
      if(!e.formattedNumber){
        this.realNumber = "";
        return;
      }
      this.realNumber = e.formattedNumber.replace(/[^0-9+]/g, '');
    },
    debounceForms: _.debounce(function (query) {
      if (query === "") {
        this.loadingForms = false;
      } else {
        this.searchForm = query;
        // this.forms = [];
        this.getForms(this.page, query);
      }
    }, 500),

    getForms(page = 1, query = "") {
      if (this.stopForm == true) {
        this.loadingForms = false;
        return;
      }
      var data = {
        id: `?page=${page}&order_by=title&order=ASC&status=active&search=${query}`,
      };
      serviceForm
        .read(data)
        .then((resp) => {
          if (resp.data.length < resp.per_page && query == "") {
            this.stopForm = true;
          }
          this.forms = this.forms.concat(resp.data);
          this.forms = this.forms.filter(
            (item, index, opt) =>
              index === opt.findIndex((t) => t.id === item.id)
          );
        })
        .catch((error) => {});
    },
  },
  watch: {
    nome: "validSpace",
  },
};
</script>

<style scoped>
p {
  margin: 0;
  font-size: 14px;
  font-weight: normal;
  color: #81858e;
  line-height: 1.5;
}
p + p {
  margin-top: 5px;
}
.link-documentacao {
  padding: 0 25px !important;
  height: 40px !important;
  font-size: 12px;
}
.top-dados {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
@media screen and (max-width: 768px) {
  .top-dados {
    display: block;
  }
  .top-dados #limpar {
    margin-top: 15px;
  }
}
.form-relative {
  position: relative;
}
.btn-todos {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 14px;
  color: #81858e;
}

#errorName {
  font-size: 12px;
  font-weight: 500;
  color: #dc3545 !important;
}

#errorEmail {
  font-size: 12px;
  font-weight: 500;
  color: #dc3545 !important;
}
</style>
<style lang="scss">
#add-lead {
  .tab-content > .tab-pane {
    border: 1px solid var(--white-medium) !important;
    border-radius: 0 10px 10px;
    padding: 20px;
  }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: var(--greenn) !important;
    font-weight: 500 !important;
  }
  .nav-tabs {
    font-weight: 500 !important;
    border-bottom: none !important;
  }

  .TabHeaderAddLead .nav-item a {
    padding: 10px 15px !important;
    color: var(--gray05);
    // margin-right: 1px;
    width: fit-content !important;
    border-radius: 0;
    border: 1px solid var(--white-medium) !important;
    border-right: none !important;
    transition: all 0.3s;
    font-weight: 500 !important;
    font-size: 15px !important;
    &:hover {
      color: var(--gray05);
    }
  }

  .TabHeaderAddLead .nav-item a.active {
    border-bottom-color: var(--white) !important;
    background-color: var(--white);
  }

  .TabHeaderAddLead .nav-tabs .nav-link:hover {
    border: 1px solid var(--white-medium) !important;
  }
  .TabHeaderAddLead .nav-item a[aria-posinset="1"] {
    border-radius: 10px 0 0 0 !important;
  }

  .TabHeaderAddLead .nav-item a[aria-posinset="3"] {
    border-radius: 0 10px 0 0 !important;
    border-right: 1px solid var(--white-medium) !important;
  }

  .grid-address-2, .grid-address-3 {
    display: grid;
    gap: 10px;
    margin-bottom: 10px;
  }
  .grid-address-2 {
    grid-template-columns: 1fr 100px;
  }
  
  .grid-address-3 {
    grid-template-columns: 1fr 150px 105px;
  }
  
  input {
    width: 100%;
  }
}
</style>
