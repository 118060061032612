import Rest from '@/services/Rest';

/**
 * @typedef {ImportLeadsService}
 */
export default class ImportLeadsService extends Rest {
    /**
     * @type {String}
     */
    static resource = 'lead/import/import-limit'
}