import { serviceGlobalSettings } from "@/services/resources";

export default {
  async fetchGlobalSettings({ commit, getters }, flag) {    
    const { getGlobalFlags } = getters;

    const existingFlag = getGlobalFlags.find((item) => item.meta_key === flag);
    if (existingFlag) return;

    const resp = await serviceGlobalSettings.read({ id: flag });

    commit("setGlobalFlags", resp);
  },
};