<template>
  <div>
    <b-container fluid class="bg-login full-height">
      <b-row class="full-height">
        <b-col cols="12" md="6" class="left-side" :style="'background-image: url('+backgroundImage+')'"> </b-col>
        <b-col cols="12" md="6" class="right-side">
          <div class="inner-wrapper" data-anima="right" v-if="!loading">
            <div class="d-flex justify-content-between">
              <span class="logo">
                <img v-if="!isGreenn" src="@/assets/img/G-digital-text.png" />
              <img
                v-else
                src="@/assets/greenn_sales/GREENN_SALES_.png"
                style="width: 198px; position: relative; left: -14px"
              />
              </span>
              <span
                class="logout pointer"
                @click="$store.dispatch('logoutRequest')"
                >Fazer Log-out</span
              >
            </div>

            <div
              class="mb-4"
              style="
                border: solid 1px #dadada;
                padding: 10px;
                border-radius: 10px;
              "
              v-for="painel in paineis"
              :key="painel.id"
            >
              <p><span class="bold">Nome:</span> {{ painel.tenant[0].name }}</p>
              <p>
                <span class="bold">Subdomínio:</span>
                {{ painel.tenant[0].subdomain }}
              </p>
              <b-row class="mt-2">
                <b-overlay
                  :show="loading"
                  rounded
                  opacity="0.6"
                  spinner-small
                  spinner-variant="primary"
                  class="btn-acoes"
                  :class="{ end: isMobile }"
                >
                  <BaseButton
                    variant="primary"
                    ref="button"
                    :disabled="loading"
                    type="submit"
                    style="margin-left: 15px"
                    class="btn-login"
                    :class="{ mg: isMobile }"
                    @click="
                      onSubmit(painel.tenant[0].subdomain, painel.tenant_id)
                    "
                  >
                    Acessar
                  </BaseButton>
                </b-overlay>
              </b-row>
            </div>
          </div>
          <div
            v-if="loading"
            class="py-4 d-flex justify-content-center align-items-center"
          >
            <b-spinner variant="dark" label="Spinning"></b-spinner>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import BaseView from "@/template/BaseView.vue";

import AuthService from "@/services/resources/AuthService";
const service = AuthService.build();
import VerifyPanel from "@/components/Dashboard/VerifyPanel";
import Cookies from "js-cookie";
const axios = require("axios");
import store from "@/store/index";
import TokenService from "@/services/resources/TokenService";

export default {
  components: {
    BaseView,
    VerifyPanel,
  },
  data() {
    return {
      data: {},
      submit: false,
      error: false,
      success: true,
      email: null,
      password: null,
      backgroundImage:'',
      blogs: [],
      loading: false,
      authorize: "",
      state: "",
      redirect_uri: "",
      client: {
        width: 0,
      },
    };
  },
  computed: {
    isGreenn() {
      return this.$store.getters["getIsGreenn"];
    },
    isMobile() {
      return this.client.width <= 768;
    },
    paineis() {
      return this.$store.getters.user.user.painels;
    },
  },
  methods: {
    handleResize() {
      // this.client.width = window.innerWidth;
      this.client.width = window.innerWidth;
    },
    onSubmit(e, id) {
      sessionStorage.removeItem("LeadsOvertaking");
      this.loading = true;
      if (e == "adm") {
        sessionStorage.setItem("tenant_id", id);
        this.$bvModal.show("verifySubdomain");
        return;
      }
      if (this.$route.query.authorize) {
        this.authorize = this.$route.query.authorize;
        this.state = this.$route.query.state;
        this.redirect_uri = this.$route.query.redirect_uri;
      }
      var service = TokenService.build();
      service
        .login(e, this.authorize, this.state, this.redirect_uri)
        .then((resp) => {
          if (resp.redirect && resp.redirect.length) {
            return window.open(resp.redirect, "_self");
          }
          store.dispatch("blogSelected2", resp);
          store.dispatch("getAllFlags");
        })
        .catch((err) => {
          store.dispatch("logoutRequest");
        });
    },
  },
  mounted() {

    if(this.isGreenn){
      this.backgroundImage = window.location.origin + '/banner2.webp'
    }else{
      this.backgroundImage =  window.location.origin + '/banner1.png'
    }

  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>
<style lang="scss" scoped>
.mg {
  margin-right: 15px !important;
  margin-left: 0px !important;
}

a:hover {
  text-decoration: none;
}

.end {
  justify-content: flex-end !important;
}

.text-green {
  color: var(--greenn);
  font-weight: bold;
}

.bg-login {
  background: var(--greenn);
  overflow: hidden;
}

.form-group {
  position: relative;
}

.form-group + .form-group {
  margin-top: 20px;
}

.left-side {
  background-image: url("~@/assets/banners/login.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.right-side {
  background: #fff;
  padding: 20px 0px;
  height: 100%;
  overflow-y: auto;

  .inner-wrapper {
    margin-top: 90px;
    padding: 6% 18%;
    // width: 60%;

    .logo {
      img {
        width: 120px;
        height: auto;
        margin-bottom: 40px;
      }
    }
  }
}

.recupera-senha-text {
  color: #81858e;
  font-size: 14px;
}

.links-help {
  font-size: 12px;
  color: #81858e;
  margin-top: 20px;
}

.btn-acoes {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mostrar-senha {
  font-size: 13px;
  position: absolute;
  top: 16px;
  right: 20px;
  cursor: pointer;
  color: #81858e;
}

.logout {
  color: #81858e;
  font-size: 14px;
  height: 20px;
}

@media screen and (max-width: 768px) {
  .right-side .inner-wrapper {
    padding: 6% 11%;
  }

  .grecaptcha-badge {
    right: -194px !important;
  }

  .logo img {
    width: 80px !important;
    margin-bottom: 30px !important;
  }

  .links-help {
    margin: 0;
  }
}

.full-height {
  height: 100vh;
}

.bold {
  font-weight: 600;
}

.pointer {
  cursor: pointer;
}

*::-webkit-scrollbar {
  width: 6px;
  background: white;
}

*::-webkit-scrollbar {
  width: 6px;
  background: white;
}
*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 20px;
}
</style>
